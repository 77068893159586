import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"

const CustomFliImg = ({ src,alt }) => {
  const [showImage, setShowImage] = useState(true);

  const [img, setimg] = useState(alt === 'I51' || alt === 'YY' ? 'I5' : alt === 'IX1'? "IX" : alt);
  // console.log(img,"alt")
   
  return (
      <img
          src={`http://www.gstatic.com/flights/airline_logos/70px/${img}.png`}
          //   style={{ height: "100%" }}
          alt="image"
          className="airline-img"
          // onError={() => setShowImage(false)}
      />
  );
};
export default CustomFliImg;