import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Table,
    Upload,
    // Popover,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import HotelAutoCompleteSelectByCategory from "../../common/AutoCompleteSelect/HotelAutoCompleteSelectByCategory";
import {
    EditOutlined,
    PlusCircleFilled,
    PlusOutlined,
    CloseOutlined,
    DeleteOutlined,
    //InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const TopCityHotel = (props) => {
    const BASE = process.env.REACT_APP_BASE_URL;
    const uploadActionUrl = BASE + "media/upload/multipleImages";
    const { Option } = Select;
    const { Content } = Layout;
    const [modalVisible, setModalVisible] = useState(false);
    const supplierBox = useRef(null);
    const city = useRef(null);

    const [dataSource, setDataSource] = useState([]);
    const [countryLists, setCountryList] = useState([]);
    const [cityLists, setCityList] = useState([]);
    const countryCityList = (val, _) => {

        let countryName = {
            country: val.children,
        };
        ApiClient.post("admin/citiesbycountry", countryName)
            .then((res) => {
                if (res.status === 200) {
                    setCityList(res.data);
                } else {
                    message(res.message);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [cityHotelId, setCityHotelId] = useState(null);
    const [form] = Form.useForm();
    const [serviceType, setServiceType] = useState(2);
    const [hotelData, setHotelData] = useState([]);

    const initialImages = {
        previewVisible: false,
        previewImage: "",
        previewTitle: "",
        fileList: [],
    };
    const [cityImages, setCityImages] = useState(initialImages);
    const [hotelCityId, setHotelCityId] = useState(0);
    const [hotelsCode, setHotelsCode] = useState([]);
    const [supplier, setSupplier] = useState()
    const [selectedCity, setSelectedCity] = useState()
    const [hotelImages, setHotelImages] = useState(initialImages);

    const showModal = () => {
        if (isEdit) {
            setServiceType(2);
            setIsEdit(false);
            form.resetFields();
            setCityImages(initialImages);
            setHotelImages(initialImages);
        }
        setModalVisible({
            visible: true,
        });
    };

    const EditCityHotel = (record) => {
        setIsEdit(true);
        // setServiceType(record.Servicetype);
        setCityHotelId(record?.CityHotelID);

        let val = {
            children: record.CountryName,
        };
        
        countryCityList(val, "");
        form.setFieldsValue({
            ...record,

        });
        
                    // CountryName: record.CountryID,
                    // CityName: record.CityName,
                    // starRating: record.starRating,
                    // limit: record.limit


        setModalVisible(true);
    };

   

  

    const tableActions = (rec) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {/* <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            EditCityHotel(rec);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div> */}
                <div className="edit-icon" style={{ textAlign: "center", cursor:"pointer" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => {
                            deleteCityHotel(rec);
                        }}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        /> {"Delete"}
                    </Popconfirm>
                </div>
            </div>
        );
    };
 

    const columns = [
        {
            title: "S.No.",
            dataIndex: "Sno",
        },
        // {
        //   title: "Status",
        //   dataIndex: "Status",
        //   // key: "name",
        //   render: (status) => getStatus(status),
        // },

        // {
        //   title: "Service Type",
        //   dataIndex: "ServiceName",
        //   //sorter: (a, b) => a.ServiceName.localeCompare(b.ServiceName),
        // },
        // {
        //     title: "Name",
        //     dataIndex: "HotelName",
        //     sorter: (a, b) => a.HotelName.localeCompare(b.HotelName),
        // },
        // {
        //     title: "Country Name",
        //     dataIndex: "CountryName",
        //     sorter: (a, b) => a.CountryName.localeCompare(b.CountryName),
        // },
        {
            title: "City Name",
            dataIndex: "result",
            // sorter: (a, b) => a.CityName.localeCompare(b.CityName),

            render: (result) => {
                return (result?.map((City, i) => {

                    return (<>{i === 0 && City?.city}
                    </>);


                }));
            }
        },
        // {
        //     title: "SupplierName",
        //     dataIndex: "supplierName",
        // },

        {
            title: "Star Rating",
            dataIndex: "result",
            render: (result) => {
                return (result?.map((starRating, i) => {

                    return (<>{i === 0 && Number(starRating?.starRating) + " - Star Hotels"}
                    </>);


                }));
            }
        },
        {
            title: "List Of Hotels",
            dataIndex: "result",
            sorter: (a, b) => a?.result?.length?.localeCompare(b?.result?.length),
            render: (result) => {
                return (result?.length);

            }

        },
        {
            title: "Actions",
            render: (currentDeal) => tableActions(currentDeal),
        },


    ];

    const selectCustomFilter = (input, option) => {
        let istext =
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        if (istext) {
          return true;
        }
      };
  
    /*==========Api Calls=====*/

    useEffect(() => {
        getCityHotels();
        getCountriesLists();
    }, []);

    const getCityHotels = () => {
        ApiClient.get("admin/dynamic/hotel")
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data.map((item, index) => {
                        // { console.log(item, "item") }
                        return {
                            ...item,
                            Sno: index + 1,
                            //   ServiceName: getName(item.Servicetype),
                        };
                    });
                    // console.log(data, "data");
                    // const data = res.data;
                    setDataSource(data);
                } else {
                    message("Server Error");
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getCountriesLists = () => {
        ApiClient.get("admin/country")
            .then((res) => {
                if (res.status === 200) {
                    setCountryList(res.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const deleteCityHotel = (id) => {
        // console.log(id, "delete");
        ApiClient.delete("admin/dynamic/hotel/" + id.CityHotelID)
            .then((res) => {
                if (res.status === 200) {
                    message.success("Top City Hotel Deleted  successfully", 3);
                    getCityHotels();
                } else {
                    message.error("Error Deleting", 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const submitForm = (values) => {





        let countryData = countryLists.filter(
            (item) => item.CountryID === values.CountryID
        );

        // let cityData = values?.hotelCityCode?.split('&&')[1]?.split('=')[1]
        let formData = {

            CountryName: countryData[0].Country,
            CityName: values?.hotelCityCode,
            starRating: values.starRating,
            limit: values.limit,

        };
        // isEdit ? putDealData(formData) : postCityHotel(formData);
        postCityHotel(formData);
    };

    
    const putDealData = (formData) => {
        const updatedFormData = {
            ...formData,
          };
        ApiClient.put("admin/dynamic/hotel/" + cityHotelId, {}, updatedFormData).then(
            (res) => {
                if (res.status === 200) {
                    message.success("Top City Hotel Updated successfully", 3);
                    setModalVisible(false);
                    form.resetFields();

                    getCityHotels();
                } else {
                    message.error(" Error Uploading Data", 3);
                }
            }
        );
    };

    const postCityHotel = (formData) => {
        ApiClient.post("admin/cityHotel", formData).then((res) => {
            if (res.status === 200) {
                message.success("Top City Hotel added successfully", 3);
                getCityHotels();
                setModalVisible(false);
                form.resetFields();

            } else {
                message.error(" Error Uploading Data", 3);
            }
        });
    };

    /*======= Search and Export Options=====*/

    const searchData = (searchData) => {
        setShowSearchBox(true);
    };

    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = dataSource.filter(function (item) {
                if (item !== undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setDataSource(searchResults);
        } else {
            getCityHotels();
        }
    };

    const hotelName_Code = (req) => {
        ApiClient.post("hotels-v2/hotelscode", req)
            .then((res) => {
                if (res.statusCode == 200) {
                    setHotelsCode(res.data);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleOnSubmit = (ref, selectCity) => {
        setSelectedCity(selectCity)
    };

    const onSupplierSelect = (val) => {
        setSupplier(val);
        const cityCode = selectedCity?.split("&&")[1].split("=")[1];
        setHotelCityId(cityCode);
        hotelName_Code({ supplierName: val, cityId: parseInt(cityCode) });
    };

    const closeSearch = () => {
        setShowSearchBox(false);
    };

    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );

    const handleExcel = (e) => {
        const excelCustomData = dataSource.map((item) => {
            return {
                From_Country: item.FromCountry,
                To_Country: item.ToCountry,
                From_Airport: item.FromAirportCode,
                To_Airport: item.ToAirportCode,
                From_Amount: item.FromAmount,
            };
        });

        props.exportExcel(excelCustomData, "dealsExcel");
    };


    return (
        <div>
            <div className="manage-markup-section">
                <Content className="container-fluid">
                    <Row>
                        <Col span={24}>
                            <div className="card-bt-gap">
                                <Card bordered={false}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <div>
                                                <h5>
                                                    View Top City Hotels{" "}
                                                    <HelpInfoHelper screenName={"/admin/cityhotel"} />
                                                </h5>
                                                <p>{dataSource.length} rows found !</p>
                                            </div>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img
                                                src={search}
                                                alt="search"
                                                onClick={(e) => searchData(dataSource)}
                                            />
                                            <img src={excel} alt="excel" onClick={handleExcel} />
                                            <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => getCityHotels()}
                                            />
                                            <p className="add-deposit-icon" onClick={showModal}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            className="table-scroll-none"
                                            bordered
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>

            <Modal
                // title={isEdit ? "Update Top City Hotel" : "Add Top City Hotel"}
                title={"Add Top City Hotel"}
                className="promo-modal-header admin-bc-level modal-header-bg12"
                visible={modalVisible}
                onCancel={(e) => setModalVisible(false)}
                // width={"1100px"}
                footer={[
                    <Button key="submit" type="primary" onClick={form.submit}>
                        {isEdit ? "Update" : "Add"}
                    </Button>,
                    <Button key="back" onClick={() => setModalVisible(false)}>
                        Cancel
                    </Button>,
                ]}
            >
                <Form
                    form={form}
                    // initialValues={{
                    //     Servicetype: 2,
                    // }}
                    layout="vertical"
                    onFinish={submitForm}
                >
                    <Row gutter={16}>

                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Country Name"
                                name="CountryID"
                                rules={[
                                    { required: true, message: "From Country is required" },
                                ]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select country"
                                    onSelect={(val, _) => {
                                        countryCityList((val, _));
                                    }}
                                    filterOption={(val, op) => selectCustomFilter(val, op)}
                                >
                                    {countryLists.map((i) => (
                                        <Option value={i.CountryID}>{i.Country}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <label rules={[{ required: true }]}>Hotel City</label>

                            <HotelAutoSelect
                                formItemProps={{
                                    name: "hotelCityCode",
                                    rules: [
                                        { required: true, message: "Please Specify The City" },
                                    ],
                                }}
                                selectProps={{
                                    size: "large",
                                    placeholder: "Enter City Name",
                                }}
                                api={"hotels-v2/searchhotelcity/"}
                                refName={city}
                                focusRef={supplierBox}
                                handleOnSubmit={handleOnSubmit}
                                modifySearch={false}
                            />

                        </Col>
                        {/* <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                                label="Supplier"
                                name="supplierName"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Supplier"
                                    ref={supplierBox}
                                    onSelect={onSupplierSelect}
                                >
                                    <Option key={0} value="TBO">
                                        TravelBoutique
                                    </Option>
                                    <Option key={1} value="GRNC">
                                        GRNConnect
                                    </Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        {/* <Col md={6} xs={24}>
                            <Form.Item
                                label="Hotel Name"
                                name="hotelName"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                               
                                <Select
                                    showSearch
                                    placeholder="Select Hotel"
                                    ref={supplierBox}
                                    onSelect={onSupplierSelect}
                                    filterOption={(input, option) =>
                                        (option?.children ?? "")
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                >
                                    {hotelsCode.length > 0 &&
                                        hotelsCode.map((item, index) => (
                                            <Option key={index} value={item.propertyName}>
                                                {item.propertyName}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col> */}





                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Star Rating"
                                name="starRating"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select Star Rating"

                                >

                                    <Option value={'5.0'}>{'5 Stars'}</Option>
                                    <Option value={'4.0'}>{'4 Stars'}</Option>
                                    <Option value={'3.0'}>{'3 Stars'}</Option>
                                    <Option value={'2.0'}>{'2 Stars'}</Option>
                                    <Option value={'1.0'}>{'1 Stars'}</Option>
                                    {/* <Option value={'5.0'}>{'5 Stars'}</Option> */}

                                </Select>
                                {/* <Radio.Group>
                                    <Radio value={'5.0'}> 5 Stars </Radio>
                                    <Radio value={'4.0'}> 4 Stars </Radio>
                                    <Radio value={'3.0'}> 3 Stars </Radio>
                                    <Radio value={'2.0'}> 2 Stars </Radio>
                                    <Radio value={'1.0'}> 1 Stars </Radio>
                                </Radio.Group> */}
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="No.of Hotels"
                                name="limit"
                                rules={[
                                    {
                                        required: true,
                                        message: "Mention No of hotels to be Displayed",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    placeholder="No of Hotels"
                                    min={1}
                                    type="number"
                                />
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </Modal>
        </div>
    );
};
export default UpdatedComponent(TopCityHotel);
