import { getConvFee, getPromoDiscount } from "../promoConvhelper";
import moment from "moment";

export const formatFlightTime = (flightTime) => {
  let [date, time] = flightTime.split("T");
  let [hour, minute, seconds] = time.split(":");
  return `${hour}:${minute}`;
};

export function getFlightPrice(ticketData) {
  let flightFares = ticketData?.oneWayflightFare?.fares || [];
  let flightFar = ticketData?.returnflightFare?.fares || [];
  let baseAmount = 0;
  let taxAmount = 0;
  let convienenceFee = 0;
  let discount = 0;
  let RefundAmount = 0;
  let insuranceTotal = 0;
  let grandTotal = 0;
  let postMarkup = 0;
  let totalmeal = 0;
  let totalbagg = 0;

  if (ticketData?.postMarkup) {
    postMarkup = Number(ticketData?.postMarkup);
  }
  if (ticketData?.tripType.toUpperCase() === "MULTIDESTINATION") {
    baseAmount = ticketData?.multicityflightFare.baseFare;
    taxAmount = ticketData?.multicityflightFare.tax;
    convienenceFee = Number(ticketData?.convienenceData.amount);
    discount = Number(ticketData?.promoData.Discount);
    totalmeal = Number(ticketData?.multicityflightFare.totalMealAmount);
    totalbagg = Number(ticketData.multicityflightFare.totalBaggageAmount);
    grandTotal = baseAmount + taxAmount + convienenceFee - discount + postMarkup + totalmeal + totalbagg;
  }
  else {

    if (
      ticketData.tripType == "roundTrip" &&
      ticketData.mappingType === "SEPARATE"

    ) {
      flightFares = [
        ...ticketData.oneWayflightFare.fares,
        ...ticketData.returnflightFare.fares,
      ];
    }


    let base = flightFares
      .filter((item) => item.FareTag == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
    let base1 = flightFar
      .filter((item) => item.FareTag == "Base")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
    baseAmount = Number(base) + Number(base1) + postMarkup;
    // baseAmount = Number(base) + postMarkup;


    let tax = flightFares
      .filter((item) => item.FareTag == "Tax")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
      let tax1 = flightFar
      .filter((item) => item.FareTag == "Tax")
      .reduce((total, item) => {
        if (item.PaxType == "ADT") {
          return total + Number(Number(item.Amount) * ticketData.pax.adults);
        } else if (item.PaxType == "CHD") {
          return total + Number(Number(item.Amount) * ticketData.pax.childCount);
        } else if (item.PaxType == "INF") {
          return total + Number(Number(item.Amount) * ticketData.pax.infantCount);
        }
      }, 0);
      
    taxAmount = Number(tax) + Number(tax1);
    // taxAmount = Number(tax);

    let totalwithtax = Number(baseAmount) + Number(taxAmount);

    if (ticketData.convienenceData) {
      convienenceFee = getConvFee(ticketData.convienenceData, totalwithtax);
    }

    let totalAmount = totalwithtax + Number(convienenceFee);

    if (ticketData.promoData) {
      let discountValue = getPromoDiscount(ticketData.promoData, totalAmount);
      discount = Number(discountValue).toFixed(2);
    }
    RefundAmount = ticketData.RefundAmount ?? 0;

    if (
      ticketData?.insuranceRequired === 1 &&
      ticketData?.insuranceData?.serviceType === 1
    ) {
      insuranceTotal =
        ticketData.passengers.length * Number(ticketData?.insuranceData.amount);
    }
    totalmeal = Number(ticketData?.oneWayflightFare?.totalMealAmount ).toFixed(2);
    totalbagg = Number(ticketData?.oneWayflightFare?.totalBaggageAmount ).toFixed(2);
    grandTotal = Number(
      baseAmount +
      taxAmount +
      Number(convienenceFee) +
      Number(insuranceTotal) -
      discount+Number(totalbagg)+Number(totalmeal)
    ).toFixed(2);
  }
  return {
    baseAmount: Number(baseAmount).toFixed(2),
    taxAmount: Number(taxAmount).toFixed(2),
    convienenceFee: Number(convienenceFee).toFixed(2),
    discount: Number(discount).toFixed(2),
    RefundAmount: Number(RefundAmount).toFixed(2),
    grandTotal,
    totalmeal,
    totalbagg,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
  };
}

export const calculateDuration = (from, to) => {
  let FromDate = moment(from);
  let ToDate = moment(to);
  let numberOfHours = ToDate.diff(FromDate, "hours");
  let numerOfMinutes = ToDate.diff(FromDate, "minutes");
  let days = Math.floor(numberOfHours / 24);
  let Remainder = numberOfHours % 24;
  let hours = Math.floor(Remainder);
  let minutes = Math.floor(numerOfMinutes - 60 * numberOfHours);
  let difference = "";
  if (days > 0) {
    difference += days === 1 ? `${days} d, ` : `${days} ds, `;
  }

  difference += hours === 0 || hours === 1 ? `${hours} h ` : `${hours} h `;

  difference +=
    minutes === 0 || hours === 1 ? `${minutes} min` : `${minutes} min`;

  return difference;
};
