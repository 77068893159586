import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  CloseOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
  Popover,
} from "antd";
import React, { useEffect, useState } from "react";

import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const AddProvider = (props) => {
  const { Header, Footer, Sider, Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [providers, setProviders] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [showUpdateBtnFlag, setShowUpdateBtnFlag] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [isCancelType, setIsCancelType] = useState(false);
  const [providerForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);

  useEffect(() => {
    getProvidersList();
  }, []);

  const getProvidersList = () => {
    ApiClient.get("admin/providers")
      .then((resp) => {
        if (resp.statusCode == 200) {
          setProviders(resp.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;

    if (searchVal.length > 0) {
      const searchRes = providers.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setProviders(searchRes);
    } else {
      getProvidersList();
    }
  };

  const onFinishFailed = (errorInfo) => {
   };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  let onChange = (jsDate, dateString) => {};
  const tableActions = (c_Record) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handelEdit(c_Record)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteProvider(c_Record)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const deleteProvider = (record) => {
    let providerId = record.ProviderID;
    ApiClient.delete("admin/provider/" + providerId)
      .then((res) => {
        if (res.statusCode == 200) {
          getProvidersList();
          message.success("Provider Deleted successfully", 3);
        }
      })
      .catch((e) => {
       });
  };

  const postProvider = (values) => {
    let data = {
      ServiceType: values.ServiceType,
      SupportEmail: values.SupportEmail,
      Status: values.Status,
      Provider: values.Provider,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
    };

    ApiClient.post("admin/provider", data)
      .then((res) => {
        if (res.statusCode == 200) {
          getProvidersList();
          setModalVisible(false);
          providerForm.resetFields();
          message.success("Successfully added api Provider", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const putProvider = (values) => {
    let providerId = currentRecord.ProviderID;
    let editObj = {
      ServiceType: values.ServiceType,
      SupportEmail: values.SupportEmail,
      Status: values.Status,
      Provider: values.Provider,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
    };
    UpdateStatus(editObj, providerId);
  };

  /* ========== ADD APIProvider Start ========= */
  const onFinish = (values) => {
    showUpdateBtnFlag ? putProvider(values) : postProvider(values);
  };

  function handelEdit(cur_Record) {
    setShowUpdateBtnFlag(true);
    setCurrentRecord(cur_Record);
    providerForm.setFieldsValue({
      ServiceType: cur_Record.ServiceType,
      SupportEmail: cur_Record.SupportEmail,
      Status: cur_Record.Status,
      Provider: cur_Record.Provider,
    });
    setModalVisible(true);
  }

  function chooseServiceType(ServiceType) {
    switch (ServiceType) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
     
      
      default:
        return null;
    }
  }
  const { Option } = Select;

  const columns = [
    {
      title: "Provider",
      dataIndex: "Provider",
      sorter: (a, b) => a.Provider.localeCompare(b.Provider),
    },
    {
      title: "Service",

      render: (record) => chooseServiceType(record.ServiceType),
    },
    {
      title: "Support Email",
      dataIndex: "SupportEmail",
      // sorter: (a, b) => a.SupportEmail.localeCompare(b.SupportEmail),
    },
    {
      title: "Status",
      // render: (currentItem) => handelStatus(currentItem),
      render: (status) => getStatus(status),
    },
    {
      title: "Actions",
      render: (record) => tableActions(record),
    },
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    setStatusBtn(e.target.value);
  };

  const getStatus = (data) => {
    return (
      <div
        style={data.Status == 0 ? { color: "#ff0000" } : { color: "#008000" }}
      >
        <b>{data.Status == 0 ? "Inactive" : "Active"}</b>
      </div>
    );
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const handleShowAddModal = () => {
    providerForm.resetFields();
    setShowUpdateBtnFlag(false);
    setModalVisible(true);
  };

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  /* =========== excel start ========== */

  const handleExcel = (e) => {
    const excelCustomData = providers.map((item) => {
      let serName = chooseServiceType(item.ServiceType);
      const obj = {
        Provider: item.Provider,
        Service_Type: serName,
        Support_Email: item.SupportEmail,
        Status: item.Status,
      };
      return obj;
    });
    props.exportExcel(excelCustomData, "Providers");
  };
  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (values, Status) => {
    let providerId = values.ProviderID;

    let data = {
      ServiceType: values.ServiceType,
      SupportEmail: values.SupportEmail,
      Status: Status,
      Provider: values.Provider,
      DeviceOS: 3,
      ApplicationType: 1,
      CreatedBy: 0,
      DeviceOSVersion: "",
      DeviceToken: "",
      FirBaseToken: "",
    };
    UpdateStatus(data, providerId);
  };
  const UpdateStatus = (data, providerId) => {
    ApiClient.put(`admin/provider/${providerId}`, {}, data)
      .then((response) => {
        if (response.statusCode == 200) {
          getProvidersList();
          setModalVisible(false);
          message.success(" Provider Updated successfully", 3);
        } else if (response.statusCode == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className="promo addprovider">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <Row>
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      {/* <div className="table-heading-count"> */}
                      <div className="rows-count">
                        <div>
                          <h5>
                            View Providers{" "}
                            <HelpInfoHelper screenName={"/admin/provider"} />
                          </h5>
                          <p>{providers.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData(providers)}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          onClick={(e) => handleExcel(e)}
                        />
                        {/* <img src={pdf} alt="pdf" /> */}
                        {/* <img src={word} alt="word" /> */}

                        {/* <img src={exchange} alt="exchange" /> */}
                        <p className="add-deposit-icon">
                          <PlusCircleFilled onClick={handleShowAddModal} />
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        rowKey={uniqueKey}
                        dataSource={providers}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </Content>
        </div>

        <Modal
          title={showUpdateBtnFlag ? "Update Provider" : "Add Provider"}
          className="promo-modal-header modal-header-bg12"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          width={"500px"}
          footer={[
            <Button key="back" onClick={() => setModalVisible(false)}>
              Cancel
            </Button>,
            showUpdateBtnFlag ? (
              <Button type="primary" onClick={providerForm.submit}>
                Update
              </Button>
            ) : (
              <Button type="primary" onClick={providerForm.submit}>
                Add
              </Button>
            ),
          ]}
        >
          <Form
            form={providerForm}
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Col md={24} xs={24}>
              <Form.Item
                label="Service"
                rules={[{ required: true, message: "Please choose  service" }]}
                name="ServiceType"
              >
                <Select>
                  <Select.Option value={1}>Flights</Select.Option>
                  <Select.Option value={2}>Hotels</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                name="Provider"
                label="Provider"
                rules={[{ required: true, message: "Please Enter provider!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* </Row> */}

            {/* <Row gutter={16}> */}
            <Col md={24} xs={24}>
              <Form.Item
                name="SupportEmail"
                label="Supported Emails"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group onChange={onChange} value={statusBtn}>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/* </Row> */}
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default UpdatedComponent(AddProvider);
