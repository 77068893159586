import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Tabs,
  Tooltip,
  Collapse
} from "antd";
import { useFlightContext } from "../../../common/providers/Flights/FlightContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import { calculateDuration } from "../../../common/AllTickets/Flight/flightHelper";
import ApiClient from "../../../helpers/ApiClient";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import CustomflightImg from "../../../common/CustomImg/CustomflightImg";
import {
  MailOutlined,
  RightOutlined,
  ShoppingOutlined,
  LineOutlined,
  CloseOutlined
} from "@ant-design/icons";
import "./Flight.scss";
import "antd/dist/antd.css";
import "font-awesome/css/font-awesome.min.css";
import moment from "moment";
// import CustomFliImg from "../../../common/CustomImg/CusFliLogo";

export const FlightTimings = ({ flightInfo, airSearchData, fareFamilies }) => {
  const { user } = useAuthContext();
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { TabPane } = Tabs;
  const [cmsFareRules, setCmsFareRules] = useState({});
  const [flightDetails, setFlightDetails] = useState(false);
  const showModal = () => {
    let obj = {
      airlineCode: flightInfo.airLine,
      fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
      roleID: user?.Role?.RoleId ?? 4,
      membershipID: user?.Membership ?? 1,
    };
    getCmsFareRules(obj);
  };

  const getCmsFareRules = (obj) => {
    ApiClient.get("admin/getFlightFareRules", obj)
      .then((res) => {
        if (res.status === 200) {
          setCmsFareRules(res.data);
          setFlightDetails({
            visible: true,
          });
        } else {
          setCmsFareRules({});
          setFlightDetails({
            visible: true,
          });
        }
      })
      .catch((e) => {
        setCmsFareRules({});
        setFlightDetails({
          visible: true,
        });
      });
  };

  const fetchFlightFares = (str, event) => {
    if (str === "2") {
      let fareReqObj = {
        traceId: airSearchData.traceId,
        flightId: flightInfo.flightId,
        airTravelType: airSearchData.airTravelType,
        mappingType: airSearchData.resultsType,
        itineraryViewType: "1",
      };
      ApiClient.post("flights/airFareRules", fareReqObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp.data.fareRules) {
                setFareRulesResp(resp);
              }
            }


          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };

  const luggageDetails = () => {
    return (
      <div className="luggage-tooltip">
        <Row gutter={16}>
          {fareFamilies.fareFamilies[0].baggage.map((bag) => {
            return (
              <Col>
                <p>{bag.cityPair}</p>
                <p>
                  <i className="fa fa-plane"></i>{" "}
                  <span>Airline {bag.airline}</span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.baggageInfo} Check-In Baggage </span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };
  return (
    <>
      <div className="flight-timings">
        {fareFamilies.fareFamilies.length > 0 && (
          <div className="refundText">
            {flightInfo.isRefundable ? "" : "Non"} Refundable
          </div>
        )}
        <div className="timing_wrapper flight-syt-time">
          <div className="plane-timings">
            <ul>
              <li>
                <p>
                  {formatFlightTime(
                    flightInfo.flightSegments[0].departureDateTime
                  )}
                </p>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {" "}
                <div className="travel-time">

                  <div>
                    <p>{flightInfo?.duration}</p>
                  </div>

                </div>
              </li>
              <li>
                <LineOutlined />
              </li>
              <li>
                {flightInfo.flightSegments.length > 0 ? (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo.flightSegments[
                          flightInfo.flightSegments.length - 1
                        ].arrivalDateTime
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      {formatFlightTime(
                        flightInfo.flightSegments[0].arrivalDateTime
                      )}
                    </p>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className="facility-icons">
          <Tooltip placement="bottom" title={luggageDetails}>
            <p className="details-bag-icon" style={{ marginRight: 18 }}>
              Baggage <ShoppingOutlined />
            </p>
          </Tooltip>
        </div>
        <div className="details-btn">
          <p className="details-text" onClick={showModal}>
            Details
          </p>
        </div>
      </div>

      <div>
        <Modal
          title="Flight Details"
          visible={flightDetails}
          footer={[null]}
          onOk={(e) => setFlightDetails(false)}
          onCancel={(e) => setFlightDetails(false)}
          className="flight-details-modal"
        >
          <div className="all-times-local">
            <Tabs
              defaultActiveKey="1"
              onTabClick={(str, e) => fetchFlightFares(str, e)}
            >
              <TabPane tab="Segments" key="1">
                <div className="from-to-modal-details">
                  <Row gutter={16} className="more-details-row">
                    <Col md={14} xs={24}>
                      <Row align="middle" gutter={16}>
                        <Col md={8} xs={8} className="more-details-list">


                          <span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                            {flightInfo.originCity}
                          </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                            ({flightInfo.flightSegments[0].origin})
                          </span>


                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[0].departureDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                        <Col md={8} xs={8}>
                          <div className="break-journey-city">
                            <div className="inner_wrapper">
                              {flightInfo.flightSegments.map(
                                (flightSegInfo, index) =>
                                  index !== 0 ? (
                                    <div
                                      className="stop_points"
                                      key={flightSegInfo.segId}
                                    >
                                      <Tooltip
                                        placement="top"
                                        title={() => (
                                          <div className="tooltip-data">
                                            {flightInfo.flightSegments.length -
                                              1 >
                                              1 ? (
                                              <h4>Change</h4>
                                            ) : (
                                              ""
                                            )}
                                            <p>
                                              {flightSegInfo.origin +
                                                " " +
                                                flightSegInfo.origin}
                                            </p>
                                          </div>
                                        )}
                                        className="citynames-tooltip"
                                      >
                                        <span className="break-city-name pointer_cursor">
                                          {flightSegInfo.origin}
                                        </span>
                                      </Tooltip>
                                    </div>
                                  ) : null
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={8} xs={8} className="more-details-list">
                          <p>
                            <span className="code pointer_cursor" style={{ fontSize: "16px", fontWeight: 600 }}>
                              {flightInfo.destinationCity}
                            </span><span className="code pointer_cursor" style={{ fontSize: "12px", fontWeight: 600 }}>

                              ({flightInfo.flightSegments[
                                flightInfo.flightSegments.length - 1
                              ].destination})
                            </span>
                          </p>
                          <p>
                            <strong>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "HH:MM"
                              )}
                              ,
                            </strong>
                            <span>
                              {dateFormat(
                                flightInfo.flightSegments[
                                  flightInfo.flightSegments.length - 1
                                ].arrivalDateTime,
                                "dd mmm (ddd)"
                              )}
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} xs={24} className="trip-duration-time">
                      <p>Total trip duration:</p>
                      <p style={{ fontWeight: 'bolder' }}>
                        {flightInfo.flightSegments.length > 0
                          ? calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[
                              flightInfo.flightSegments.length - 1
                            ].arrivalDateTime
                          )
                          : calculateDuration(
                            flightInfo.flightSegments[0].departureDateTime,
                            flightInfo.flightSegments[0].arrivalDateTime
                          )}{" "}
                        |
                        {flightInfo.flightSegments.length - 1 === 0 ? (
                          <>Direct</>
                        ) : (
                          <>{flightInfo.flightSegments.length - 1} stops </>
                        )}
                      </p>
                    </Col>
                    <Col md={4} xs={24} className="trip-duration-time">
                      <p>class:</p>
                      <p>{airSearchData.cabinClass}</p>
                    </Col>
                  </Row>
                </div>

                {flightInfo.flightSegments.map((flightSegment, i) => (
                  <div
                    className="total-flight-details"
                    key={"flighttiming" + i}
                  >
                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.origin}</span>
                      </div>
                      <div>
                        <span>
                          departure:{" "}
                          <strong>
                            {dateFormat(
                              flightSegment.departureDateTime,
                              "HH:MM"
                            )}
                          </strong>
                          {" , "}
                        </span>
                        <span>
                          {dateFormat(
                            flightSegment.departureDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                        <span>
                          <strong>
                            {"  "}
                            {flightInfo.airLineName}(
                            {flightSegment.marketingAirline}-
                            {flightSegment.flightNumber})
                          </strong>
                        </span>
                      </div>
                    </div>
                    <div className="collapsed-card">
                    </div>

                    <div className="to-fro-flight-details">
                      <div className="from-city-flight-details">
                        <span>{flightSegment.destination}</span>
                      </div>
                      <div>
                        <span>
                          arrival:{" "}
                          <strong>
                            {formatFlightTime(flightSegment.arrivalDateTime)}
                          </strong>
                        </span>
                        {" , "}
                        <span>
                          {dateFormat(
                            flightSegment.arrivalDateTime,
                            "dd mmm (ddd)"
                          )}
                        </span>
                      </div>
                    </div>
                    {i < flightInfo.flightSegments.length - 1 && (
                      <div className="halt-wrapper">
                        <p>
                          Layover Time:{" "}
                          {calculateDuration(
                            flightInfo.flightSegments[i + 1].departureDateTime,
                            flightSegment.arrivalDateTime
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </TabPane>

              {cmsFareRules?.cancelPolicyDescription ? (
                <TabPane tab="Cancel Policy" key="3">
                  <div className="fareRule-container">
                    <div>
                      {ReactHtmlParser(cmsFareRules.cancelPolicyDescription)}
                    </div>
                  </div>
                </TabPane>
              ) : (
                <TabPane tab="Cancel Policy" key="2">
                  <div className="fareRule-container">
                    {!fareRulesResp ? (
                      <p>Loading...</p>
                    ) : (
                      fareRulesResp.data.fareRules.map((rule, i) => {
                        return (
                          <div key={i}>
                            <pre>{ReactHtmlParser(rule.ruleDetails)}</pre>
                          </div>
                        );
                      })
                    )}
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.reschedulePolicyDescription && (
                <TabPane tab="Reschedule Policy" key="4">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(
                        cmsFareRules.reschedulePolicyDescription
                      )}
                    </pre>
                  </div>
                </TabPane>
              )}

              {cmsFareRules?.covidRulesDescription && (
                <TabPane tab="Covid Rules Policy" key="5">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.covidRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
              {cmsFareRules?.otherRulesDescription && (
                <TabPane tab="Other Rules Policy" key="6">
                  <div className="fareRule-container">
                    <pre>
                      {ReactHtmlParser(cmsFareRules.otherRulesDescription)}
                    </pre>
                  </div>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Modal>
      </div>
    </>
  );
};

const Flight = (props) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  const { TabPane } = Tabs;
  const flightInfo = props.flightInfo;
  const flightSearchObj = props.flightSearchObj;
  const fareFamilies = props.fareFamilies;
  const promoData = props.promoData;
  const selectedFlight = props.selectedFlight;
  const MultiCityIndex = props.MultiCityIndex;
  const showNetFare = props.showNetFare;
  const index = props.index;
  const changeFlightResultsView = props.changeFlightResultsView;
  const journeyType = props.journeyType;
  const [cmsFareRules, setCmsFareRules] = useState({});
  const { Panel } = Collapse;
  const [flightArr, setFlightArr] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [act, setact] = useState(0)
  const onClickChooseFlight = (fareFamiliesIndex) => {
    try {
      if (
        (flightSearchObj.resultsType === "Combined" &&
          flightSearchObj.airTravelType === "roundTrip") || (flightSearchObj.resultsType === "Combined" &&
            flightSearchObj.airTravelType === "multidestination")
      ) {
        flightInfo.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });
        changeFlightResultsView(flightArr);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "roundTrip"
        ) {
          if (journeyType === "return") {
            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;

              changeFlightResultsView(copySelectedFlights);
            } else {
              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;

            changeFlightResultsView(copySelectedFlights);
          }

        } else if (selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "multidestination" && flightSearchObj.resultsType == "Separate") {
          let copyarray = flightInfo;
          let copy = flightInfo.fareFamilies.fareFamilies.filter((_, index) => index == fareFamiliesIndex);
          copyarray.fareFamilies.fareFamilies = copy;
          if (selectedFlight.length <= flightSearchObj.originDestinations.length) {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[MultiCityIndex] = copyarray;

            changeFlightResultsView(copySelectedFlights);
          }
          else {

            changeFlightResultsView([...selectedFlight, flightInfo]);
          }
        } else {
          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [fareRulesResp, setFareRulesResp] = useState("");
  const { user } = useAuthContext();
  const [flightDetails, setFlightDetails] = useState(false);
  const [flightdetailsactivecss, setflightdetailsactivecss] = useState({
    flightdetails: true, cancel: false, reschedule: false
    , covidRules: false, otherRules: false
  })
  const showModal = () => {
    let obj = {
      airlineCode: flightInfo.airLine,
      fareType: flightInfo.fareFamilies.fareFamilies[0].coupanType,
      roleID: user?.Role?.RoleId ?? 4,
      membershipID: user?.Membership ?? 1,
    };
    getCmsFareRules(obj);
  };
  const fetchFlightFares = (str, event) => {
    if (str === "2") {
      let fareReqObj = {
        traceId: flightSearchObj.traceId,
        flightId: flightInfo.flightId,
        airTravelType: flightSearchObj.airTravelType,
        mappingType: flightSearchObj.resultsType,
        itineraryViewType: "1",
      };
      ApiClient.post("flights/airFareRules", fareReqObj)
        .then((result) => {
          return result;
        })
        .then((resp) => {
          if (resp.statusCode === 200) {
            if (resp.data) {
              if (resp.data.fareRules) {
                setFareRulesResp(resp);
              }
            }

          } else {
            let error_resp = {
              data: {
                fareRules: [
                  {
                    ruleDetails:
                      "Something went wrong , Fare Rules not Available",
                  },
                ],
              },
            };
            setFareRulesResp(error_resp);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const getCmsFareRules = (obj) => {
    ApiClient.get("admin/getFlightFareRules", obj)
      .then((res) => {
        if (res.status === 200) {
          setCmsFareRules(res.data);
          setFlightDetails({
            visible: true,
          });
        } else {
          setCmsFareRules({});
          setFlightDetails({
            visible: true,
          });
        }
      })
      .catch((e) => {
        setCmsFareRules({});
        setFlightDetails({
          visible: true,
        });
      });
  };
  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };
  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = (e) => {
    setOpenMailModal(false);
  };
  const [openNumberModal, setOpenNumberModal] = useState(false);

  const clickOk = (e) => {
    setOpenNumberModal(false);
  };

  const clickCancel = (e) => {
    setOpenNumberModal(false);
  };
  const [Emailform] = Form.useForm();

  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchObj.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
        ).toFixed(2),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };
  const priceToolTip = () => {
    let base = 0;
    let service = 0;
    let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);
    let TotalBase = Number(flightInfo.fareFamilies.fareFamilies[0].totalBaseFare);
    let TotalPublish = Number(flightInfo.fareFamilies.fareFamilies[0].totalPublishFare);
    let Nettax = (TotalPublish - TotalBase);
    flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
      if (fare.fareTag === "Base") {
        base += fare.amount;

      } else if (fare.fareTag === "Tax") {
        service += fare.amount;

      }
    });

    return (
      <div className="pointer_cursor">
        <p>
          Base Fare: {activeCurrency} {currencyValue(TotalBase)}
        </p>
        <p>
          Tax Charges: {activeCurrency} {currencyValue(Nettax)}
        </p>
      </div>
    );
  };

  const AirportToolTip = (heading, code, name) => {
    return (
      <div className="tooltip-data">
        <h4>{heading}</h4>
        <p>{code}</p>
        <p>{name}</p>
      </div>
    );
  };

  return (<>
    <div className={" flight-results-container"} >
      <div className="combined-view">
        <div className="combined-flight-results-container">
          <Card className="combined-flight-card">
            <Row className="flight-trip-details">
              <Col md={24} xs={24} >
                <div className="main-flightres-box" >
                  {/* <div className="flightres_img_details1">
                    <img
                      className="airline-img"
                      src={flightInfo.airLineLogo}
                      alt={flightInfo.airLineName}
                    />
                  </div> */}
                  {/* <div className="flightres_img_details">
                    <img
                      className="airline-img"
                      src={flightInfo.airLineLogo}
                      alt={flightInfo.airLineName}
                    />
                  </div> */}
                  <div className="flightres_img_details">
                    <CustomflightImg
                      className="airline-img"
                      src={flightInfo.airLineLogo}
                      alt={flightInfo.airLine}
                    />
                  </div>
                  <div className="common_flight_headings1">
                    <p className="upper_headings1" style={{ margin: '0px 0px 0px', fontSize: '12px', fontWeight: 'bolder' }}> {flightInfo.airLineName}</p>
                    <p className="lower_heading1" style={{ margin: '0px 0px 0px', fontSize: '11px', fontWeight: 'bolder' }}>{flightInfo.flightSegments[0].marketingAirline} -
                      {flightInfo.flightSegments[0].flightNumber}</p>
                    <p className="lower_heading2" style={{ margin: '0px 0px 0px', fontSize: '11px', fontWeight: 'bolder' }}>
                      {flightInfo.isRefundable ? <span style={{ color: "green" }}>
                        Refundable</span> : <span style={{ color: "red", whiteSpace: "nowrap", fontWeight: 'bolder' }}>
                        Non-Refundable</span>}
                    </p>
                  </div>

                  <div className="common_flight_headings">
                    <p className="upper_headings2" style={{ margin: '0px 0px 0px', fontSize: '16px', fontWeight: '700' }} >  {formatFlightTime(
                      flightInfo.flightSegments[0].departureDateTime
                    )}</p>
                    <p className="lower_heading2" style={{ margin: '0px 0px 0px', fontSize: '13px', fontWeight: 'bolder' }}  >{flightInfo.originCity}</p>
                  </div>
                  <div className="common_flight_headings">
                    <p className="upper_heading2" style={{ margin: '0px 0px 0px', fontSize: '13px', fontWeight: 'bolder' }} >{flightInfo?.duration}</p>
                    <p className="diff_line_segments" style={{ margin: '0px 0px 0px', fontWeight: 'bolder' }} >
                      {flightInfo.flightSegments.map((flight, i) => (i != 0 && <span key={i} className="segments"></span>
                      ))}</p>
                    <div className="arrow-flightres" ><span class="arrow right arrowarroe" ></span></div>
                    {flightInfo.flightSegments.length > 1 ?
                      <p className="lower_heading" style={{ margin: '0px 0px 0px', fontSize: '11px', fontWeight: 'bolder' }} >{flightInfo.flightSegments.length - 1} Stops</p> : "Direct"}
                  </div>
                  <div className="common_flight_headings">
                    <div className="common_flight_headings_sub">
                      <p className="upper_headings2" style={{ margin: '0px 0px 0px', fontSize: '16px', fontWeight: '700' }} >{flightInfo.flightSegments.length > 0 ? formatFlightTime(
                        flightInfo.flightSegments[
                          flightInfo.flightSegments.length - 1
                        ].arrivalDateTime
                      ) : formatFlightTime(
                        flightInfo.flightSegments[0
                        ].arrivalDateTime
                      )}</p>
                      <p className="lower_heading2" style={{ margin: '0px 0px 0px', fontSize: '13px', fontWeight: 'bolder' }} >{flightInfo.destinationCity}</p>

                    </div>
                  </div>
                  <div className="flightseg-for-mob" >
                    <p className="upper_headings" style={{ margin: '0px 0px 0px', fontWeight: 'bolder' }}> {flightInfo.airLineName} </p>
                    <p className="lower_heading" style={{ margin: '0px 0px 0px', fontWeight: 'bolder' }}>{flightInfo.flightSegments[0].marketingAirline} |
                      {flightInfo.flightSegments[0].flightNumber}</p>
                  </div>
                </div>
                {promoData && !agent
                  ? promoData.length > 0 && (
                    <div className="promo-strip">
                      <div  >
                        <span className="promoData">
                          Use code {promoData[0].Code} to get FLAT{" "}
                          {promoData[0].Discount}{" "}
                          {promoData[0].DiscountType === 0 ? "" : "%"} instant
                          discount in this flight.
                        </span>
                      </div>
                    </div>
                  )
                  : ""}

              </Col >

            </Row >

          </Card >
        </div >
      </div >
    </div >
  </>
  );
};

export const FlightSelection = ({
  flightInfo,
  changeFlightResultsView,
  flightSearchObj,
  selectedFlight,
  showNetFare,
  journeyType = false,
  MultiCityIndex,
  setActiveCollapse, activeCollapse, index
}) => {
  const { Panel } = Collapse;
  const [flightArr, setFlightArr] = useState([]);
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    isLogin: { agent },
  } = useAuthContext();
  const onClickChooseFlight = (fareFamiliesIndex) => {
    try {
      if (
        (flightSearchObj.resultsType === "COMBINED" &&
          flightSearchObj.airTravelType === "roundTrip") || (flightSearchObj.resultsType === "COMBINED" &&
            flightSearchObj.airTravelType === "multidestination")
      ) {
        flightInfo.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });
        changeFlightResultsView(flightArr);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "roundTrip"
        ) {
          if (journeyType === "return") {
            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;

              changeFlightResultsView(copySelectedFlights);
            } else {
              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;

            changeFlightResultsView(copySelectedFlights);
          }

        } else if (selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "multidestination" && flightSearchObj.resultsType == "SEPARATE") {
          let copyarray = flightInfo;
          let copy = flightInfo.fareFamilies.fareFamilies.filter((_, index) => index == fareFamiliesIndex);
          copyarray.fareFamilies.fareFamilies = copy;
          if (selectedFlight.length <= flightSearchObj.originDestinations.length) {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[MultiCityIndex] = copyarray;

            changeFlightResultsView(copySelectedFlights);
          }
          else {

            changeFlightResultsView([...selectedFlight, flightInfo]);
          }
        } else {
          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
    }
  };

  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = (e) => {
    setOpenMailModal(false);
  };
  const [openNumberModal, setOpenNumberModal] = useState(false);

  const clickOk = (e) => {
    setOpenNumberModal(false);
  };

  const clickCancel = (e) => {
    setOpenNumberModal(false);
  };
  const [Emailform] = Form.useForm();

  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchObj.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
        ).toFixed(2),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };
  const priceToolTip = () => {
    let base = 0;
    let service = 0;
    let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);
    let Agentmarkup = Number(flightInfo.fareFamilies.fareFamilies[0].agentMarkup);

    flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
      if (fare.fareTag === "Base" && fare.paxType === "ADT") {
        base = fare.amount;


      } else if (fare.fareTag === "Tax" && fare.paxType === "ADT") {
        service = fare.amount;

      }
    });

    return (
      <div className="pointer_cur">
        <p>Per Pax's</p>
        <p>
          Base Fare: {activeCurrency} {currencyValue(base + markup)}
        </p>
        <p>
          Tax Charges: {activeCurrency} {currencyValue(service)}
        </p>
      </div>
    );
  };

  const luggageDetails = () => {
    return (
      <div className="luggage-tooltip">
        <Row gutter={16}>
          {flightInfo.fareFamilies.fareFamilies[0].baggage.map((bag) => {
            return (
              <Col>

                <p>{bag.cityPair}</p>
                <p>
                  <i className="fa fa-plane"></i>{" "}
                  <span>Airline {bag.airline}</span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.baggageInfo} Check-In Baggage </span>
                </p>
                <p>
                  <ShoppingOutlined className="ant-icon" />{" "}
                  <span>{bag.cabinBaggageInfo} Cabin Baggage</span>
                </p>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  const pricetool = () => { }
  return (
    <>
      <div className="choose-btn-block"   >
        <div className="share-price-mail">
          {selectedFlight[MultiCityIndex]?.flightId == flightInfo.flightId && MultiCityIndex != null ? <p style={{ marginTop: '3%', marginRight: '10%' }} >Selected</p> : ""}
          <div className="choose-btn-price">
            {showNetFare ? (
              <>
                <span className="currencyType text-line">{activeCurrency}</span>
                <Tooltip title={priceToolTip}>
                  <span className="currency text-line">
                    {
                      currencyValue(
                        flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                      )
                    }
                  </span >
                </Tooltip >

                <div>
                  <Tooltip placement="bottom" title={luggageDetails}>
                    <p className="details-bag-icon" style={{ marginRight: 18, fontWeight: 'bolder' }} >
                      Baggage <ShoppingOutlined />
                    </p>
                  </Tooltip>
                </div>

                <div className="text-left">
                  <p>
                    {activeCurrency}{" "}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].adultFare
                    )}{" "}
                  </p>
                  <p className="netfare">
                    Inc: {activeCurrency}{" "}
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].commission
                    )}{" "}
                  </p>
                </div>
                <p style={{ margin: '0' }} className="flight-class">
                  Coupan Type: {flightInfo.fareFamilies?.fareFamilies[0]?.coupanType}
                </p>
                {agent ?
                <p style={{ margin: '0', color: '#ffff', fontSize: '12px' }}>{flightInfo?.supplierKey}</p> : null}
                <div>
                  <Tooltip placement="bottom" title={luggageDetails}>
                    <p className="details-bag-icon" style={{ marginRight: 18, fontWeight: 'bolder' }}>
                      Baggage <ShoppingOutlined />
                    </p>
                  </Tooltip>
                </div>

              </>
            ) : (
              <>
                <span className="currencyType">
                  {activeCurrency}
                </span>
                <Tooltip title={priceToolTip}>
                  <span className="currency">
                    {currencyValue(
                      flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
                    )}
                  </span>

                </Tooltip>{" "}
                <p style={{ margin: '0'}} className="flight-class">
                  Coupan Type: {flightInfo.fareFamilies?.fareFamilies[0]?.coupanType}
                </p>
                {agent ?
                <p style={{ margin: '0', color: '#ffff', fontSize: '12px' }}>{flightInfo?.supplierKey}</p> : null}
                <div>
                  <Tooltip placement="bottom" title={luggageDetails}>
                    <p className="details-bag-icon" style={{ marginRight: 18, fontWeight: 'bolder' }}>
                      Baggage <ShoppingOutlined />
                    </p>
                  </Tooltip>
                </div>
              </>

            )}

          </div >
          <div className="right-price-section">
            <span className="price-text">
              Price for{" "}
              {flightSearchObj.adultCount +
                flightSearchObj.infantCount +
                flightSearchObj.childCount}{" "}
              passenger,
              <br />{" "}
              {flightSearchObj.airTravelType === "oneWay"
                ? "Oneway"
                : "Round Trip"}
            </span>
          </div>
        </div >
        <div className="choose-btn-section">
          <div className="choose-web-btn">
            <button
              className="choose-button"
              onClick={(e) => {
                let reqData = {
                  serviceType: 1,
                  airlineCode: flightInfo.airLine,
                };
                if (flightInfo.fareFamilies.fareFamilies.length == 1) {
                  onClickChooseFlight(0);
                } else {

                  if (activeCollapse === index) {
                    setActiveCollapse("");
                  } else {
                    setActiveCollapse(index);
                  }
                }
              }}
            >
              {(activeCollapse === index && activeCollapse != null) ? "Hide Prices" : flightInfo.fareFamilies.fareFamilies.length == 1 ? "Book Now" : "View Prices"}
            </button>
          </div>

        </div>

        <Modal
          title="Send Flight Information"
          visible={openMailModal}
          onOk={openOk}
          onCancel={OpenCancel}
          footer={false}
          className="flight-infoModal"
          centered
          width={400}
        >
          <Form onFinish={sendEmail} form={Emailform}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Required!" },
                { type: "email", message: "Email is not a valid email" },
              ]}
            >
              <Input placeholder="Enter Email Address" />
            </Form.Item>

            <Button type="primary" htmlType={"submit"}>
              Send
            </Button>
          </Form>
        </Modal>
        <Modal
          title="Send Flight Information"
          visible={openNumberModal}
          onOk={clickOk}
          onCancel={clickCancel}
          className="flight-infoModal"
          footer={false}
          centered
          width={400}
        >
          <Input placeholder="Enter Mobile Number" />
          <Button type="primary">Send</Button>
        </Modal>
      </div >
    </>
  );
};
export const FlightSe = ({
  flightInfo,
  changeFlightResultsView,
  flightSearchObj,
  selectedFlight,
  showNetFare,
  journeyType = false,
  MultiCityIndex,
  setActiveCollapse, activeCollapse, index,
  traceId,
  ListType
}) => {
  const {
    updateSelectedFlight,
  } = useFlightContext();
  const { Panel } = Collapse;
  const [flightArr, setFlightArr] = useState([]);
  const [flightInfoCopy, setFlightInfoCopy] = useState(flightInfo)
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [FareDetails, setFareDetails] = useState(false);
  const [FareRules, setFareRules] = useState();
  const [flightdetailsactivecss, setflightdetailsactivecss] = useState({
    flightdetails: true, cancel: false, reschedule: false
    , covidRules: false, otherRules: false
  })
  const [act, setact] = useState(0)
  const [fareIndex, setfareIndex] = useState(0);
  const onClickChooseFlight = (fareFamiliesIndex) => {
    try {
      if (
        flightSearchObj.resultsType === "COMBINED" &&
        flightSearchObj.airTravelType === "roundTrip"
      ) {

        flightInfo.flightDetails.map((flightDetObj) => {
          flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
          flightArr.push(flightDetObj);
          setFlightArr([flightArr]);
        });
        changeFlightResultsView(flightArr);
      } else {
        if (
          selectedFlight.length > 0 &&
          flightSearchObj.airTravelType === "roundTrip"
        ) {
          if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
            let FareFamiliesList = getFaredetails([...flightInfo.fareFamilies.fareFamilies], fareFamiliesIndex)

            flightInfo.fareFamilies.fareFamilies = FareFamiliesList
          }
          if (journeyType === "return") {

            if (selectedFlight.length === 2) {
              let copySelectedFlights = [...selectedFlight];
              copySelectedFlights[1] = flightInfo;

              changeFlightResultsView(copySelectedFlights);
            } else {

              changeFlightResultsView([...selectedFlight, flightInfo]);
            }
          } else {
            let copySelectedFlights = [...selectedFlight];
            copySelectedFlights[0] = flightInfo;
            changeFlightResultsView(copySelectedFlights);
          }
        } else if (flightSearchObj.airTravelType === "multidestination") {
          if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
            let FareFamiliesList = getFaredetails([...flightInfo.fareFamilies.fareFamilies], fareFamiliesIndex)

            flightInfo.fareFamilies.fareFamilies = FareFamiliesList
          }


          flightInfo.flightDetails.map((flightDetObj) => {
            flightDetObj["fareFamilies"] = flightInfo.fareFamilies;
            flightArr.push(flightDetObj);
            setFlightArr([flightArr]);
          });
          changeFlightResultsView(flightArr);


        } else {
          if (flightInfo?.fareFamilies?.fareFamilies?.length > 1) {
            let FareFamiliesList = getFaredetails([...flightInfo.fareFamilies.fareFamilies], fareFamiliesIndex)

            flightInfo.fareFamilies.fareFamilies = FareFamiliesList
          }

          changeFlightResultsView([flightInfo]);
        }
      }
    } catch (error) {
    }
  };
  const getFaredetails = (fareArr, fareFamiliesIndex) => {
    let FareIndexData = [];
    let ZeroIndexData = [];
    let FareFamiliesList = fareArr
    FareIndexData = FareFamiliesList[fareFamiliesIndex];
    ZeroIndexData = FareFamiliesList[0];
    FareFamiliesList[0] = FareIndexData;
    FareFamiliesList[fareFamiliesIndex] = ZeroIndexData;
    return FareFamiliesList;

  }
  const [openMailModal, setOpenMailModal] = useState(false);
  const showMailModal = () => {
    setOpenMailModal(true);
  };
  const openOk = (e) => {
    setOpenMailModal(false);
  };

  const OpenCancel = (e) => {
    setOpenMailModal(false);
  };
  const [openNumberModal, setOpenNumberModal] = useState(false);
  const showNumberModal = () => {
    setOpenNumberModal(true);
  };

  const clickOk = (e) => {
    setOpenNumberModal(false);
  };

  const clickCancel = (e) => {
    setOpenNumberModal(false);
  };
  const [Emailform] = Form.useForm();
  const getCoupanTypeData = (key, coupanType) => {

  };
  const getAdminFareRules = (fareReqObj) => {

  };
  const formatFlightTime = (flightTime) => {
    let [date, time] = flightTime.split("T");
    let [hour, minute, seconds] = time.split(":");
    return `${hour}:${minute}`;
  };
  const sendEmail = (formData) => {
    let emailData = {
      to: formData.email,
      data: {
        url: document.location.href,
        airLine: flightInfo.airLine,
        airLineLogo: flightInfo.airLineLogo,
        airLineName: flightInfo.airLineName,
        cabinClass: flightSearchObj.cabinClass,
        currency: flightInfo.fareFamilies.fareFamilies[0].currency,
        destiantionName: flightInfo.destiantionName,
        destination: flightInfo.destination,
        duration: flightInfo.duration,
        flightId: flightInfo.flightId,
        flightSegments: flightInfo.flightSegments,
        isBookOffline: flightInfo.isBookOffline,
        origin: flightInfo.origin,
        originName: flightInfo.originName,
        totalFare: Number(
          flightInfo.fareFamilies.fareFamilies[0].adultPublishFare
        ).toFixed(2),
      },
      cc: "",
      bcc: "",
      subject: "Flight Information",
    };

    ApiClient.post("email/flightsegment", emailData)
      .then((res) => {
        if (res.status === 200) {
          message.success("Mail Sent Successfully", 3);
          Emailform.resetFields();
          OpenCancel();
        }
      })
      .catch((e) => console.log(e));
  };
  const priceToolTip = () => {
    let base = 0;
    let service = 0;
    let markup = Number(flightInfo.fareFamilies.fareFamilies[0].markup);

    flightInfo.fareFamilies.fareFamilies[0].flightFares.forEach((fare) => {
      if (fare.fareTag == "Base") {
        base += fare.amount;

      } else if (fare.fareTag == "Tax") {
        service += fare.amount;

      }
    });

    return (
      <div className="pointer_cursor">
        <p>
          Base Fare: {activeCurrency} {currencyValue(base + markup)}
        </p>
        <p>
          Tax Charges: {activeCurrency} {currencyValue(service)}
        </p>
      </div>
    );
  };
  const getCancellationDetails = (id) => {

    let FareRuleObj = {
      flowType: "SEARCH",
      traceId: traceId,
      flightId: flightInfo?.flightId,
      airTravelType: flightSearchObj.airTravelType,
      priceId: id,
      mappingType: flightSearchObj.resultsType,
      itineraryViewType: "1",

    }
    ApiClient.post("flights/airFareRules", FareRuleObj)
      .then((response) => {
        if (response.statusCode == 200) {
          setFareRules(response?.data?.fareRules)
          setFareDetails(true);
        } else if (response.status == 404) {
          message.error(response.message, 3);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      {ListType && (<div className="flightdeatils_drop">
        <span style={{ fontSize: '13px' }} className="flightdeatils_drop_text" onClick={() => { setact(1); }}
        ><a>Flight Details</a></span>
        {flightInfo.fareFamilies.fareFamilies.map((item, indexP) => {
          return (
            <span>{flightInfo.fareFamilies.fareFamilies.length == 1 ? <span className="View_Cancel-detailss" onClick={() => getCancellationDetails(item.fareId)}><a>Fare Rules</a></span> : ""} </span>
          )
        })}

      </div>
      )}




      {ListType && (act === 1 && <Collapse activeKey={act} bordered={false}>

        <Panel showArrow={false} key={1}>
          <div className="flight_deatails">
            <div className="flight_details_headings">
              <div className="active_tab" onClick={() => setflightdetailsactivecss((prev) => ({ ...prev, flightdetails: true }))}
                style={{ backgroundColor: flightdetailsactivecss?.flightdetails ? "#0091FF" : "" }}><a>Flight Details</a></div>
            </div>
            <div className="active_tab tab-X-one">
              <CloseOutlined onClick={() => setact(0)} />
            </div>
            <div className="flightdetails_content" >
              <div className="Journey_heading">
                <p className="journey_route">{flightInfo?.originCity} To {flightInfo?.destinationCity} , {moment(flightInfo?.flightSegments[0].departureDateTime).format("MMM D")}</p>
              </div>
              <div>
                {flightInfo.flightSegments.map((flightSegment, i) => (
                  <div>
                    <div style={{ display: 'flex' }}>
                      <div className="flight_logo_up">
                        {/* <img className="flight_logo" src={`https://www.gstatic.com/flights/airline_logos/70px/${flightSegment?.operatingAirline}.png`}></img> */}
                        <CustomflightImg
                      className="airline-img"
                      src={flightSegment.airLineLogo}
                      alt={flightSegment.airLineName}
                    />
                        <span className="airLine"><b>{flightSegment?.airLineName}</b></span>
                        <span className="flight_number">{flightSegment?.operatingAirline}|{flightSegment?.operatingFlightNumber}</span>
                      </div>
                      <Row gutter={16} className="flight_timing">
                        <Col className="origin_details">
                          <p className="time">
                            {flightSegment?.originCity}<br />
                            {formatFlightTime(
                              flightSegment.departureDateTime
                            )}
                          </p>
                          <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                            {moment(
                              flightSegment.departureDateTime
                            ).format("dddd, Do MMMM YY")}
                          </p>
                          <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.departureTerminal}</p>
                        </Col >
                        <Col className="duration">
                          <i class="fa fa-clock-o" style={{ color: 'black', fontSize: '18px' }} ></i>
                          <p>{flightSegment?.journeyDuration}</p>
                        </Col>
                        <Col className="destination_details">
                          <p className="time">
                            {flightSegment?.destinationCity}<br />
                            {formatFlightTime(
                              flightSegment.arrivalDateTime
                            )}
                          </p>
                          <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                            {moment(
                              flightSegment.arrivalDateTime
                            ).format("dddd, Do MMMM YY")}
                          </p>
                          <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.arrivalTerminal}</p>
                        </Col >
                      </Row >
                    </div >
                    {i === flightInfo.flightSegments.length - 1 ? null : <div className="change_planes">
                      <div className="change_planes_border">Layover : &nbsp;
                        {moment.utc(moment(flightInfo.flightSegments[i + 1]?.departureDateTime).diff(moment(flightSegment.arrivalDateTime))).format('HH:mm')}
                      </div>
                    </div>}
                  </div >
                ))}
              </div >
            </div >
          </div >


        </Panel >
      </Collapse >)}
      {
        !ListType && <div className="flightdeatils_drop">
          <span style={{ fontSize: '13px' }} className="flightdeatils_drop_text" onClick={() => { setact(1); }}
          ><a>Flight Details</a></span>

        </div>
      }

      {
        (act === 1 && !ListType) && <Collapse activeKey={act} bordered={false}>
          <Panel showArrow={false} key={1}>
            <div className="flight_deatails">
              <div className="flight_details_headings">
                {flightInfo?.flightDetails.map((flight, id) => {
                  return <div className="active_tab" onClick={() => setfareIndex(id)}><a>Flight Details({flight?.origin}-{flight?.destination})</a>
                  </div>
                })}</div>
              <div className="active_tab tab-X-one">
                <CloseOutlined onClick={() => setact(0)} />
              </div>
              <div className="flightdetails_content" >

                <div>
                  {flightInfo?.flightDetails?.[fareIndex]?.flightSegments.map((flightSegment, i) => (

                    <div>
                      <div style={{ display: 'flex' }} >
                        <div className="flight_logo_up">
                          <img className="flight_logo" src={`https://www.gstatic.com/flights/airline_logos/70px/${flightSegment?.airLine}.png`}></img>
                          <span className="airLine"><b>{flightSegment?.airLineName}</b></span>
                          <span className="flight_number">{flightSegment?.operatingAirline}|{flightSegment?.operatingFlightNumber}</span>
                        </div>
                        <Row gutter={16} className="flight_timing">
                          <Col className="origin_details">
                            <p className="time">
                              {flightSegment?.originCity}<br />
                              {formatFlightTime(
                                flightSegment.departureDateTime
                              )}
                            </p>
                            <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                              {moment(
                                flightSegment.departureDateTime
                              ).format("dddd, Do MMMM YY")}
                            </p>
                            <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.departureTerminal}</p>
                          </Col >
                          <Col className="duration">
                            <i class="fa fa-clock-o" style={{ color: 'black', fontSize: '18px' }} ></i>
                            <p>{flightSegment?.journeyDuration}</p>
                          </Col>
                          <Col className="destination_details">
                            <p className="time">
                              {flightSegment?.destinationCity}
                              <br />
                              {formatFlightTime(
                                flightSegment.arrivalDateTime
                              )}
                            </p>
                            <p className="date_time" style={{ margin: '0px 0px 0px' }}>
                              {moment(
                                flightSegment.arrivalDateTime
                              ).format("dddd, Do MMMM YY")}
                            </p>
                            <p className="arrival" style={{ margin: '0px 0px 0px' }}>Terminal - {flightSegment?.arrivalTerminal}</p>
                          </Col >
                        </Row >
                      </div >
                      {
                        flightInfo.flightSegments?.length - 1 > i && <div className="change_planes">
                          <div className="change_planes_border">Change of planes</div>
                        </div>
                      }
                    </div >
                  ))
                  }
                </div >
              </div >
            </div >


          </Panel >
        </Collapse >}
      {
        index === activeCollapse ? <div className={flightInfo.fareFamilies.fareFamilies.length > 1 ? "FarDetailsCollapse" : "FarDetailsCollapse_none"}>
          <Collapse activeKey={activeCollapse} bordered={false}>
            <Panel key={index} showArrow={false}>
              {flightInfo.fareFamilies.fareFamilies.length > 0 && (
                <div className="fareTypeContainer mt-1">
                  <Row className="FareDetails_Heading"  >
                    <Col className="view-pricecolss">FARE TYPE</Col>
                    <Col className="view-pricecolss1">CABIN BAG</Col>
                    <Col className="view-pricecolss1">CHECK-IN</Col>
                    <Col className="view-pricecolss2">CANCELLATION</Col>
                    <Col className="view-pricecolss1">SEAT</Col>
                    <Col className="view-pricecolss1">Price</Col>
                  </Row >

                  {
                    flightInfo.fareFamilies.fareFamilies.map((item, indexP) => {
                      return (


                        <Row className="Faredetails_content"  >

                          <Col className="view-pricecolss">
                            <p className="FareTypeName">{item.coupanType}</p>
                            <p className="sub-FareTypeName">
                              {item.coupanType.toUpperCase() == "SMEFARE"
                                ? "Eligible for small or medium corporate customer."
                                : item.coupanType.toUpperCase() == "CORPORATEFARE"
                                  ? "Exclusively offered to organizations."
                                  : "Fare offered by airline."}
                            </p>
                            <p className="sub-FareTypeName">
                              <b>Sub-Cabin Class: {item.segmentInfos.length ? item.segmentInfos[0].cabinSubClass : ""}</b>
                            </p>
                          </Col>
                          <Col className="view-pricecolss1">
                            <p className="m-0 text">{item.baggage[0]?.cabinBaggageInfo}</p>
                          </Col>
                          <Col className="view-pricecolss1">
                            <p className="m-0 text">{item.baggage[0]?.baggageInfo}</p>
                          </Col>
                          <Col className="view-pricecolss2">

                            <Button style={{ width: '60%' }} className="View_Canceldetails" onClick={() => getCancellationDetails(item.fareId)}>View Details</Button>
                          </Col >

                          <Col className="view-pricecolss1">
                            <p className="m-0 text">{item.segmentInfos[0].seatRemaining} Seat Left</p>
                          </Col>
                          <Col className="view-pricecolss1">
                            {" "}
                            <p className="Fare_text">{activeCurrency} {currencyValue(item.adultNetFare?.toFixed(2))}</p>
                          </Col>
                          <Col className="Button_fare view-pricecolss3"  >
                            <div className="choose-web-btn text-right">
                              <Button
                                style={{ width: '100%' }}
                                className="choose-button"
                                onClick={() => {
                                  onClickChooseFlight(indexP);
                                }}
                              >
                                Book Now
                              </Button>
                            </div>
                          </Col>
                        </Row >
                      );
                    })
                  }
                </div >
              )}

              <div className="bottom-airline-text">
                <p className="static_text">
                  Airline reserves the right to change the Cancellation/Change Fees from time to time without any prior intimation. *Fee as per existing Regular Fare Policy applicable & may vary for
                  domestic and international bookings. **Days left for departure.
                </p>
              </div>
            </Panel >
          </Collapse >
        </div > : null}
      <div>
        <Modal
          title="Fare Rules"
          visible={FareDetails}
          footer={[null]}
          onOk={(e) => setFareDetails(false)}
          onCancel={(e) => setFareDetails(false)}
          className="flight-details-modal"
        >
          <div style={{ padding: "8px" }}>
            <b>CancellationPolicy : </b>
            <div>{FareRules ? FareRules.map((data, id) => {
              return (<div>
                <div>{id + 1}Flight</div>

                <div>{ReactHtmlParser(data.ruleDetails)}</div>
              </div >
              )
            }) : "No Rule"}</div >
          </div >
        </Modal >
      </div >
    </>
  );
};

export default Flight;
