import { CaretRightOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../components/Bookingreports/Bookingreports.scss";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import { useAuthContext } from "../../common/providers/AuthProvider";
import {
  allColumns,
  activitiesColumns,
  dateFormat,
  oriDateFormat,
  hotelColumns,
  renderReffrence,
  getStatus,
  buildPackageColumns,
  renderSingleRef,
  flightColumns,
} from "./superReportHelper";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
let uiDateFormat = "DD-MM-YYYY";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;

const HolidayBookingReports = (props) => {

  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [reportRole, setReportRole] = useState(0);
  const [travelType, setTravelType] = useState(1);
  const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm();

  const [showTable, setShowTable] = useState(false);

  const [isDateDisplay, setIsDateDisplay] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const [loading, setLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);

  const [toDisableDate, setToDisableDate] = useState(moment());
  const [arrivalDate, setArrivalDate] = useState(null);
  const [weekendDate, setWeekendDate] = useState(null);
  const [bookedType, setbookedType] = useState(null);
  const [excelList, setExcelList] = useState([]);

  useEffect(() => {
    getProviders();
  }, []);
  const getProviders = () => {
    ApiClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          let filterProvider = res.data.filter((item) => item.Status === 1);
          setAgentList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

 

  const onFormSubmit = (values) => {
     values.UserID = user?.UserID ?? 1
    if (values.options == 5) {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    if (values.journeyDate) {
      values.journeyDate = moment(values.journeyDate).format(oriDateFormat);
    } else {
      values.journeyDate = "";

    }

     setDataSource([]);
    setTableHeaders(1);

    getOrdersRequest(...values , user?.UserID ?? 1);

   };

  const getOrdersRequest = (values) => {
     ApiClient.get("HolidayExtranet/Holidayorders-filter", values)
      .then((res) => {
        setShowTable(true);
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              DepartureDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          if (bookedType != null) {
            data = data.filter((e) => e.pgType == (bookedType))
          }
          setOrdersList(data);
          defineExcelData(data);
        } else {
          setOrdersList([]);
        }
      })
      .catch((e) => {
        setOrdersList([]);
      });
  };

  const handleTimeAndDate = (value) => {
    if (value === 4) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const BookedType = (value) => {
    setbookedType(value)

  }
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        SrNo: item.SNo,
        ToursExPackagesID: item.ToursExPackagesID,
        ReferenceNo: item.ReferenceNo,
        PassengerName: item.PassengerName,
        MobileNumber: item.MobileNumber,
        EmailAddress: item.EmailAddress,
        PassengerName: item.PassengerName,
        Address: item.Address,
        AnyAdditionalRequest: item.AnyAdditionalRequest,
        PromoCode: item.PromoCode,
        AdvancePaid: item.AdvancePaid,
        FullAmountPaid: item.FullAmountPaid,
        VendorID: item.VendorID,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    const excelData = dataSource;
    props.exportExcel(excelData, "Booking_Reports");
  };

  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 2:
        setColumns(hotelColumns);
        break;
      
      case 4:
        setColumns(activitiesColumns);
        break;
      
      default:
        setColumns(allColumns);
    }
  };

 



  const searchTableData = (e) => {
    var searchVal = e.target.value;
    let prevData = dataSource;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(prevData);
    }
  };

 

  const BookingReportsHeader = () => (
    <>
      Holiday Reports{" "}
      <HelpInfoHelper screenName={"/admin/reports/holidayReport"} />
    </>
  );

  
   const onChangeFromDate = (current, dateString) => {
    // Can not select days before today and toda
    setArrivalDate(dateString);
  };
  const onChangeToDate = (current, dateString) => {
    // Can not select days before today and today
    setWeekendDate(dateString);
  };
  const disabledFromDate = (current) => {
    if (weekendDate != null) {
      return current.valueOf() > (new Date(weekendDate));
    }
    // return current && current > moment().endOf('day');

    return current.valueOf() > Date.now();
  }
  const disabledToDate = (current) => {
    // Can not select days before today and today
    return current.valueOf() > Date.now() || current.valueOf() < (new Date(arrivalDate));
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        <CaretRightOutlined rotate={isActive ? 90 : -90} />
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={<BookingReportsHeader />} key="1">
                      <Form
                        form={form}
                        layout={"vertical"}
                        initialValues={{
                          options: 5,
                          bookingStatus: "",
                          emailId: "",
                          journeyDate: "",
                          paxName: "",
                          phoneNumber: "",
                          referenceNo: "",
                          fareType: "",
                          travelType: 3,
                          Role: 0,
                          tripType: "",
                        }}
                        onFinish={onFormSubmit}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Time and Date"
                              name="options"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value={1}>Last One Hour</Option>
                                <Option value={2}>Yesterday</Option>
                                <Option value={3}>Last Week</Option>
                                <Option value={5}>Custom Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {isDateDisplay ? (
                            <>
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                     style={{ width: "100%" }}
                                    format={"YYYY-MM-DD"}
                                    
                                    disabledDate={disabledFromDate}
                                    onChange={(date, dateString) =>
                                      onChangeFromDate(date, dateString)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="YYYY-MM-DD"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledToDate}
                                   onChange={(date, dateString) =>
                                     onChangeToDate(date, dateString)
                                   } 
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}

                          <Col md={6} xs={24}>
                            <Form.Item label="Reports of" name="Role">
                              <Select
                                placeholder="Select"
                                onSelect={(val) => setReportRole(val)}
                              >
                                <Option value={0}>All</Option>
                                <Option value={2}>Users</Option>
                                <Option value={4}>Guest</Option>
                                <Option value={5}>Partner</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {reportRole === 5 && (
                            <Col md={6} xs={24}>
                              <Form.Item label="Partner" name="UserId">
                                <Select placeholder="Select Partner">
                                  {agentList.map((data, index) => (
                                    <Option key={index} value={data.UserID}>
                                      {data.userDetails.FirstName}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}

                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Reference number"
                              name="referenceNo"
                            >
                              <Input placeholder="Enter Reference Number" />
                            </Form.Item>
                          </Col>
                          <Col md={6} xs={24}>
                            <Form.Item 
                            label="Tour Packages" 
                            name= "Tour packages"
                            >
                                <Input placeholder="Enter Tour Packages"/>
                            </Form.Item>
                          </Col>

                        
                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Pax Email"
                              name="emailId"
                              rules={[{ type: "email" }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Pax Mobile"
                              name="phoneNumber"
                              rules={[
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>

                          <Col md={6} xs={24}>
                            <Form.Item
                              label="Booking Status"
                              name="bookingStatus"
                            >
                              <Select placeholder="Please Select">
                                <Option value={2}>Blocked</Option>
                                <Option value={3}>Confirmed</Option>
                                <Option value={4}>Cancelled</Option>
                               
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="Booking Type"
                            name="BookingType"
                          >
                            <Select
                              placeholder="Please select"
                              onChange={BookedType}
                            >
                              <Option value={3}>Online</Option>
                              <Option value={2}>Offline</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        </Row>
                        <Row className="reports-btns">
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => setShowTable(true)}
                          >
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              setReportRole(0);
                              //   setTravelType(0);
                              form.resetFields();
                            }}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {dataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>Flights Reports {dataSource.length}</h5>
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              scroll={{ x: true }}
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(HolidayBookingReports);
