import React, { useState } from "react";
// import hotelNoImage from "../../assets/images/hotels/no_img.png";
import fli8 from "../../assets/images/Icons/plane-ic.png"

const CustomflightImg = ({ src, alt }) => {
  const [showImage, setShowImage] = useState(true);


  const [img, setimg] = useState(alt === 'IX1' ||alt === 'I51' || alt === 'YY' ? 'I5' : alt);
  // console.log(img,"alt")
  return (
      <img
          src={showImage ? src : `http://www.gstatic.com/flights/airline_logos/70px/${img}.png`}
          //   style={{ height: "100%" }}
          alt="image"
          className="airline-img"
          onError={() => setShowImage(false)}
      />
  );

//   {console.log(src,"ima")}
  // return (
  //   <img
  //     className="logo-fli"
  //     src={showImage ? src : fli8}
  //     alt={className}
  //     style={{height:"50px"}}
  //     onError={() => setShowImage(false)}
  //   />
  // );
};
export default CustomflightImg;