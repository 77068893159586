import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useState } from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";

import search from "../assets/vector-icons/search.png";
import "./ServiceType.scss";

const ServiceType = () => {
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    form.setFieldsValue({
      ...rec,
    });

    setIsEditMode(true);

    showModal();
  };

  const [dataSource, setDataSource] = useState([]);
  const getAllServiceTypes = () => {
    setDataSource([]);
    ApiClient.get("admin/services").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  useEffect(() => {
    getAllServiceTypes();
  }, []);

  const SubmitServiceType = (formvalues) => {
    ApiClient.post("admin/service", formvalues).then((response) => {
      if (response.status === 200) {
        setModalVisible(false);
        message.success("ServiceType created successfully");

        getAllServiceTypes();
        form.resetFields();
      } else {
        message.error(" Error Creating ServiceType ");
      }
    });
  };

  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      UpdateServiceType(formvalues);
    } else {
      SubmitServiceType(formvalues);
    }
  };

  const UpdateServiceType = (formvalues) => {
    const updatedFormData = {
      ...formvalues,
    };

    ApiClient.put("admin/service/" + id, {}, updatedFormData).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          message.success("ServiceType updated successfully");

          getAllServiceTypes();
          form.resetFields();
        } else {
          message.error(" Error Updating ServiceType ");
        }
      }
    );
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteCarType = (id) => {
    ApiClient.delete("carextranet/deletecartype/" + id).then((res) => {
      if (res.status == 200) {
        message.success("ServiceType Deleted  successfully", 3);
        getAllServiceTypes();
      }
    });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setId(rec.ServiceID);
              handleEditClick(rec);
            }}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCarType(rec.CarTypeID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;

  const columns = [
    {
      title: "Service Name",
      dataIndex: "ServiceName",
    },

    {
      title: "URL",
      dataIndex: "Url",
    },
    {
      title: "ServiceType Enum",
      dataIndex: "ServiceType",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info, status) => {
    let obj = {
      ServiceID: info.ServiceID,
      ServiceType: info.ServiceType,
      Status: status,
    };
    ApiClient.put("admin/service/" + obj.ServiceID, {}, obj)
      .then((response) => {
        
        if (response.status == 200) {

          message.success("Status updated successfully !");

          getAllServiceTypes();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>Service Types </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />

                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Service Type"
        className="promo-modal-header cartypes-value modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Service Name"
                rules={[{ required: true }]}
                name="ServiceName"
              >
                <Input placeholder="Please Enter Service Name" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Service Type"
                rules={[
                  { required: true },
                  {
                    pattern: /^[0-9]*$/,
                    message: "Please Enter Numeric Value",
                  },
                ]}
                name="ServiceType"
              >
                <Input placeholder="Please Enter Service URL" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="URL"
                rules={[
                  { required: true },
                  { type: "url", message: "Enter a Valid URL" },
                ]}
                name="Url"
              >
                <Input placeholder="Please Enter Service URL" />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
              }}
              danger
              className="cancel-btn"
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ServiceType;
