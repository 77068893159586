import React, { useState, useEffect } from "react";
import * as ANTD from "antd";

import "./Storevistors.scss";

import excel from "../../assets/vector-icons/excel.png";
import search from "../../assets/vector-icons/search.png";
import TextArea from "antd/lib/input/TextArea";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  Checkbox,
  Row,
  Form,
  Input,
  message,
  Upload,
  Modal,
  Button,
  Col,
} from "antd";
import { useAuthContext } from "../../common/providers/AuthProvider";

const Storevistors = (props) => {
  const { user } = useAuthContext();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = ANTD.Form.useForm();
  const [form2] = ANTD.Form.useForm();
  const [id, setId] = useState(-1);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [Agentlist, setAgentlist] = useState([]);
  useEffect(() => {
    getAllStoreVisitors();
  }, []);
  const BASE = process.env.REACT_APP_BASE_URL;

  const uploadActionUrl = BASE + "media/upload/singleImage";
  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imageUr, setImageUr] = useState();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [formData, setFormData] = useState([]);
  const [img, setImg] = useState("");

  function handleChange(info) {
    if (info.file.status === "error") {
      ANTD.message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status === 200) {
        setFormData({
          ...formData,
          Image: info.file.response.data.filepath,
        });
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  function handleChang(info) {
    if (info.file.status === "error") {
      ANTD.message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status === 200) {
        setFormData({
          ...formData,
          Image: info.file.response.data.filepath,
        });
        getBase64(info.file.originFileObj, (imageUr) => {
          setImageUr(imageUr);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image.png" || file.type === "image.jpeg";
    if (!checkJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }

    const checkFileSize = file.size / 1024 / 1024 < 2;
    if (!checkFileSize) {
      ANTD.message.error(" Image must be smaller than 2MB!");
    }

    return checkJpgOrPng && checkFileSize;
  }
  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  const handlePreview = async (file) => {

    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };
  const clear = () => {
    setModalVisible(false);
  };

  function chooseTitle(res) {
    let result = "";
    if (res === 1) {
      result += "Mr";
    }
    if (res === 2) {
      result += "Ms";
    }
    if (res === 3) {
      result += "Mrs";
    }
    if (res === "Mr") {
      result += "1";
    }
    if (res === "Ms") {
      result += "2";
    }
    if (res === "Mrs") {
      result += "3";
    }
    return result;
  }
  const updateFlightComm = (currentData) => {
    setIsEditMode(true);
    setId(currentData.VisitorsId);
    setImageUrl(currentData.Uploads);
    setFormData({
      ...formData,
      Image: currentData.Uploads ? currentData.Uploads.slice(28) : "",
    });

    form2.setFieldsValue({
      Title: chooseTitle(currentData.Title),
      FirstName: currentData.FirstName,
      LastName: currentData.LastName,
      Email: currentData.Email,
      MobileNumber: currentData.MobileNumber,
      Intrem: currentData.Remarks,
      ReminderType: chooseReminderType(currentData.ReminderType),
      image: currentData.Uploads,
    });
    setModalVisible(true);

  };

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}

        >
          <EditOutlined
            onClick={() => updateFlightComm(CurrentItem)}
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteFlightComm(CurrentItem.VisitorsId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  const columnsmanagebalstore = [
    {
      title: "S.No",
      dataIndex: "SNo",
    },

    {
      title: "Tittle",
      dataIndex: "Title",
    },

    {
      title: "First Name	",
      dataIndex: "FirstName",
    },

    {
      title: "Last Name",
      dataIndex: "LastName",
    },
    {
      title: "Mobile Number",
      dataIndex: "MobileNumber",
    },
    {
      title: "Interest / Remarks",
      dataIndex: "Remarks",
    },
    {
      title: "Remaind",
      dataIndex: "ReminderType",
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];
  function chooseTitle(res) {
    let result = "";
    if (res === 1) {
      result += "Mr";
    }
    if (res === 2) {
      result += "Ms";
    }
    if (res === 3) {
      result += "Mrs";
    }
    if (res === "Mr") {
      result += 1;
    }
    if (res === "Ms") {
      result += 2;
    }
    if (res === "Mrs") {
      result += 3;
    }
    return result;
  }
  function chooseReminderType(res) {
    let result = "";
    if (res.indexOf("1") !== -1) {
      result += " Mobile,";
    }
    if (res.indexOf("2") !== -1) {
      result += " Email,";
    }

    if (res.indexOf("3") !== -1) {
      result += " Chat,";
    }
    if (res.indexOf("4") !== -1) {
      result += " SocialMedia";
    }
    if (res.indexOf(" Mobile,") !== -1) {
      result += "1,";
    }
    if (res.indexOf(" Email,") !== -1) {
      result += "2,";
    }

    if (res.indexOf(" Chat,") !== -1) {
      result += "3,";
    }
    if (res.indexOf(" SocialMedia,") !== -1) {
      result += "4,";
    }
    return result;
  }

  const submitForm = (value) => {
    let Reminder_Type = value.ReminderType;

    var Userid = user.UserID;
    let data = {
      Uploads: `http://164.52.210.241/api/v1${formData.Image}`,
      UserID: Userid,
      Title: value.Title,
      FirstName: value.FirstName,
      LastName: value.LastName,
      Email: value.Email,
      MobileNumber: value.MobileNumber,
      Remarks: value.Intrem,
      ReminderType: Reminder_Type,
      // ReminderType": 1,
      //"Uploads": "string",
      DeviceOS: 0,
      DeviceOSVersion: "string",
      ApplicationType: 0,
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    modalVisible ? submitUpdateMap(data) : submitMapDetails(data);

  };
  const submitF = (value) => {

    let Reminder_Type = value.ReminderType;
    var Userid = user.UserID;
    let data = {
      Uploads: `http://164.52.210.241/api/v1${formData.Image}`,
      UserID: Userid,
      Title: value.Title,
      FirstName: value.FirstName,
      LastName: value.LastName,
      Email: value.Email,
      MobileNumber: value.MobileNumber,
      Remarks: value.Intrem,
      ReminderType: Reminder_Type,

      DeviceOS: 0,
      DeviceOSVersion: "string",
      ApplicationType: 0,
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    submitUpdateMap(data);
  };
  const submitUpdateMap = (formMapData) => {
    APIClient.put(`StoreVisitors/updateVisitors/${id}`, {}, formMapData)
      .then((response) => {

        if (response.status === 200) {
          ANTD.message.success("visitors Updated SuccessFully", 3);
          setIsEditMode(false);
          setModalVisible(false);
          getAllStoreVisitors();

        } else if (response.status === 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const deleteFlightComm = (id) => {
    APIClient.delete("StoreVisitors/StoreVisitors/" + id)

      .then((res) => {
        if (res.status === 200) {
          ANTD.message.success("Deleted  successfully", 3);
          getAllStoreVisitors();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitMapDetails = (formMapData) => {
    localStorage.setItem(
      "accessToken",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxLCJyb2xlIjoiQWRtaW4iLCJlbWFpbCI6ImFkbWluQGdtYWlsLmNvbSIsIm1vYmlsZSI6Ijk4NDk4NTg2OCJ9LCJpYXQiOjE2MjA3NDE3MDAsImV4cCI6MTYyMDc0NTMwMH0.s4lblCa0hBavb0u2g4S9NbOrmzzhlqAhEZfxOedFBLA"
    );
    APIClient.post("StoreVisitors/StoreVisitors", formMapData)
      .then((response) => {
        if (response.statusCode === 200) {
          ANTD.message.success("visitors Added SuccessFully", 3);


          getAllStoreVisitors();
          form.resetFields();
          setImageUr();
        } else if (response.statusCode === 400) {
          ANTD.message.error("Duplicate Entry", 3);
        } else if (response.statusCode === 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getAllStoreVisitors = () => {
    APIClient.get(`StoreVisitors/GetAllVisitors/${user.UserID}`)
      .then((res) => {
        if (res.statusCode === 200) {
          let filterdata = res.data.filter((item) => item.Status === 1);

          let data = filterdata.map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              Title: chooseTitle(item.Title),
              FirstName: item.FirstName,
              LastName: item.LastName,
              MobileNumber: item.MobileNumber,
              AgencyName: item.userDetails
                ? item.userDetails.AgencyName
                  ? item.userDetails.AgencyName
                  : ""
                : "",
              Remarks: item.Remarks,
              ReminderType: chooseReminderType(item.ReminderType),
            };
          });
          setAgentlist(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
        labelCol: { span: 24 },
        wrapperCol: { span: 23 },
      }
      : null;
  const submit = () => {
    setModalVisible(false);
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Agentlist.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setAgentlist(searchResults);
    } else {
      getAllStoreVisitors();
    }
  };
  const handleExcel = () => {
    const excelData = Agentlist.map((item) => {
      return {
        S_No: item.SNo,
        Title: item.Title,
        FirstName: item.FirstName,
        LastName: item.LastName,
        MobileNumber: item.MobileNumber,
        AgencyName: item.userDetails
          ? item.userDetails.AgencyName
            ? item.userDetails.AgencyName
            : ""
          : "",
        Remarks: item.Remarks,
        ReminderType: item.ReminderType,
      };
    });

    props.exportExcel(excelData, "StoreVisitors");
  };
  return (
    <>
      <div className="manage-markup-section ourteam-bg">
        <div className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false} className="retrieve-booking">
                  <div className="card-add-heading">
                    <h5>Store Vistors</h5>

                    <div className="add-icon d-none">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <div>
                    &nbsp;
                    <ANTD.Form
                      form={form}
                      {...formItemLayout}
                      layout={formLayout}
                      initialValues={{ layout: formLayout }}
                      onFinish={submitForm}
                      className="store-img-1"
                    >
                      <ANTD.Row className="store-vist-v3">
                        <ANTD.Col md={6} xs={24}>
                          <ANTD.Form.Item
                            label="Title"
                            name="Title"
                            rules={[
                              {
                                required: true,
                                message: "Title is required",
                              },
                            ]}
                          >
                            <ANTD.Select placeholder="Mr">
                              <option value="1">Mr</option>
                              <option value="2">Ms</option>
                              <option value="3">Mrs</option>
                            </ANTD.Select>
                          </ANTD.Form.Item>
                        </ANTD.Col>
                        <ANTD.Col md={6} xs={24}>
                          <ANTD.Form.Item
                            label="First Name"
                            name="FirstName"
                            rules={[
                              { required: true },
                              {
                                pattern: "^[a-zA-Z]*$",

                                message: "Must be Alphabet",
                              },
                            ]}
                          >
                            <Input placeholder="FirstName" />
                          </ANTD.Form.Item>
                        </ANTD.Col>

                        <ANTD.Col md={6} xs={24}>
                          <ANTD.Form.Item
                            label="Last Name"
                            name="LastName"
                            rules={[
                              { required: true },
                              {
                                pattern: "^[a-zA-Z]*$",

                                message: "Must be Alphabet",
                              },
                            ]}
                          >
                            <Input placeholder="Last Name" />
                          </ANTD.Form.Item>
                        </ANTD.Col>

                        <ANTD.Col md={6} xs={24}>
                          <ANTD.Form.Item
                            label="Email"
                            name="Email"
                            rules={[
                              {
                                type: "email",
                                required: true,
                              },
                            ]}
                          >
                            <Input placeholder="Enter Your Email" />
                          </ANTD.Form.Item>
                        </ANTD.Col>

                        <ANTD.Col md={6} xs={24}>
                          <ANTD.Form.Item
                            label="Mobile Number"
                            name="MobileNumber"
                            rules={[
                              { required: true },
                              {
                                minLength: 10,
                                maxLength: 10,
                                pattern: "^[0-9]{10}$",
                                message: "Must be 10 digits",
                              },
                            ]}
                          >
                            <Input placeholder="Enter Your Mobile Number" />
                          </ANTD.Form.Item>
                        </ANTD.Col>

                        <ANTD.Col md={6} xs={24}>
                          <ANTD.Form.Item
                            label="Interests/Remarks"
                            name="Intrem"
                            rules={[
                              {
                                required: true,
                                message: "Remarks is required",
                              },
                            ]}
                          >
                            <TextArea
                              placeholder="remarks"
                              style={{ width: "100%", height: "39px" }}
                            />
                          </ANTD.Form.Item>
                        </ANTD.Col>

                        <ANTD.Col md={8} xs={24}>
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Reminder is required",
                              },
                            ]}
                            name="ReminderType"
                            label="ReminderType"
                          >
                            <Checkbox.Group>
                              <Row>
                                <Checkbox value={1}>Mobile</Checkbox>
                                <Checkbox value={2}>Email</Checkbox>
                                <Checkbox value={3}>Chat</Checkbox>
                                <Checkbox value={4}>SocialMedia</Checkbox>
                              </Row>
                            </Checkbox.Group>
                          </Form.Item>
                        </ANTD.Col>

                        <ANTD.Col xs={24} md={4}>
                          <Form.Item
                            label="Upload Image"
                            name="UploadImage"
                          // rules={[{ required: true, message: "Image is required" }]}
                          >
                            <Upload
                              name="image"
                              listType="picture-card"
                              className="avatar-uploader"
                              showUploadList={false}
                              onPreview={handlePreview}
                              action={uploadActionUrl}
                              data={{ category: "promo" }}
                              onChange={handleChang}
                              beforeUpload={beforeUpload}
                            >
                              {imageUr ? (
                                <img
                                  src={imageUr}
                                  alt="avatar"
                                  style={{ width: "100%" }}
                                />
                              ) : (
                                uploadButton
                              )}
                            </Upload>
                          </Form.Item>
                        </ANTD.Col>
                      </ANTD.Row>
                      <ANTD.Row className="reports-btns">
                        {/* <div > */}

                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={submit}
                          // className="btob-profile-btn"
                          >
                            Submit
                          </Button>
                        </Form.Item>


                      </ANTD.Row>
                    </ANTD.Form>
                  </div>

                  <div className="action-image">
                    &nbsp;&nbsp;
                    <img src={excel} alt="excel" onClick={handleExcel} />
                    <img
                      src={search}
                      alt="search"
                      onClick={(e) => searchData()}
                    />
                    {showSearchBox && (
                      <ANTD.Input
                        placeholder="Search"
                        onChange={(e) => searchTableData(e)}
                        suffix={closeSearchInput}
                        style={{ padding: "0px 12px", width: "200px" }}
                      />
                    )}
                  </div>

                  <ANTD.Table
                    className="table-scroll-none store-visit-tr"
                    style={{ color: "red" }}
                    columns={columnsmanagebalstore}
                    // dataSource={datamanagebalstore}
                    dataSource={Agentlist}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </div>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Store Vistors</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header store-modal-tr"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        on
        footer={[
          <div>
            <ANTD.Button key="add" type="primary" onClick={form2.submit}>
              Update
            </ANTD.Button>
            <ANTD.Button key="submit" type="danger" onClick={(e) => clear()}>
              Cancel
            </ANTD.Button>
          </div>,
        ]}
      >
        <ANTD.Row>
          <ANTD.Col span={24}>
            <div className="card-bt-gap">
              <div className="card-add-heading">
                {/* onClick={handleShow} */}
                <div className="add-icon d-none">
                  <i className="fa fa-plus"></i>
                </div>
              </div>
              <div>
                &nbsp;
                <ANTD.Form
                  form={form2}
                  {...formItemLayout}
                  layout={formLayout}
                  onFinish={submitF}
                  initialValues={{ layout: formLayout }}
                >
                  <ANTD.Row>
                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item label="Title" name="Title">
                        <ANTD.Select placeholder="Mr">
                          <option value="1">Mr</option>
                          <option value="2">Ms</option>
                          <option value="3">Mrs</option>
                        </ANTD.Select>
                      </ANTD.Form.Item>
                    </ANTD.Col>
                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item
                        label="First Name"
                        name="FirstName"
                        rules={[
                          { required: true },
                          {
                            pattern: "^[a-zA-Z]*$",

                            message: "Must be Alphabet",
                          },
                        ]}
                      >
                        <Input placeholder="Name" />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item
                        label="Last Name"
                        name="LastName"
                        rules={[
                          { required: true },
                          {
                            pattern: "^[a-zA-Z]*$",

                            message: "Must be Alphabet",
                          },
                        ]}
                      >
                        <Input placeholder="Last Name" />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item
                        label="Eamil"
                        name="Email"
                        rules={[
                          {
                            type: "email",
                            required: true,
                          },
                        ]}
                      >
                        <Input placeholder="Enter Your Email" />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item
                        label="Mobile Number"
                        name="MobileNumber"
                        rules={[
                          { required: true },
                          {
                            minLength: 10,
                            maxLength: 10,
                            pattern: "^[0-9]{10}$",
                            message: "Must be 10 digits",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Your Mobile Number" />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item
                        label="Interests/Remarks"
                        name="Intrem"
                        rules={[
                          {
                            required: true,
                            message: "Remarks is required",
                          },
                        ]}
                      >
                        <Input placeholder="Enter Remarks" />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={8} xs={24}>
                      <Form.Item
                        name="ReminderType"
                        label="Reminder"
                        rules={[
                          {
                            required: true,
                            message: "ReminderType is required",
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          <Row>
                            <Col>
                              <Checkbox value={1}>Mobile</Checkbox>
                            </Col>
                            <Col>
                              {" "}
                              <Checkbox value={2}>Email</Checkbox>
                            </Col>
                            <Col>
                              {" "}
                              <Checkbox value={3}>Chat</Checkbox>
                            </Col>
                            <Col>
                              {" "}
                              <Checkbox value={4}>SocialMedia</Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </ANTD.Col>

                    <ANTD.Col xs={24} md={4}>
                      <Form.Item
                        label="Upload Image"
                        name="image"
                      // rules={[{ required: true, message: "Image is required" }]}
                      >
                        <Upload
                          name="image"
                          listType="picture-card"
                          className="avatar-uploader "
                          showUploadList={false}
                          onPreview={handlePreview}
                          action={uploadActionUrl}
                          data={{ category: "promo" }}
                          onChange={handleChange}
                          beforeUpload={beforeUpload}
                        >
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </ANTD.Form>
              </div>
            </div>
          </ANTD.Col>
        </ANTD.Row>
      </Modal>
    </>
  );
};

export default UpdatedComponent(Storevistors);
