export const getFareType = (num) => {
  

  switch (num) {
    case 1:
      return "General";
    case 2:
      return "Publish";
    // case 3:
    //   return "Instantpur";
    case 3:
      return "SME";
    case 4:
      return "SME.CrpCon";
      case 5:
        return "Super6E";
        case 6:
      return "Saver";
      case 7:
      return "Flexi";
      case 8:
      return "Cluster/TBF";
      case 9:
      return "Coupon";
    default:
      return "";
      
  }
};

export const getCabinType = (num) => {
  switch (num) {
    case 1:
      return "Economy Premium";
    case 2:
      return "Business";
    case 3:
      return "First Class";
    case 4:
      return "Economy";
    default:
      return "";
  }
};

export const getRoleType = (num) => {
  if (num === 2) {
    return "Users";
  } else if (num === 4) {
    return "Guest";
  } else if (num === 5) {
    return "Agent";
  }
};

export const getTypeSign = (num) => {
  if (num === 1) {
    return "%";
  }
  return "";
};


