import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import moment from "moment";

import excel from "../../assets/vector-icons/excel.png";

import { useAuthContext } from "../../common/providers/AuthProvider";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";

import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
const { Option } = Select;

const Margins = (props) => {
  const { user } = useAuthContext();
  const [reportRole, setReportRole] = useState(0);
  const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm();
  let dateFormat = "DD-MM-YYYY";
  const [showTable, setShowTable] = useState(false);

  const [dataSource, setDataSource] = useState({
    totalCanncellationCharges: 0,
    totalCollectedFare: 0,
    totalCommission: 0,
    totalConvenienceFee: 0,
    totalMarkup: 0,
    totalRefundAmount: 0,
    cancellationResult: [],
    marginDetails: [],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProviders();
  }, []);

  const getProviders = () => {
    ApiClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          let agents = res.data.filter((item) => item.Role == 5);
          setAgentList(agents);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleExcel = (type) => {
    if (type === "booking") {
      if (dataSource?.marginDetails?.length > 0) {
        let copyData = dataSource?.marginDetails;
        const excelData = copyData.map((item) => {
          return {
            Name: item.Name,
            ReferenceNumber: item.ReferenceNumber,
            ServiceType: getServiceType(item.ServiceType),
            CollectedFare: item.CollectedFare,
            Commission: item.Commission,
            Markup: item.Markup,
            Conveniencefee: item.Conveniencefee,
          };
        });
        props.exportExcel(excelData, "BookingSales");
      }
    } else {
      if (dataSource?.cancellationResult?.length > 0) {
        let copyData = dataSource?.cancellationResult;
        const excelData = copyData.map((item) => {
          return {
            Name: item.Name,
            ReferenceNumber: item.ReferenceNumber,
            ServiceType: getServiceType(item.ServiceType),
            RefundAmount: item.RefundAmount,
            RefundType: item.RefundType,
            CancellationCharges: item.CancellationCharges,
          };
        });
        props.exportExcel(excelData, "CancellationSales");
      }
    }
  };

  const getServiceType = (key) => {
    switch (key) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      case 0:
        return "All";
      default:
        return;
    }
  };
  const cancelColumns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
      sorter: (a, b) => Number(a.SNo) - Number(b.SNo),
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Service Type",
      dataIndex: "ServiceType",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
      render: (ServiceType) => getServiceType(ServiceType),
    },
    {
      title: "Reference Number",
      dataIndex: "ReferenceNumber",
      sorter: (a, b) => a.ReferenceNumber.localeCompare(b.ReferenceNumber),
    },
    {
      title: "Refund Amount",
      dataIndex: "RefundAmount",
      sorter: (a, b) => a.RefundAmount.localeCompare(b.RefundAmount),
    },
    {
      title: "Refund Type",
      dataIndex: "RefundType",
      sorter: (a, b) => a.RefundType.localeCompare(b.RefundType),
    },
    {
      title: "Cancellation Charges",
      dataIndex: "CancellationCharges",
      sorter: (a, b) =>
        a.CancellationCharges.localeCompare(b.CancellationCharges),
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
      // sorter: (a, b) => a.RefundType.localeCompare(b.RefundType),
      render: (CreatedDate) => moment(CreatedDate).format("DD-MM-YYYY"),
    },
    {
      title: "Created By",
      dataIndex: "CreatedBy",
      sorter: (a, b) => a.CreatedBy.localeCompare(b.CreatedBy),
    },
    {
      title: "Modified Date",
      dataIndex: "Modified Date",
      // sorter: (a, b) => a.RefundType.localeCompare(b.RefundType),
      render: (ModifiedDate) => moment(ModifiedDate).format("DD-MM-YYYY"),
    },
    {
      title: "Modified By",
      dataIndex: "ModifiedBy",
      sorter: (a, b) => a.ModifiedBy.localeCompare(b.ModifiedBy),
    },
    // {
    //   title: "TransactionId",
    //   dataIndex: "TransactionId",
    //   sorter: (a, b) => a.TransactionId.localeCompare(b.TransactionId),
    // },
  ];
  const columns = [
    {
      title: "Sr No",
      dataIndex: "SNo",
      sorter: (a, b) => Number(a.SNo) - Number(b.SNo),
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      render: (ServiceType) => getServiceType(ServiceType),
    },
    {
      title: "ReferenceNumber",
      dataIndex: "ReferenceNumber",
      sorter: (a, b) => a.ReferenceNumber.localeCompare(b.ReferenceNumber),
    },

    {
      title: "CollectedFare	",
      dataIndex: "CollectedFare",
      sorter: (a, b) => Number(a.CollectedFare) - Number(b.CollectedFare),
    },
    {
      title: "Commission (Rs.)	",

      dataIndex: "Commission",
      sorter: (a, b) => Number(a.Commission) - Number(b.Commission),
    },

    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "MarkUp",
      dataIndex: "Markup",
    },
    {
      title: "Convenience Fee",
      dataIndex: "Conveniencefee",
      sorter: (a, b) => a.Conveniencefee.localeCompare(b.Conveniencefee),
    },
  ];

  const getDiscountsData = (values) => {
    setLoading(true);
    setDataSource({
      totalCanncellationCharges: 0,
      totalCollectedFare: 0,
      totalCommission: 0,
      totalConvenienceFee: 0,
      totalMarkup: 0,
      totalRefundAmount: 0,
      cancellationResult: [],
      marginDetails: [],
    });

    ApiClient.get("margin/getmargin", values)
      .then((res) => {
        if (res.status == 200) {

          let cancellationResult = res.data.cancellationResult.map(
            (item, index) => ({
              SNo: index + 1,
              ...item,
              CreatedOn: moment(item.CreatedOn).format(dateFormat),
            })
          );

          let marginDetails = res.data.marginDetails.map((item, index) => ({
            SNo: index + 1,
            ...item,
            CreatedOn: moment(item.CreatedOn).format(dateFormat),
          }));

          setDataSource({ ...res.data, cancellationResult, marginDetails });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onFormSubmit = (values) => {
    let data = {
      fromDate: values.fromDate ? moment(values.fromDate).format() : "",
      toDate: values.toDate ? moment(values.toDate).format() : "",
      UserID: values.reportsOf
        ? values.reportsOf === 0
          ? ""
          : values.UserID
        : "",

      serviceType: values.serviceType,
    };
    getDiscountsData(data);
  };

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false}>
            <h5>
              Margins <HelpInfoHelper screenName={"/admin/margins"} />
            </h5>
            <Form
              form={form}
              layout={"vertical"}
              initialValues={{
                dateType: 5,
                reportsOf: 0,
              }}
              onFinish={onFormSubmit}
            >
              <Row gutter={16}>
                {user?.Role?.RoleId === 1 ? (
                  <>
                    <Col md={8} xs={24}>
                      <Form.Item
                        label="Reports of"
                        name="reportsOf"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Select
                          placeholder="Select"
                          onSelect={(val) => setReportRole(val)}
                        >
                          <Option value={0}>Self</Option>
                          <Option value={5}>Specific Partner</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {reportRole === 5 && (
                      <Col md={8} xs={24}>
                        <Form.Item
                          label="Partner"
                          name="UserID"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select placeholder="Select Agent">
                            {agentList.map((data, index) => (
                              <Option key={index} value={data.UserID}>
                                {data.userDetails.FirstName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}
                  </>
                ) : null}
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item
                    label="Service Type"
                    name="serviceType"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <Select placeholder="Please Select">
                      <Option value={1}>Flights</Option>
                      <Option value={3}>Holiday</Option>
                      <Option value={2}>Hotels</Option>
                      <Option value={0}>All</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="From Date"
                      name="fromDate"
                    // rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Select From date"
                        format={dateFormat}
                        disabledDate={disabledFutureDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item
                      label="To Date"
                      name="toDate"
                    // rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Select To date"
                        format={dateFormat}
                        disabledDate={disabledFutureDate}
                      />
                    </Form.Item>
                  </Col>
                </>
              </Row>
              <Row className="reports-btns">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setShowTable(true)}
                >
                  Search
                </Button>
                <Button
                  type="danger"
                  onClick={() => {
                    form.resetFields();
                    setReportRole(0);
                  }}
                >
                  Reset
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
        <div className="card-bt-gap">
          {showTable && (
            <Card bordered={false}>
              <div className="total-wrapper mb-2">
                <b>Total : </b>

                <span>
                  Collected Fare: Rs.
                  <span className="text-danger mx-2">
                    {dataSource.totalCollectedFare.toFixed(2)}
                  </span>
                  ,
                </span>
                <span>
                  Commission: Rs.
                  <span className="text-danger mx-2">

                    {dataSource.totalCommission.toFixed(2)}
                  </span>
                  ,
                </span>
                <span>
                  Markup: Rs.
                  <span className="text-danger mx-2">
                    {dataSource.totalMarkup.toFixed(2)}{" "}
                  </span>
                  ,
                </span>
                <span>
                  Refund Amount: Rs.
                  <span className="text-danger mx-2">
                    {dataSource.totalRefundAmount.toFixed(2)}
                  </span>
                  ,
                </span>
                <span>
                  Can. Charges: Rs.
                  <span className="text-danger mx-2">
                    {dataSource.totalCanncellationCharges}
                  </span>
                  ,
                </span>
                <span>
                  Convenience Fee: Rs.
                  <span className="text-danger mx-2">
                    {dataSource.totalConvenienceFee}
                  </span>
                </span>
              </div>

              <Row justify="space-between">
                <Col md={12} xs={24}>
                  <h5>Booking Sales Report:</h5>
                </Col>

                <Col md={12} xs={24}>
                  <div className="action-images">
                    <img
                      src={excel}
                      alt="excel"
                      onClick={() => handleExcel("booking")}
                    />
                  </div>
                </Col>
              </Row>

              {loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : dataSource?.marginDetails?.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={dataSource.marginDetails}
                  columns={columns}
                  summary={() => {
                    return (
                      <Table.Summary.Row
                        style={{ fontWeight: 600, fontSize: 13 }}
                      >
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell>Total :</Table.Summary.Cell>

                        <Table.Summary.Cell>
                          Rs. {dataSource.totalCollectedFare.toFixed(2)}
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          Rs. {dataSource.totalCommission.toFixed(2)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell>
                          Rs. {dataSource.totalMarkup.toFixed(2)}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          Rs. {dataSource.totalConvenienceFee.toFixed(2)}
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    );
                  }}
                  pagination={false}

                />
              ) : (
                <b> No Results Found!</b>
              )}

              <Row justify="space-between" className="mt-3">
                <Col md={12} xs={24}>
                  <h5>Cancellation Sales Report:</h5>
                </Col>

                <Col md={12} xs={24}>
                  <div className="action-images">
                    <img
                      src={excel}
                      alt="excel"
                      onClick={() => handleExcel("cancel")}
                    />
                  </div>
                </Col>
              </Row>

              {loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : dataSource?.cancellationResult?.length > 0 ? (
                <Table
                  scroll={{ x: true }}
                  bordered
                  dataSource={dataSource.cancellationResult}
                  columns={cancelColumns}
                  summary={() => {
                    return (
                      <Table.Summary.Row
                        style={{ fontWeight: 600, fontSize: 13 }}
                      >
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>
                        <Table.Summary.Cell></Table.Summary.Cell>

                        <Table.Summary.Cell>Total :</Table.Summary.Cell>

                        <Table.Summary.Cell>
                          Rs. {dataSource.totalRefundAmount}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell> </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          Rs. {dataSource.totalCanncellationCharges}
                        </Table.Summary.Cell>
                        <Table.Summary.Cell> </Table.Summary.Cell>
                      </Table.Summary.Row>
                    );
                  }}
                  pagination={false}
                // pagination={{
                //   defaultPageSize: 25,
                //   showSizeChanger: true,
                //   pageSizeOptions: ["25", "50", "100", "125"],
                // }}
                />
              ) : (
                <b> No Results Found!</b>
              )}
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdatedComponent(Margins);
