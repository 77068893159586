import React, { useState, useEffect, useContext } from "react";
import "./HolidayFilter.scss";
import { Card, Checkbox, Collapse, Slider } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

const { Panel } = Collapse;

const HolidayFilter = ({ holidaySearchResultObj, setHolidayResultsList }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  let {
    tours: { tours, filtersObj },
  } = holidaySearchResultObj;
  let count = tours?.length;

  const [resultCount, setResultCount] = useState(count);
  const [filters, setFilters] = useState({});
  const [priceRange, setPriceRange] = useState([]);

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    // scrollToListTop();
  };


  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    if (Object.keys(filtersObj).length > 0) {
      setFilters(filtersObj);
      setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
    }
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const applyFilters = () => {
    let visibleCount = 0;

    const packagesModelChecked = checkedFilters("packagesModel");
    const cityNameChecked = checkedFilters("cityName");
    const categoryChecked = checkedFilters("category");
    const subCategoryChecked = checkedFilters("subCategory");
    const nightsChecked = checkedFilters("nights");

    let filteredList = tours.map((tour) => {
      let isVisible = true;

      const startingFare = Number(tour.PerAdultCharge);
      if (
        !(
          startingFare >= filters.price.minPrice &&
          startingFare <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }

      if (
        cityNameChecked.length &&
        !cityNameChecked.map((cName) => cName.id).includes(tour.CityName)
      ) {
        isVisible = false;
      }
      if (
        categoryChecked.length &&
        !categoryChecked
          .map((cName) => cName.id)
          .includes(tour.Category.CategoryName)
      ) {
        isVisible = false;
      }
      if (
        subCategoryChecked.length &&
        !subCategoryChecked
          .map((cName) => cName.id)
          .includes(tour.SubCategory.SubCategoryName)
      ) {
        isVisible = false;
      }
      if (
        nightsChecked.length &&
        !nightsChecked.map((nights) => nights.id).includes(tour.Nights)
      ) {
        isVisible = false;
      }
      if (
        packagesModelChecked.length &&
        !packagesModelChecked
          .map((pTitle) => pTitle.id)
          .includes(tour.Packagesmodel)
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      return { ...tour, isVisible: isVisible };
    });

    filteredList = filteredList.filter((item) => item.isVisible);

    setHolidayResultsList({
      ...holidaySearchResultObj.tours,
      tours: filteredList,
    });
    setResultCount(visibleCount);
  };

  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);

  };

  useEffect(() => {
    setDynamicFilters();
  }, []);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    // scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );

  return (
    <div className="holiday-side-bar">
      <div className="holiday-filter">
        <div className="holiday-data">
          <p>
            {/* Showing {resultCount} Of {count} Holidays */}
            Showing {resultCount} Results
          </p>
        </div>
        <div className="overall-filter-container">
          <div className="overall-filter-header">
            <p className="filter-text">Filters</p>
            <p className="clear-text" onClick={setDynamicFilters}>
              Clear all
            </p>
          </div>
          <div className="overall-filter-body">
            <div className="stops-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel header="Price range" key="1" extra={extraPanel("price")}>
                  <div className="slider-label">
                    <span>
                      {activeCurrency} :{currencyValue(priceRange[0])}
                    </span>
                    <span> - </span>
                    <span className="right-pr">
                      {activeCurrency} :{currencyValue(priceRange[1])}
                    </span>
                  </div>
                  {filters.price && (
                    <Slider
                      range
                      step={10}
                      defaultValue={[
                        filters.price.minPriceRange,
                        filters.price.maxPriceRange,
                      ]}
                      value={priceRange}
                      min={filters.price.minPriceRange}
                      max={filters.price.maxPriceRange}
                      onChange={priceChangeHandler}
                      onAfterChange={priceChangeCompleteHandler}
                    />
                  )}
                </Panel>
              </Collapse>
            </div>

            <div className="stops-filter">
              <Collapse
                className="customscroll"
                defaultActiveKey={["1"]}
                expandIconPosition={"right"}
              >
                <Panel
                  header={"City Name"}
                  key="1"
                  extra={extraPanel("cityName")}
                >
                  {filters.cityName &&
                    filters.cityName.map((cName) => (
                      <p>
                        <Checkbox
                          checked={cName.isChecked}
                          onChange={(e) => onChange(e, "cityName", cName.id)}
                        >
                          {cName.label}
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>
            <div className="stops-filter">
              <Collapse defaultActiveKey={["1"]} expandIconPosition={"right"}>
                <Panel
                  header={"Category"}
                  key="1"
                  extra={extraPanel("category")}
                >
                  {filters.category &&
                    filters.category.map((cName) => (
                      <p>
                        <Checkbox
                          checked={cName.isChecked}
                          onChange={(e) => onChange(e, "category", cName.id)}
                        >
                          {cName.label}
                        </Checkbox>
                      </p>
                    ))}
                </Panel>
              </Collapse>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default HolidayFilter;
