import React, { useEffect, useState } from "react";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Layout,
  Modal,
  Upload,
  Image,
  Select,
  Spin
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ImageUpload = ({
  category,
  fileList,
  setFileList,
  formName,
  deleteBanner,
  limit,
  limitSize,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadImageButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    const checkFileSize = file.size < 1126400;
    if (!checkFileSize) {
      message.error(" Image must be smaller than 1Mb!");
    }

    return checkJpgOrPng && checkFileSize;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item
        name={formName}
        // rules={[
        //   { required: fileList.length > 0 ? false : true, message: "Required" },
        // ]}
      >
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadImageButton
            : uploadImageButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const SubCategory = ({ exportExcel }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [SubCategoryForm] = Form.useForm();
  const [subCategory, setSubCategory] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [image, setImage] = useState([]);
  const [imageRequestModal, setImageRequestModal] = useState(false);
  const [imageRequest, setImageRequest] = useState([]);
  const [category, setCategory] = useState([]);

  const[Addupdate,setAddupdate]=useState(false);

  useEffect(() => {
    getSubCategory();
    getCategory();
  }, []);

  const getCategory = () => {
    ApiClient.get("HolidayExtranet/GetCategory")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              ...item,
            };
          });
          setCategory(data);
        } else {
          setCategory([]);
        }
      })
      .catch((e) => {
        setCategory([]);
      });
  };

  const getSubCategory = () => {
    ApiClient.get("HolidayExtranet/GetSubCategory")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              DepartureDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setSubCategory(data);
          defineExcelData(data);
        } else {
          setSubCategory([]);
        }
      })
      .catch((e) => {
        setSubCategory([]);
      });
  };

  const submitForm = (values) => {
    let ImageArray = [];

    if (image.length > 0) {
      for (let i = 0; i < image.length; i++) {
        if (image[i].response.status === 200) {
          ImageArray.push(image[i].response.data.filepaths[0]);
        }
      }
    }
    let data = {
      CategoryImages: ImageArray,
      CategoryId: values.CategoryId,
      SubCategoryName: values.SubCategoryName,
      Description: values.Description,
    };

    isEdit ? updateSubCategory(data, currID) : addSubCategory(data);
  };

  const addSubCategory = (data) => {
    setAddupdate(true);
    ApiClient.post("HolidayExtranet/CreateSubCategory", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("SubCategory added successfully !");

          getSubCategory();
          setModalVisible(false);
          SubCategoryForm.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateSubCategory = (data, recId) => {
    setAddupdate(true);
    ApiClient.put("HolidayExtranet/UpdateSubCategory/" + recId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("SubCategory updated successfully !");

          getSubCategory();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteSubCategory = (id) => {
    ApiClient.delete("HolidayExtranet/DeleteSubCategory/" + id)
      .then((response) => {
        if (response.status == 200) {
          message.success("SubCategory Deleted successfully !");
          getSubCategory();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    setAddupdate(false);
    setIsEdit(false);
    SubCategoryForm.resetFields();
    setImage([]);

    setModalVisible({
      visible: true,
    });
  };

  const onUpdate = (currentData) => {
    setAddupdate(false);
    setImage([]);
    SubCategoryForm.resetFields();
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.SubCategoryId);
    SubCategoryForm.setFieldsValue({
      CategoryId: currentData.CategoryId,
      Description: currentData.Description,
      SubCategoryName: currentData.SubCategoryName,
    });
    let array = [];
    if (currentData.CategoryImages.length > 0) {
      for (let i = 0; i < currentData.CategoryImages.length; i++) {
        array.push({
          uid: currentData.SubCategoryId,
          status: "done",
          url: `${BASE}${currentData.CategoryImages[i].substring(1)}`,
          response: {
            status: 200,
            data: {
              filepaths: [currentData.CategoryImages[i]],
            },
          },
        });
      }
      setImage(array);
    }
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        SrNo: item.SNo,

        CategoryName: item.CategoryName,
        Description: item.Description,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => onUpdate(currentItem)}
        >
          <EditOutlined
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteSubCategory(currentItem.SubCategoryId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const DeleteImage = (id) => {
   };

  const columns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "Category Images",
      dataIndex: "CategoryImages",
      render: (CategoryImages) => {
        return CategoryImages?.length > 0 ? ImageRequest(CategoryImages) : null;
      },
    },
    {
      title: "Sub-Category Name",
      dataIndex: "SubCategoryName",
    },
    {
      title: "Category Name",
      dataIndex: "CategoryName",
    },
    {
      title: "Description",
      dataIndex: "Description",
      render: (text) => {
        return (
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            style={{ whiteSpace: "break-spaces", lineHeight: "14px" }}
          ></div>
        );
      },
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const ImageRequest = (CategoryImages) => {
    return (
      <div className="text-center">
        <span
          style={{ cursor: "pointer", color: "green" }}
          onClick={(e) => {
            setImageRequest(CategoryImages);
            setImageRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = subCategory.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setSubCategory(searchResults);
    } else {
      getSubCategory();
      setSubCategory(subCategory);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View SubCategory{" "}
                          <HelpInfoHelper
                            screenName={"/admin/toursextranet-category"}
                          />
                        </h5>
                        {subCategory.length > 0 && (
                          <p>{subCategory.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          exportExcel(excelList, "TourExtranetCategory")
                        }
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getSubCategory()}
                      />
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>
                  {subCategory.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={subCategory}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEdit ? "Update SubCategory" : "Add SubCategory"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>{Addupdate?(<Spin style={{marginRight:"5px"}} />):null}
            <Button
              htmlType="submit"
              type="primary"
              onClick={SubCategoryForm.submit}
              disabled={Addupdate}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button type="danger" onClick={() => SubCategoryForm.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={SubCategoryForm} onFinish={submitForm}>
          <Row gutter={(12, 12)} className="mb-2">
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Category Name"
                name="CategoryId"
                rules={[{ required: true }]}
              >
                <Select>
                  {category.length > 0
                    ? category.map((item, key) => (
                        <Option key={key + "category"} value={item.CategoryId}>
                          {item.CategoryName}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="SubCategory Name"
                name="SubCategoryName"
                rules={[{ required: true }]}
              >
                <Input placeholder="SubCategory Name" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Description"
                name="Description"
                rules={[{ required: true }]}
              >
                <TextArea rows={4} placeholder="SubCategory Description" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <p>Images</p>

              <div className="upload-logo">
                <ImageUpload
                  category="CategoryImages"
                  fileList={image}
                  setFileList={setImage}
                  formName="CategoryImages"
                  limit={true}
                  limitSize={3}
                  deleteBanner={DeleteImage}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={imageRequestModal}
        title="Photos"
        onCancel={(e) => {
          setImageRequestModal(false);
        }}
        footer={false}
        className="modal-header-bg12"
      >
        <Row gutter={12}>
          {imageRequest.map((image, index) => (
            <Col md={6} sm={8} xs={12} key={index}>
              <Image src={BASE + image.substring(1)} />
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(SubCategory);
