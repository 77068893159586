import React, { useContext, useState, useEffect, useReducer } from "react";
import { Card, Col, Form, Row, Input, message } from "antd";
import { useHistory } from "react-router-dom";

import GlobalStatesContext from "../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import ApiClient from "../../helpers/ApiClient";
import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";
import FlightDetailsCard from "../FlightsCheckout/Flight-Details-Card";
import FareSummaryCard from "../FlightsCheckout/FareSummaryCard";
import BookPayCard from "../FlightsCheckout/Book-Pay-Card";
import FlightGrandTotalCard from "../FlightsCheckout/FlightGrandTotalCard";
import { getFlightGrandTotalPrice } from "../FlightsCheckout/flightPriceHelper";
import "./Flights-review.scss";

import moment from "moment";
import RazorpayCheckout from "../../helpers/RazorpayCheckout";
import PaymentComponent from "../../helpers/CCavanuePayCheckout";
const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";

const dateFormat = "DD-MM-YYYY";
const oriDateFormat = "YYYY-MM-DD";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };

function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}

const Review = () => {
  const {
    state: {
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount },
    },
  } = useContext(GlobalStatesContext);
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();

  const {
    updateAirBookRespState,
    state: {
      airBookStateObj,
      flightAirPriceResp,
      flightSearchObj,
      selectedFlight,
    },
  } = useFlightContext();
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);

  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false)

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const history = useHistory();
  const [pgData, setPgData] = useState({});
  const [openRazorpayCheckout, setOpenrazorpayCheckout] = useState(false)
  const [loadingSpin, setLoadingSpin] = useState(false);
  useEffect(() => {
    if (Object.keys(airBookStateObj).length <= 0) {
      history.push("/not-found");
    }
  }, [airBookStateObj]);

  const userReg = (name, email, mobile) => {
    let regObj = {
      Name: name,
      Email: email,
      DialingCode: "+91",
      Mobile: mobile,
      Password: "",
      Role: 4,
      DeviceToken: "string",
      DeviceType: "Web",
      FirBaseToken: "string",
    };

    ApiClient.post("admin/user/register", regObj)
      .then(() => { })
      .catch();
  };

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }

  const fetchAirBook = (pgType = null) => {
    if (!user) {
      userReg(
        airBookStateObj.passengers[0].firstName,
        airBookStateObj.passengers[0].email,
        airBookStateObj.passengers[0].mobile
      );
    }

    const allAmount = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj,
      selectedInsuranceData,
      redeemAmount
    );

    airBookStateObj.promoCode = promoData.Code ?? "";
    airBookStateObj.convienenceId = ConvFee.id ?? 0;
    airBookStateObj.pgType = pgType ? pgType : pgDetails.pgType;
    airBookStateObj.totalPrice = Number(allAmount.grandTotal);
    airBookStateObj.isCouponReedem = redeemAmount.status ?? false;
    setLoadingSpin(true);
    ApiClient.post("flights/airBlock", airBookStateObj)
      .then((resp) => {
        return resp;
      })
      .then((resp) => {
        setLoadingSpin(false);
        if (resp.statusCode === 200) {
          updateAirBookRespState(resp);


          ccavanueCheckoutData(resp.data)

        } else {
          console.log("airbook feching failed");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingSpin(false);
      });
  };
  const RazorPayCheckoutData = (resp) => {
    setPgData(resp)
    setOpenrazorpayCheckout(true)
  }

  const processPayGateway = () => {
    dispatchPgDetails({ type: PGDISPLAY, payload: true });
  };

  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
      fetchAirBook(pgType);

    }
  };



  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: airBookStateObj.passengers[0].mobile,
      DialingCode: airBookStateObj.passengers[0].areaCode,
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          fetchAirBook();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: airBookStateObj.passengers[0].mobile,
      DialingCode: airBookStateObj.passengers[0].areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };
  const ValidateBookingLimit = () => {
    const { grandTotal } = getFlightGrandTotalPrice(
      flightAirPriceResp,
      ConvFee,
      promoData,
      flightSearchObj,
      selectedInsuranceData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 1,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: grandTotal ? grandTotal : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <section className="checkout-body-sec">
      <div className="checkout-container flights-poins-sec review-container">
        <Row gutter={[16, 16]} className="flight-review-row">
          <Col md={16} xs={24}>
            <FlightDetailsCard
              selectedFlight={selectedFlight}
              flightSearchObj={flightSearchObj}
            />
            <div className="user-details">
              <p className="heading">Passengers</p>
              <Card className="flight-cards-details">
                {!airBookStateObj.passengers
                  ? null
                  : airBookStateObj.passengers.length > 0 && (
                    <Form
                      layout="vertical"
                      className="passenger-form user-details "
                    >
                      {airBookStateObj.passengers.map((paxData) => (
                        <Row gutter={[16, 8]}>
                          <div className="pax-heading">
                            {paxData.paxType === "ADT"
                              ? "Adult"
                              : paxData.paxType === "CHD"
                                ? "Child"
                                : "Infant"}
                          </div>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item label="Title">
                              <Input value={paxData.title} readOnly />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item label="First Name">
                              <Input value={paxData.firstName} readOnly />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item label="Last Name">
                              <Input value={paxData.lastName} readOnly />
                            </Form.Item>
                          </Col>

                          {paxData.hasOwnProperty("dob") && (
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item label="Date of birth">
                                <Input
                                  value={moment(
                                    paxData.dob,
                                    oriDateFormat
                                  ).format(dateFormat)}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {paxData.hasOwnProperty("passengerNationality") && (
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item label="Passenger Nationality">
                                <Input
                                  value={paxData.passengerNationality}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {paxData.hasOwnProperty("passportNumber") && (
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item label="Passport Number">
                                <Input
                                  value={paxData.passportNumber}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {paxData.hasOwnProperty("passportDOI") && (
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="Passport DOI"
                                className="passport-dates"
                              >
                                <Input
                                  value={moment(
                                    paxData.passportDOI,
                                    oriDateFormat
                                  ).format(dateFormat)}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {paxData.hasOwnProperty("passportDOE") && (
                            <Col md={8} sm={8} xs={24}>
                              <Form.Item
                                label="Passport DOE"
                                className="passport-dates"
                              >
                                <Input
                                  value={moment(
                                    paxData.passportDOE,
                                    oriDateFormat
                                  ).format(dateFormat)}
                                  readOnly
                                />
                              </Form.Item>
                            </Col>
                          )}
                          {paxData.hasOwnProperty(
                            "passportIssuedCountry"
                          ) && (
                              <Col md={8} sm={8} xs={24}>
                                <Form.Item label="Passport Issue Country">
                                  <Input
                                    value={paxData.passportIssuedCountry}
                                    readOnly
                                  />
                                </Form.Item>
                              </Col>
                            )}
                        </Row>
                      ))}
                    </Form>
                  )}
              </Card>
            </div>

            {!airBookStateObj.gstDetails
              ? null
              : airBookStateObj.gstDetails.gstNumber && (
                <div className="user-details">
                  <p className="heading">GST Details</p>
                  <Card className="checkout-custom-card">
                    <Form
                      layout="vertical"
                      initialValues={{ ...airBookStateObj.gstDetails }}
                    >
                      <Row gutter={[16, 8]}>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Company Name"
                            name="gstCompanyName"
                          >
                            <Input readOnly />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item label="GST Number" name="gstNumber">
                            <Input readOnly />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item label="Contact Number" name="gstPhoneNo">
                            <Input readOnly />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item label="Company Email" name="gstEmailId">
                            <Input readOnly />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Company Address"
                            name="gstAddressLine1"
                          >
                            <Input readOnly />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>
                  </Card>
                </div>
              )}
          </Col>
          <Col xs={24} md={8}>
            {Object.keys(flightAirPriceResp).length > 0 && (
              <div className="checkout-sticky-part">
                {flightAirPriceResp.flightDetails.map((flightDetObj, i) => (
                  <FareSummaryCard
                    flightDetObj={flightDetObj}
                    flightSearchObj={flightSearchObj}
                    currency={flightDetObj.fareFamilies[0].currency}
                    title={i === 0 ? "Onward Trip Fare" : "Return Trip Fare"}
                  />
                ))}

                {flightAirPriceResp?.flightDetails?.length > 0 ? (
                  <div className="grand-total-card">
                    <FlightGrandTotalCard
                      airPriceResp={flightAirPriceResp}
                      PromoNotVisible={true}
                      flightSearchObj={flightSearchObj}
                    />
                  </div>
                ) : null}

                <BookPayCard
                  isLoading={false}
                  bookpaycardinfo={"flight-review"}
                  pgDisplay={pgDetails.pgDisplay}
                  pgData={pgDetails.pgData}
                  processPayGateway={processPayGateway}
                  blockApiReq={blockApiReq}
                  loadingSpin={loadingSpin}
                />


                {
                  openCCavanueCheckout && (
                    <PaymentComponent
                      pgData={pgData}

                    />
                  )
                }


              </div>
            )}
          </Col>
        </Row>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={airBookStateObj.passengers[0].mobile}
        />
      )}
    </section>
  );
};

export default Review;
