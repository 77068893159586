import {
    DeleteOutlined,
    EditOutlined,
    PlusCircleFilled,
} from "@ant-design/icons";

import {
    Button,
    Card,
    Col,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Select,
    Switch,
    Table,
    DatePicker,
} from "antd";
import "rc-datepicker/lib/style.css";
import React, { useEffect, useState } from "react";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import search from "../assets/vector-icons/search.png";
//   import "./ServiceType.scss";

const CurrencyConersion = () => {
    const { Content } = Layout;
    const [modalVisible, setModalVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(true);
    const [id, setId] = useState(-1);
    const [form] = Form.useForm();

    const showModal = () => {
        setModalVisible({
            visible: true,
        });
    };

    const handleAddClick = () => {
        form.resetFields();
        showModal();
        setIsEditMode(false);
    };
    const handleEditClick = (rec) => {
        form.setFieldsValue({
            ...rec,
        });

        setIsEditMode(true);

        showModal();
    };
    let dateFormat = "YYYY-MM-DD";
    const [dataSource, setDataSource] = useState([]);
    const getAllServiceTypes = () => {
        setDataSource([]);
        ApiClient.get("admin/currencyConversions").then((res) => {
            if (res.status == 200) {
                setDataSource(res.data);
            }
        });
    };
    useEffect(() => {
        getAllServiceTypes();
    }, []);

    const SubmitServiceType = (formvalues) => {
        ApiClient.post("admin/currencyConversion", formvalues).then((response) => {
            if (response.status === 200) {
                setModalVisible(false);
                message.success("ServiceType created successfully");
                getAllServiceTypes();
                form.resetFields();
            } else {
                message.error(" Error Creating Currency Conversion");
            }
        });
    };

    const handleSubmit = (formvalues) => {
      
        if (isEditMode) {
            console.log("formvalues", formvalues)
            UpdateServiceType(formvalues);
        } else {
            SubmitServiceType(formvalues);
        }
    };

    const UpdateServiceType = (formvalues) => {
        const updatedFormData = {
            ...formvalues,
        };

        ApiClient.put("admin/currencyConversion/" + id, {}, updatedFormData).then(
            (response) => {
                if (response.status === 200) {
                    setModalVisible(false);
                    message.success("ServiceType updated successfully");

                    getAllServiceTypes();
                    form.resetFields();
                } else {
                    message.error(" Error Updating Currency Coversion ");
                }
            }
        );
    };
     const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const deleteCarType = (id) => {
        ApiClient.delete("admin/currencyConversion/" + id).then((res) => {
            if (res.status == 200) {
                message.success("ServiceType Deleted  successfully", 3);
                getAllServiceTypes();
            }
        });
    };

    const tableActions = (rec) => {
         return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <EditOutlined
                        onClick={() => {
                            setId(rec.CurrencyConversionID);
                            handleEditClick(rec);
                        }}
                        style={{ color: "#bd0c21", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleteCarType(rec.CurrencyConversionID)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };
    const { Option } = Select;
     const columns = [

        {
            title: "CreatedDate",
            dataIndex: "CreatedDate",
            render: (date) => (date ? moment(date).format("DD-MM-YYYY") : null),
        },
        {
            title: "CurrencyDate",
            dataIndex: "CurrencyDate",
            render: (date) => (date ? moment(date).format("DD-MM-YYYY") : null),
        },
        
        {
            title: "Hours",
            dataIndex: "Hours",
        },
        {
            title: "India",
            dataIndex: "INR",
        },
        {
            title: "Ruble",
            dataIndex: "RUB",
        },

        {
            title: "UAE Dirham",
            dataIndex: "AED",
        },
        {
            title: "USA",
            dataIndex: "USD",
        },
        {
            title: "United Kingdom",
            dataIndex: "GBP",
        },
        {
            title: "Australia",
            dataIndex: "AUD",
        },
        {
            title: "Canada",
            dataIndex: "CAD",
        },
        {
            title: "UAH",
            dataIndex: "UAH",
        },
        {
            title: "China",
            dataIndex: "CNY",
        },
        {
            title: "Mexico",
            dataIndex: "MXN",
        },
        // {
        //     title: "Actions",
        //     render: (rec) => {
        //         return tableActions(rec);
        //     },
        // },
    ];

    const handelStatus = (data) => {
        if (data.Status == 1) {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    defaultChecked
                    onChange={() => changeStatus(data, 0)}
                />
            );
        } else {
            return (
                <Switch
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    onChange={() => changeStatus(data, 1)}
                />
            );
        }
    };

    const changeStatus = (info) => {
        // let obj = {
        //   Id: info.CarTypeID,
        //   status: Status,
        // };
        ApiClient.put("admin/currencyConversion/" + info.CarTypeID)
            .then((response) => {
                if (response.status == 200) {
                    message.success("Car Type Status updated successfully !");

                    getAllServiceTypes();
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <div className="promo">
            <div className="manage-markup-section">
                <Content className="admin-container">
                    <Row>
                        <Col span={24}>
                            <div className="card-bt-gap">
                                <Card bordered={false}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <div>
                                                <h5> Currency Conversion </h5>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="action-images">
                                            <img src={search} alt="search" />

                                            <p className="add-deposit-icon" onClick={handleAddClick}>
                                                <PlusCircleFilled />
                                            </p>
                                        </div>
                                    </div>

                                    <div>
                                        <Table
                                            className="table-scroll-none"
                                            bordered
                                            dataSource={dataSource}
                                            columns={columns}
                                            pagination={{
                                                defaultPageSize: 25,
                                                showSizeChanger: true,
                                                pageSizeOptions: ["25", "50", "100", "125"],
                                            }}
                                        />
                                    </div>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>

            <Modal
                title="Service Type"
                className="promo-modal-header cartypes-value modal-header-bg12"
                visible={modalVisible}
                onOk={(e) => setModalVisible(false)}
                onCancel={(e) => setModalVisible(false)}
                footer={null}
                width={"1100px"}
            >
                <Form
                    layout="vertical"
                    name="basic"
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Row gutter={16}>
                    <Col md={24} xs={24}>
                            <Form.Item
                                label="CreatedDate"
                                rules={[{ required: true }]}
                                name="CreatedDate"
                            >
                                {/* <DatePicker format={dateFormat} style={{ width: "100%" }} /> */}
                                <Input type="date" placeholder="Please Enter Service Name" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="CurrencyDate"
                                rules={[{ required: true }]}
                                name="CurrencyDate"
                            >
                                {/* <DatePicker format={dateFormat} style={{ width: "100%" }} /> */}
                                <Input type="date" placeholder="Please Enter Service Name" />
                            </Form.Item>
                        </Col>
                        
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Hours"
                                rules={[{ required: true }]}
                                name="Hours"
                            >
                                <Input placeholder="Please Enter Service Name" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="India"
                                rules={[{ required: true }]}
                                name="INR"
                            >
                                <Input placeholder="Please Enter Service Name" />
                            </Form.Item>
                        </Col>

                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Ruble"
                                rules={[
                                    { required: true },

                                ]}
                                name="RUB"
                            >
                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="UAE Dirham"
                                rules={[
                                    { required: true },

                                ]}
                                name="AED"
                            >
                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="USA"
                                rules={[
                                    { required: true },

                                ]}
                                name="USD"
                            >
                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>

                        <Col md={24} xs={24}>
                            <Form.Item
                                label="United Kingdom"
                                rules={[
                                    { required: true },

                                ]}
                                name="GBP"
                            >
                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Australia"
                                rules={[
                                    { required: true },

                                ]}
                                name="AUD"
                            >
                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Canada"
                                rules={[
                                    { required: true },

                                ]}
                                name="CAD"
                            >
                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="UAH"
                                rules={[
                                    { required: true },

                                ]}
                                name="UAH"
                            >

                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>

                        <Col md={24} xs={24}>
                            <Form.Item
                                label="China"
                                rules={[
                                    { required: true },

                                ]}
                                name="CNY"
                            >

                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Mexico"
                                rules={[
                                    { required: true },

                                ]}
                                name="MXN"
                            >

                                <Input placeholder="Please Enter Service URL" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                        <Button type="primary" htmlType="submit">
                            {isEditMode ? "Update" : "Add"}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                form.resetFields();
                            }}
                            danger
                            className="cancel-btn"
                        >
                            Clear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default CurrencyConersion;
