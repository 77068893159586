import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, Rate, Skeleton, Badge, Carousel } from "antd";
import { useCurrencyContext } from "../../../../common/providers/CurrencyProvider";
import ImagesLightbox from "../../../../components/ImagesLightbox/ImagesLightbox";
import queryString from "query-string";
import { EnvironmentOutlined, ClockCircleOutlined } from "@ant-design/icons";
import hotelNoImg from "../../../../assets/images/hotels/no_photo.png";
import "./HolidayList.scss";
import NoImage from "../../../../assets/images/image-not-found-scaled-1150x647.png"

import ApiClient from "../../../../helpers/ApiClient";
import HolidayLightbox from "../../../../components/ImagesLightbox/HolidayLightbox";

const HolidayList = ({ key, toursData, showNetFare, travelDate, traceId }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [imagesModal, setImagesModal] = useState({
    visible: false,
    data: null,
  });

  const goToHolidayDetails = (tourObj) => {
    let queryObj = {
      traceId: traceId,
      tourId: tourObj.holidayId,

      isFrm: "list",
    };

    const query = queryString.stringify(queryObj);

    return "/holiday/tourdetail?" + query;
  };

  const onHandleModal = (hotelObj) => {
    setImagesModal((prev) => ({ ...prev, data: hotelObj, visible: true }));
  };

  function handleImagesModalClose() {
    setImagesModal((prev) => ({ ...prev, data: null, visible: false }));
  }

  return (
    <div key={key} className="activitys_search_list">
      <Badge.Ribbon
        text="Recommended"
        color="#174998"
        style={{ display: toursData?.IsRecommended === 1 ? 'block' : "none" }}
      >
        <div className="activity-card">
          <div className="results-wrapper">
            <div className="package-title">{toursData?.PackageTitle}</div>
            <div className="grid-item activity-image">

              <div className="activity_image_">

                <>
                  <Carousel autoplay className="holiday-carousel-list">
                    {toursData?.HolidayMedia?.[0]?.Images?.length > 0 ?
                      (toursData?.HolidayMedia?.[0]?.Images?.map((image, id) => {
                        return (<img height="150px"
                          className="slider-img-holidays"
                          src={image}
                          alt="slider-img"
                        />)
                      })
                      ) :
                      <img
                        className="slider-img-holidays"
                        src={NoImage}
                        alt="slider-img"
                      />}
                  </Carousel>

                </>

              </div>

            </div>
            <div className="holiday-card-info">
              <div>Holiday Type : {toursData?.Category?.CategoryName}</div>
              <div>Duration : {toursData?.Days} days/{toursData?.Nights} Nights</div>
              <div>Destination : {toursData?.CityName}</div>
              <div className="pack-desc">{toursData?.Packagesdescription}</div>

              {toursData?.IsFlightAvailable || toursData?.IsHotelsAvailable || toursData?.IsTransportAvailable ||
                toursData?.isMealsAvailable || toursData?.IsSightseeingAvailable ? <div className="incl">Inclusions: {toursData?.IsFlightAvailable ? <i class="fa fa-plane" aria-hidden="true"></i> : null}{" "}
                {toursData?.IsHotelsAvailable ? <i class="fa fa-building" aria-hidden="true"></i> : null}{" "}
                {toursData?.IsTransportAvailable ? <i class="fa fa-car" aria-hidden="true"></i> : null}{" "}
                {toursData?.isMealsAvailable ? <i class="fa fa-cutlery" aria-hidden="true"></i> : null}{" "}
                {toursData?.IsSightseeingAvailable ? <i class="fa fa-binoculars" aria-hidden="true"></i> : null}{" "}

              </div> : null}
              <div className="price-btm">
                <div className="activity-price activity-cp-price">
                  {showNetFare ? (
                    <>
                      <span className="text-line">
                        {activeCurrency}{" "}
                        <span>{currencyValue(toursData.publishAmount)}</span>
                      </span>

                      <p className="netfare">
                        {activeCurrency} {currencyValue(toursData.netAmount)}{" "}
                      </p>
                      <p className="netfare">
                        Inc: {activeCurrency}{" "}
                        {currencyValue(toursData.commission)}{" "}
                      </p>
                    </>
                  ) : (
                    <span className="price-tag">
                      {activeCurrency}{" "}
                      <span>{currencyValue(toursData.PerAdultCharge)}</span>
                    </span>
                  )}
                </div>
                <div className="choose-btn">
                  <Link to={goToHolidayDetails(toursData)}>
                    <Button
                      block
                      className="web-choose-btn activity-choose-btn"
                    >
                      View Details
                    </Button>
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Badge.Ribbon>
      <Modal
        wrapClassName="modalHeader hotelImgModal holiday-lightbox"
        visible={imagesModal.visible}
        centered
        width={800}

        onOk={handleImagesModalClose}
        onCancel={handleImagesModalClose}
        footer={null}
      >

        {imagesModal.visible ? (
          <HolidayLightbox holidayImages={imagesModal.data} withBase={true} />
        ) : null}
      </Modal>
    </div>
  );
};

export default HolidayList;
