import React from "react";
import { Card, Carousel, Col, Row } from "antd";
import NoImage from "../../../assets/images/image-not-found-scaled-1150x647.png";
import queryString from "query-string";
import { useHistory } from "react-router-dom";


const getGuestCount = (data) => {
  let guests = 0;
  if (data.price) {
    guests +=
      Number(data.price.adultCount) + Number(data.price.childCount6_11) + Number(data.price.infantCount) + Number(data.price.childCount2_5);
  }

  return guests;
};


const HolidayDetailCard = ({ holidayData }) => {
  let history = useHistory();
  const backToDeailsPage = () => {

    let queryObj = {
      traceId: holidayData?.Request?.traceId,
      isFrm: "list",
      tourId: holidayData?.Request?.holidayId,
    };

    const query = queryString.stringify(queryObj);
    history.push("/holiday/tourdetail?" + query);
  };

  return (
    <div className="holiday-info-card">
      <div className="header">
        <div className="hdr-titl">
          <h3>{holidayData?.PackageTitle}</h3>
        </div>
        <div className="back-fun">
          <p
            className="back-btn"
            onClick={() => backToDeailsPage()}
          >
            &nbsp; Go Back
          </p>
        </div>

      </div>
      <div className="tour-data">

        <Row>
          <Col md={6} >
            <div className="img-info">
              {holidayData?.HolidayMedia?.length > 0 ?
                <img style={{ height: "80px", width: "140px", margin: "0", padding: "0" }} className="thm-holiday" src={holidayData?.HolidayMedia?.[0]?.Images?.[0]} alt="" /> :
                <img style={{ height: "80px", width: "140px", margin: "0", padding: "0" }} src={NoImage} alt="" />}
            </div>
          </Col>
          <Col md={7} >
            <div style={{ paddingTop: "25px" }} className="big priceCategory">
              <strong className="fromCity">{holidayData?.price?.RoomCategoryName} - {holidayData?.CityName}</strong>
            </div>
          </Col>
          <Col md={4} >
            <div style={{ paddingTop: "25px" }} className="date-info">
              <strong>{holidayData?.TravellingDate}</strong>
            </div>
          </Col>
          <Col md={5}>
            <div style={{ paddingTop: "25px" }} className="roomsGuests">
              <strong className="guest-count-label">
                Adult:{holidayData?.price?.adultCount}{holidayData?.price?.childCount >= 1 ? `, Child:${holidayData?.price?.childCount}` : null}
              </strong>
            </div>
          </Col>
          <Col md={2}>
            <div style={{ paddingTop: "25px" }} className="room">
              <strong>Room:{holidayData?.price?.RoomCount}</strong>
            </div>
          </Col>
        </Row>

      </div>
    </div>

  );
};

export default HolidayDetailCard;
