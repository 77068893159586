import React from "react";
import { Button, Card, Checkbox, Form, Spin } from "antd";

import "./HolidaysCheckout.scss";
import { LoadingOutlined } from "@ant-design/icons";
import PayGateway from "../../../helpers/PayGateway";

const HolidayBookPayCard = (props) => {
  const [form] = Form.useForm();

  const goTo = () => {
    if (props.bookpaycardinfo === "review") {
      props.processPayGateway();
    } else if (props.bookpaycardinfo === "checkout") {
      props.redirectToPreview();
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );

  return (
    <div className="payment-conditions">
      {props.pgDisplay ? (
        <PayGateway
          blockApiReq={props.blockApiReq}
          loadingSpin={props.loadingSpin}
        />
      ) : (
        <Form form={form} onFinish={goTo}>
          <div style={{marginTop:"40px"}} className="book-pay-card">
            <Form.Item
              name="remember"
              rules={[
                {
                  validator: (rule, value) => {
                    return value
                      ? Promise.resolve()
                      : Promise.reject("Accept Terms & Conditions");
                  },
                },
              ]}
              valuePropName="checked"
            >
              <Checkbox>
                I Agree To All The{" "}
                <a
                  href="/termsofconditions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" Terms & Conditions"}{" "}
                </a>{" "}
                and{" "}
                <a
                  href="/privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Privacy Policy.
                </a>
              </Checkbox>
            </Form.Item>
            <div className="book-pay-btns mr-2 mb-1">
              <Button
                disabled={props.isLoading}
                htmlType="submit"
                className="holiday-book-btn"
              >
                {props.loadingSpin ? (
                  <Spin indicator={antIcon} />
                ) : props.bookpaycardinfo === "checkout" ? (
                  "Continue"
                ) : (
                  "Pay Now"
                )}
              </Button>
              {props.loadingSpin ? (
                <Spin indicator={antIcon} />
              ) : props.bookpaycardinfo === "review" ? (
                <Button
                  onClick={() => props.blockApiReq(2)}
                  className="holiday-book-btn ml-1"
                >
                  Book Offline
                </Button>
              ) : null}
            </div>
         </div>
        </Form>
      
      )}
   </div>
  );
};

export default HolidayBookPayCard;
