import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import ApiClient from "../../helpers/ApiClient";
import Banner from "../../components/banner/Banner";
import HomeCarousel from "../HomeCarousel/HomeCarousel";
import "./Home.scss";
import CityTopHotels from "../../pages/Hotels/TopHotels/CityTopHotels";
import { useSytContext } from "../../common/providers/SytProvider";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import AgentHomePage from "../../components/Agenthomepage/Agenthomepage";
import { useAuthContext } from "../../common/providers/AuthProvider";
import Subscribe from "../subscribe/Subscribe";
import Reviews from "../../common/Reviews/Reviews";
import FooterBanner from "./FooterBanner/FooterBanner";
import PopularHolidays from "../../pages/Holidays/HolidaysSearch/PopularHolidays";
import HomeSlider from "./HomeSlider/HomeSlider";
import TopDomesticRoutes from "./TopDomesticRoutes/topDomesticRoutes";
import OffersSlider from "../../common/LandingPageOffers/LandingPageOffers";
import CombineServLink from "../../common/CombineServicesLink/CombineServLink";
const Home = () => {
  const {
    blog,
    flightBanners,
    footerBanner,
    bigHomeSliderBanners,
    smallHomeSliderBanners,
    promoDataSource,
  } = useSytContext();

  const [dataSource, setDataSource] = useState([]);
  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();

  const [dealsList, setDealsList] = useState([]);

  const getDeals = () => {
    ApiClient.get("admin/getAllServicesTopCities")
      .then((res) => {
        if (res.status === 200) {
          setDealsList(res.data);
        }
      })
      .catch((e) => {
        setDealsList([]);
      });
  };
  // console.log("chkkkk", dealsList)

  useEffect(() => {
    getDeals();
    let data = sessionStorage.getItem("recentSearchesResults");
    getDeal()
    if (data?.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 1);

      setDataSource(data);
    }

  }, [promoDataSource]);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.trustindex.io/loader.js?f9e2cb227d8102278196d0e2475';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="home-page" >
      {(agent && user?.Role?.RoleId === 5) || user?.Role?.RoleId === 7 ? (
        <>
          <div className="work-agent-baer">
            <Banner banner={flightBanners} />
          </div>
          {(isGuest || user?.Services.includes("1")) && (
            <AgentHomePage />
          )}
        </>
      ) : (
        <section className="hero-banner">
          {/* <h2 className='upperheading-of-searchlinkbar' >The world’s best escapes, handpicked by experts</h2> */}

          <Banner banner={flightBanners} />
          <CombineServLink activetab={0}
          />
        </section>
      )}


      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Signup & save upto 30%</strog></h3>
        <div className="main-box-dis-work" >
          <div className="main-box-dis-work1" >
            <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
        </div>
        <div className="liks-about-sigup">
          <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
          {flag === true || agent === true ? (null) : (
            <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>

      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <CityTopHotels />
      </section>


      {/* <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}
      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <HomeCarousel deal={deal} />
          : null}
      </section>
      <div className="card holidays_search_container_1" style={{ border: 'none' }} >
        <PopularHolidays />
      </div>

      <section className="container">
        {footerBanner.length > 0 && (
          <div className="domestic-airsearch faqs-flight-sec">
            <FooterBanner footerBanner={footerBanner} />
          </div>
        )}
      </section>

      {/* {agent ? (null) : (
        <section>
          {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
            <OffersSlider dataSource={dataSource} serviceType="Flight" /> : null}
        </section>)}
      {(isGuest || user?.Services.includes("1")) && (
        <section>
          {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
            <HomeCarousel deal={deal} />
            : null}
        </section>
      )}
      {(isGuest || user?.Services.includes("1")) && (
        <section>
          {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
            <TopDomesticRoutes deals={dealsList} type={"Flights"} /> : null}
        </section>
      )}

      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <HomeCarousel deal={deal} />
          : null}
      </section>
      {/* <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <TopDomesticRoutes deals={dealsList} type={"Flights"} />
          : null}
      </section> */}

      {/* <section className="img-ft-img-sec">
        {bigHomeSliderBanners.length > 0 ? (
          <div className="container">
            <Row>
              <Col md={14} xs={24}>
                <Carousel effect="fade" autoplay={true}>
                  {bigHomeSliderBanners.map((banner, idx) => (
                    <div key={"bannersIDX" + idx}>
                      <img
                        className="step-img-value"
                        src={
                          process.env.REACT_APP_BASE_URL +
                          banner.path.substring(1)
                        }
                        alt=""
                      />
                    </div>
                  ))}
                </Carousel>
              </Col>
              <Col md={10} xs={24}>
                {smallHomeSliderBanners.length > 0 && (
                  <HomeSlider images={smallHomeSliderBanners} />
                )}
              </Col>
            </Row>
          </div>
        ) : null}
      </section> */}


      {/* <section className="home-best-trip" style={{ backgroundColor: '#e7e7e7', padding: '60px 0px' }} >
        <div className="container-holiday-cont">
          <div className="container-holiday-cont1">
            <h2 className="holiday-cont-heading" >Q - How do I make a flight booking on trypmaker?</h2>
            <p className="holiday-cont-inner" >A: You can book a flight on trypmaker in five easy steps: Head over to the trypmaker flight booking page, Enter your departure and arrival destinations, Select your air travel dates, Choose from our wide range of cheap flights based on your airfare preferences, Click on ‘Book Now’ and your air flight booking is done. Alternatively, you can also use the trypmaker app for your flight ticket booking. Download the trypmaker app, Put in the details i.e. date of journey, departure and arrival destinations, travel class of your choice, Select on your best comfortable option and click on 'Book Now'.</p>

          </div>
          <div className="container-holiday-cont1">
            <h2 className="holiday-cont-heading" >Q - How do I make a flight booking on trypmaker?</h2>
            <p className="holiday-cont-inner" >A: You can book a flight on trypmaker in five easy steps: Head over to the trypmaker flight booking page, Enter your departure and arrival destinations, Select your air travel dates, Choose from our wide range of cheap flights based on your airfare preferences, Click on ‘Book Now’ and your air flight booking is done. Alternatively, you can also use the trypmaker app for your flight ticket booking. Download the trypmaker app, Put in the details i.e. date of journey, departure and arrival destinations, travel class of your choice, Select on your best comfortable option and click on 'Book Now'.</p>

          </div>
          <div className="container-holiday-cont1">
            <h2 className="holiday-cont-heading" >Q - How do I make a flight booking on trypmaker?</h2>
            <p className="holiday-cont-inner" >A: You can book a flight on trypmaker in five easy steps: Head over to the trypmaker flight booking page, Enter your departure and arrival destinations, Select your air travel dates, Choose from our wide range of cheap flights based on your airfare preferences, Click on ‘Book Now’ and your air flight booking is done. Alternatively, you can also use the trypmaker app for your flight ticket booking. Download the trypmaker app, Put in the details i.e. date of journey, departure and arrival destinations, travel class of your choice, Select on your best comfortable option and click on 'Book Now'.</p>

          </div>
          <div className="container-holiday-cont1">
            <h2 className="holiday-cont-heading" >Q - How do I make a flight booking on trypmaker?</h2>
            <p className="holiday-cont-inner" >A: You can book a flight on trypmaker in five easy steps: Head over to the trypmaker flight booking page, Enter your departure and arrival destinations, Select your air travel dates, Choose from our wide range of cheap flights based on your airfare preferences, Click on ‘Book Now’ and your air flight booking is done. Alternatively, you can also use the trypmaker app for your flight ticket booking. Download the trypmaker app, Put in the details i.e. date of journey, departure and arrival destinations, travel class of your choice, Select on your best comfortable option and click on 'Book Now'.</p>

          </div>
        </div>
      </section> */}


      {/* 
      <section className="home-best-trip">
        <div className="container">
          <Row>
            <Col md={24} xs={24}>
              <h2 className="top-fl-route route-trip-3">Why trypmaker?</h2>
              <p>
                Our competitive rates and exclusive offers are what gives us a
                top notch over our competitors. We promise 'Unbeatable' services
                both in pricing and quality. Here is the one stop destination
                for your Dream Destination. trypmaker provide you the best travel
                packages at the lowest possible pricing that gives the best
                value for your each penny. We are your Travel Companion and
                works for you so that can get the best travel experience and
                live some memorable moments.
              </p>
              <p>
                We give you the pros and cons for all the different travel
                products and allow you to decide what works best for you and
                your family. We combine first-hand knowledge with exceptional
                special offers, and we take care of every detail to create a
                holiday as unique as you are. You will no more need to worry
                about coordinating flight bookings, hotel reservations, visa
                stamps or tours as all your needs are taken care of under one
                roof.
              </p>
              <p>
                trypmaker can satisfy all your travel needs. Here, You can book
                flight tickets, hotels and holiday packages at a cost-effective price.
                So, why go anywhere else? Visit us for a memorable travel experience
                in your budget.
              </p>
            </Col>
          </Row>
        </div>
      </section> */}

      <section className="top-route-bb">
        <Reviews serviceType={1} />
      </section>


      <Subscribe />
      {/* <section className="top-google_reviews">
      <script defer async src='https://cdn.trustindex.io/loader.js?c618f5427da04298cd361e82e92'></script>
        {/* <script defer async src='https://cdn.trustindex.io/loader.js?f2ad38f27bdf023de06689a794b'></script> *
      </section> */}

    </div >
  );
};

export default Home;
