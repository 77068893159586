import React from "react";
import { Route, Switch } from "react-router-dom";
import AccessPermissions from "../../admin/AccessPermissions/AccessPermissions";
import FlightsCommissions from "../../admin/AdminMarkup/FlightsCommissions";
import HotelCommissions from "../../admin/AdminMarkup/HotelsCommissions";
import AdminUser from "../../admin/AdminUser/AdminUser";
import AgentRegistration from "../../admin/AgentRegistration/AgentRegistration";
import B2Bpg from "../../admin/B2BPG/B2Bpg.jsx";
import BankDetails from "../../admin/BankDetails/BankDetails";
import Banners from "../../admin/Banners/Banners";
import Deals from "../../admin/Deals/Deals";
import BookingOffline from "../../admin/BookingOffline/BookingOffline";
import CancelRequest from "../../admin/CancelRequest/CancelRequest";
import CurrencyConversion from "../../admin/Currencyconersion/CurrencyConersion";
import CityHotel from "../../admin/CityHotel/CityHotel";
import TopCityHotel from "../../admin/CityHotel/TopCityHotel.jsx";
import CMS from "../../admin/CMS/cms";

import ContactForm from "../../admin/ContactForms/ContactForm";
import Coupons from "../../admin/Coupons/Coupons";

import Dashboard from "../../admin/Dashboard/Dashboard";
import FlightEnquires from "../../admin/FlightEnquires/FlightEnquires";
import HelpInfo from "../../admin/HelpInfo/HelpInfo";

import LedgerStatement from "../../admin/LedgerStatement/LedgerStatement";
import UserLedgerStatement from "../../admin/UserLedgerStatements/UserLedgerStatement";
import APILogs from "../../admin/Logs/APIAccessLogs";
import PGLogs from "../../admin/Logs/PGLogs";
import ManageBalance from "../../admin/ManageBalance/ManageBalance";
import Margins from "../../admin/Margins/Margins";
import Membershipinfo from "../../admin/Membershipinfo/Membershipinfo";
import OperatorsService from "../../admin/OperatorsService/OperatorsService";
import Admin from "../../admin/Permissions/Admin/Admin";
import Promo from "../../admin/Promo/Promo";
import Reports from "../../admin/Reports/Reports";
import HolidayOfflineReport from "../../admin/HolidayExtranet/HolidayOfflineReport";
import ResheduleRequest from "../../admin/ResheduleRequest/ResheduleRequest";
import ReviewRating from "../../admin/ReviewRating/ReviewRating";
import Role from "../../admin/Role/Role";
import ServiceType from "../../admin/ServiceType/ServiceType";
import SetCommission from "../../admin/SetCommission/SetCommission";
import Settings from "../../admin/Settings/Settings";
import Siteusers from "../../admin/Siteusers/Siteusers";
import StoreVistors from "../../admin/Storevistors/Storevistors";
import SubscriberReport from "../../admin/SubscriberReport/SubscriberReport";
import SuperFlightTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperFlightTicket";
import SuperHotelTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperHotelTicket";
import AddProvider from "../../admin/SupplierConfiguration/AddProvider";
import ProviderApi from "../../admin/SupplierConfiguration/ProviderApi";
import TopCitiesAndDestination from "../../admin/TopCitiesAndDestination/TopCitiesAndDestination";
import TravelCalendar from "../../admin/TravelCalendar/TravelCalendar";
import Error404 from "../../components/ErrorPages/Error404";
import NotFound from "../../components/ErrorPages/NotFound";
import Unauthorized from "../../components/ErrorPages/Unauthorized";
import AdminRoute from "../../helpers/AdminProtection";
import FlightBookingReports from "../../admin/SuperAdminBookingReports/FlightBookingReports";
import HotelBookingReports from "../../admin/SuperAdminBookingReports/HotelBookingReports";
import HolidayBookingReports from "../../admin/SuperAdminBookingReports/HolidayBookingReports";
import HotelFITReports from "../../admin/HotelOfflineBookingReports/HotelFITReports";
import NewsLetter from "../../admin/NewsLetter/NewsLetter";
import Screens from "../../admin/Screens/Screens";
import HolidayExtranetCategory from "../../admin/HolidayExtranet/Category";
import HolidayExtranetSubCategory from "../../admin/HolidayExtranet/SubCategory"
import HolidayExtranetMedia from "../../admin/HolidayExtranet/Media"
import HolidayCancellationPolicy from "../../admin/HolidayExtranet/CancelationPolicy"
import HolidayExtranetPrice from "../../admin/HolidayExtranet/Price"
import HolidayCancelRequest from "../../admin/HolidayExtranet/HolidayCancelRequest"
import HolidayRescheduleRequest from "../../admin/HolidayExtranet/HolidayRescheduleRequest"
import HolidayExtranetPackages from "../../admin/HolidayExtranet/Packages"
import HotelRoomCategory from "../../admin/HolidayExtranet/HotelRoomCategory"
import HotelImageUpload from "../../admin/HotelImageUpload/HotelImageUpload";
import OrdersTourExtranet from "../../admin/HolidayExtranet/Orders"
import AllServicesSessiontime from "../../admin/AllServicesSessionTime/AllServiceSessiontime";
import SuperHolidayTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperHolidayTicket";
const AdminNavigations = ({ accessList, accessToken }) => {
  return (
    <div className="mob-M-Top">
      <Switch>
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path={["/", "/admin/dashboard"]}
          component={Dashboard}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/role"
          component={Role}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/sessiontime"
          component={AllServicesSessiontime}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/accesspermissions"
          component={AccessPermissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/provider"
          component={AddProvider}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/user"
          component={AdminUser}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/ServiceType"
          component={ServiceType}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/hotelImageUpload"
          component={HotelImageUpload}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/CMS"
          component={CMS}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/screens"
          component={Screens}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/ledger-statement"
          component={LedgerStatement}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/Userledger-statement"
          component={UserLedgerStatement}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/operator"
          component={OperatorsService}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/b2b"
          component={B2Bpg}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/bankdetails"
          component={BankDetails}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/agentregistration"
          component={AgentRegistration}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/set-commission"
          component={SetCommission}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/cityhotel"
          component={CityHotel}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/topHotel"
          component={TopCityHotel}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/fExtranet"
          component={BookingOffline}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/coupons"
          component={Coupons}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/deals"
          component={Deals}
        />


        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightcommissions"
          component={FlightsCommissions}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/hotelcommissions"
          component={HotelCommissions}
        />


        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/managebalance"
          component={ManageBalance}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/membershipinfo"
          component={Membershipinfo}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/promo"
          component={Promo}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/storevistors"
          component={StoreVistors}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apiprovider"
          component={ProviderApi}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports"
          component={Reports}
        />



        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/subscriberreport"
          component={SubscriberReport}
        />


        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/banners"
          component={Banners}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/topCities"
          component={TopCitiesAndDestination}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/travelcalendar"
          component={TravelCalendar}
        />


        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/Siteusers"
          component={Siteusers}
        />



        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/apilogs"
          component={APILogs}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/pglogs"
          component={PGLogs}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/settings"
          component={Settings}
        />


        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/admin"
          component={Admin}
        />

        <Route
          exact
          path="/admin/flight/ticket"
          component={SuperFlightTicket}
        />
        <Route
          exact
          path="/admin/holiday/ticket"
          component={SuperHolidayTicket}
        />
        <Route exact path="/admin/hotel/ticket" component={SuperHotelTicket} />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/contactforms"
          component={ContactForm}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightenquiries"
          component={FlightEnquires}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/margins"
          component={Margins}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/HelpInfo"
          component={HelpInfo}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reviews-rating"
          component={ReviewRating}
        />

        <Route exact path="/admin/cancelrequest" component={CancelRequest} />
        <Route
          exact
          path="/admin/reshedule-request"
          component={ResheduleRequest}
        />



        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/flightReport"
          component={FlightBookingReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/hotelReport"
          component={HotelBookingReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/holidayReport"
          component={HolidayBookingReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/reports/holidayOffline"
          component={HolidayOfflineReport}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/hotelFITBooking"
          component={HotelFITReports}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/hoildayextranet-orders"
          component={OrdersTourExtranet}
        />



        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/newsletter-subscribers"
          component={NewsLetter}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-packages"
          component={HolidayExtranetPackages}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-media"
          component={HolidayExtranetMedia}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-cancelpolicy"
          component={HolidayCancellationPolicy}
        />


        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/currency-conversion/currency-conversion"
          component={CurrencyConversion}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-price"
          component={HolidayExtranetPrice}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-cancelrequest"
          component={HolidayCancelRequest}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-reschedulerequest"
          component={HolidayRescheduleRequest}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-category"
          component={HolidayExtranetCategory}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-subcategory"
          component={HolidayExtranetSubCategory}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/holiday-hotelroomcategory"
          component={HotelRoomCategory}
        />
        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/404" component={Error404} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AdminNavigations;
