import { message, Tooltip, Popover, } from "antd";
import React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./HotelPassengers.scss";
import { Collapse } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";

const HotelPassengers = ({ pax, index, updatePaxInfoFromChild, paxInfo }) => {
  const { Panel } = Collapse;
  const [activekey, setactivekey] = useState(1);
  const agesList = [
    {
      id: "< 1",
      childYear: 0,
    },
    {
      id: "1",
      childYear: 1,
    },
    {
      id: "2",
      childYear: 2,
    },
    {
      id: "3",
      childYear: 3,
    },
    {
      id: "4",
      childYear: 4,
    },
    {
      id: "5",
      childYear: 5,
    },
    {
      id: "6",
      childYear: 6,
    },
    {
      id: "7",
      childYear: 7,
    },
    {
      id: "8",
      childYear: 8,
    },
    {
      id: "9",
      childYear: 9,
    },
    {
      id: "10",
      childYear: 10,
    },
    {
      id: "11",
      childYear: 11,
    },
    {
      id: "12",
      childYear: 12,
    },
    {
      id: "13",
      childYear: 13,
    },
    {
      id: "14",
      childYear: 14,
    },
    {
      id: "15",
      childYear: 15,
    },
    {
      id: "16",
      childYear: 16,
    },
    {
      id: "17",
      childYear: 17,
    },
   
  ];

  /* on Click of remove room */

  const onRemoveRoom = (i) => {
    paxInfo.splice(i, 1);
    updatePaxInfoFromChild(paxInfo);
  };

  /* On Increase Adult count in room */
  const onIncreaseAdultCount = (paxObj) => {
    if (paxObj.noOfAdults < 4) {
      paxObj.noOfAdults = paxObj.noOfAdults + 1;
      updatePaxInfoFromChild(paxInfo);
    } else {
      message.error("Maximum 4 adults allowed", 3);
    }
  };

  /* On Decrease Adult count in room */
  const onDecreaseAdultCount = (paxObj) => {
    if (paxObj.noOfAdults > 1) {
      paxObj.noOfAdults = paxObj.noOfAdults - 1;
      updatePaxInfoFromChild(paxInfo);
    } else {
      message.error("Minimum 1 adult is required");
    }
  };

  /* on Child Increment */
  const onIncreaseChildCount = (paxObj) => {
    if (paxObj.noOfChilds < 2) {
      paxObj.noOfChilds = paxObj.noOfChilds + 1;
      paxObj.childAge.push(1);
      updatePaxInfoFromChild(paxInfo);
    } else {
      message.error("Maximum 2 childs allowed", 3);
    }
  };
  /* on Child Decrement */
  const onDecreaseChildCount = (paxObj) => {
    if (paxObj.noOfChilds > 0) {
      paxObj.noOfChilds = paxObj.noOfChilds - 1;
      paxObj.childAge.pop();
      updatePaxInfoFromChild(paxInfo);
    }
  };

  /* Grabbing child age */
  const selectChildAge = (paxIndex, e, paxObj) => {
    paxObj.childAge[paxIndex] = e.target.value;
    updatePaxInfoFromChild(paxInfo);
  };
  const act = (i) => {

    setactivekey(i)
  }
  return (
    <div key={"room" + index} className="hotel-pax-box">
      <li>
        <ul className="child-item">
          <li>
            <div className="lists-wrapper">
              <div className="pax-label">
                <p>Adults</p>
                <span>Above 18 years</span>
              </div>
              <div className="pax-count">
                <span>{pax.noOfAdults}</span>
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={(e) => onDecreaseAdultCount(pax)}
                ></i>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={() => onIncreaseAdultCount(pax)}
                ></i>
              </div>
            </div>
          </li>
          <li>
            <div className="lists-wrapper">
              <div className="pax-label">
                <p>Children</p>
                <span>Below 18 years</span>
              </div>
              <div className="pax-count">
                <span>{pax.noOfChilds}</span>
                <i
                  className="fa fa-minus"
                  aria-hidden="true"
                  onClick={() => onDecreaseChildCount(pax)}
                ></i>
                <i
                  className="fa fa-plus"
                  aria-hidden="true"
                  onClick={() => onIncreaseChildCount(pax)}
                ></i>
              </div>
            </div>

            {pax.childAge.length > 0 && (
              <p className="agetitle">{pax.noOfChilds > 1 ? "Children's" : "Children"} Age{" "}
                <Popover
                  overlayClassName="pricepopup"
                  placement="left"
                  content={
                    <>
                      <div className="pax-count-acc-body">

                        <p>To find a place to stay that fits your entire group along with correct prices, we need to know how old your children will be at check-out</p>
                      </div>

                    </>
                  }
                  title="Children Age"
                >
                  <QuestionCircleOutlined style={{ color: "#0f76bb" }} />
                </Popover>

              </p>

            )}
            <div className="ages-select">
              {pax.childAge.map((_, index) => (
                <select
                  onChange={(e) => {
                    selectChildAge(index, e, pax);
                  }}
                  key={index}
                  defaultValue={1}
                >
                  {agesList.map((ageObj) => (
                    <option key={ageObj.id} value={ageObj.childYear}>
                      {ageObj.id}
                    </option>
                  ))}
                </select>
              ))}
            </div>
          </li>
        </ul>
      </li>
    </div>
  );
};

export default HotelPassengers;
