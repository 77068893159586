import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ActiveTabs from "./ActiveTabs";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
import Hotels from "../../../components/Hotels/Hotels";
import Combinesearchhotel from "../../../components/CombinedSearch/Combinesearchhotel";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import Subscribe from "../../../components/subscribe/Subscribe";
import moment from "moment";
import Reviews from "../../../common/Reviews/Reviews";
import { AppConstants } from "../../../helpers/constants";
import "../HotelSearch/HotelSearch.scss";
import CityTopHotels from "../TopHotels/CityTopHotels";
import TopHotels from "../TopHotels/TopHotels";
import PopularHolidays from "../../Holidays/HolidaysSearch/PopularHolidays";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import CombineServLink from "../../../common/CombineServicesLink/CombineServLink";
const HotelSearch = (props) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  const { hotelBanners } = useSytContext();
  const [cityHotelData, setCityHotelData] = useState([]);
  const [cityHotel, setCityHotel] = useState([]);
  const [cityTopHotel, setCityTopHotel] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [recentSearchResults, setRecentSearchResults] = useState([]);
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();
  const { promoDataSource } = useSytContext();
  let tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD");
  let dayafter = moment().add(2, "days").format("YYYY-MM-DD");
  const getCityHotel = () => {
    ApiClient.get("admin/cityHotels")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.filter(
            (item) => item?.Status === 0 && item?.Servicetype === 2
          );

          setCityHotel(data);
        }
      })
      .catch((e) => console.log("api error", e));
  };
//   const getCityTopHotel = () => {
//     ApiClient.get("admin/dynamic/hotel")
//       .then((res) => {
//         if (res.status === 200) {
//           let data = res?.data?.map(
//             (item) => item
//           );
// // console.log(data,"ddddd");
//           setCityTopHotel(data);
//         }
//       })
//       .catch((e) => console.log("api error", e));
//   };
  // getCityTopHotel();
  // console.log(cityTopHotel,"top");
  const getCityHotelList = () => {
    ApiClient.get("admin/getAllServicesTopCities")
      .then((res) => {

        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 1 && item.ServiceType === 2
          );

          setCityHotelData(data);
        }
      })
      .catch((e) => console.log("api error", e));
  };

  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };


  useEffect(() => {
    getCityHotelList();
    getCityHotel();
    // getCityTopHotel();
    getDeal()
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 2);

      setDataSource(data);
    }
  }, [promoDataSource]);

  useEffect(() => {
    getRecentSearches();
  }, []);
  const getRecentSearches = () => {
    let data = sessionStorage.getItem("hotelRecentSearchesResults");

    if (data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  };


  const getHotelSer = (url) => {
    let formData = {
      checkInDate: tomorrowDate,
      checkOutDate: dayafter,
      hotelCityCode: url?.CityName,
      roomGuests: JSON.stringify([{
        noOfAdults: 1,
        noOfChilds: 0,
        childAge: [],
      },]),
      nationality: "IN",
      currency: "INR",
      countryCode: "IN",
      traceId: "string",
    };

    const query = queryString.stringify(formData);

    return "/hotels/listing?" + query;
  };

  return (
    <div className="hotels_search_container">
      <Helmet>
        <title>
          Book Best Luxury and Budget Hotels Online - {AppConstants.DOMAIN_NAME}
        </title>
        <meta
          name="description"
          content="Best hotel deals are available at hopmker. Book budget hotels, luxury hotels or resorts at cost effective rates."
        />
      </Helmet>

      <section className="hero-banner">
        {/* <h2 className='upperheading-of-searchlinkbarhotel'  >The world’s best escapes, handpicked by experts</h2> */}

        <Banner banner={hotelBanners} />
        <CombineServLink activetab={1} />

        {/* <ActiveTabs/> */}

      </section>



      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Signup & save upto 30%</strog></h3>
        <div className="main-box-dis-work" >
          <div className="main-box-dis-work1" >
            <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
        </div>
        <div className="liks-about-sigup">
          <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
          {flag === true || agent === true ? (null) : (
            <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>
      {/* 
      <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}



      {/* <section className="top-route-bb">
        <OffersSlider dataSource={dataSource} serviceType="Hotel" />
      </section> */}



      {/*       
      <div className="container">
        {/*--start----end--*/}
      {/* <Row className="heading-hotel">
          <Col md={24} xs={24}>
            <h3>Only Here! New, Larger Accommodation Base</h3>
            <p className="mb-0">
              Hotels, Apartments, Bungalows &amp; Homestays - Just The Way You
              Like It
            </p>
          </Col>
        </Row> */}
      {/* <h5 className="topcities_heading">Top Hotels</h5> */}
      {/* <Card style={{ boxShadow: "0px 0px 1px 1px white" }}>
          <div>{cityHotel ? cityHotel.map((item) => {
            return <div className="top-routes-card" >

              <div className="inside-toproutes-card">
                <Link
                  to={getHotelSer(item)}
                  className="d-block"
                >
                  <img
                    //className="cart-shopping-img"
                    src={
                      item.HotelImage1
                        ? `${BASE}${item.HotelImage1.substring(1)}`
                        : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                          .default
                    }
                    alt="Hotel Image"
                  />
                </Link>
              </div>

              <div className="toproutes_text">
                <p className="toproutes_cityname">{item?.CityName?.split("=")[1]?.split(',')[0]}</p>
                <p className="toprouts-hotel">{item?.HotelName}</p>
                <p>{item?.ShortDescription}</p>

              </div>

            </div>
          }) : ""}

          </div>
        </Card> */}


      {/* </div> */}
      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <TopHotels />
      </section>
      <section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <CityTopHotels />
      </section>
      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
          <HomeCarousel deal={deal} />
          : null}
      </section>
      <div className="card holidays_search_container_1" style={{ border: 'none' }} >
        <PopularHolidays />
      </div>

      {/* <section className="hotels_advantages">
        <div className="hotels-advantages-wrapper">
          <div className="hotels-adv-box left-box">
            <img
              src={
                require("../../../assets/images/hotels/save_up_to.png").default
              }
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p> SAVE UP TO 30%!</p>
              <small>
                We've made deals with over 1.3 M hotels so you can book up to
                30% cheaper
              </small>
            </div>
          </div>
          <div className="hotels-adv-box right-box">
            <img
              src={require("../../../assets/images/hotels/no_tf.png").default}
              alt="hotel icon"
            />
            <div className="hotels-adv-box-wrapper">
              <p> NO HIDDEN CHARGES</p>
              <small>
                We tell you exactly what you'll pay from the very beginning -
                transparent reservation policies mean no nasty surprises
              </small>
            </div>
          </div>
        </div>
      </section>
      <section className="queries_about_hotels">
        <div className="query-container">
          <div className="query-hotels-heading">
            <h2>Any Questions About Hotel Or Your Booking?</h2>
            <p>You will find the answers in our travel guide</p>
          </div>
          <div className="query-hotels-body">
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-1.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">What Do Hotel Stars Mean?</a>
                  <p>
                    The number of stars indicates the standard and amenities
                    that you can expect in a hotel. The more, the more luxurious
                    the conditions are. However,..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-2.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Different Types Of Hotel Rooms</a>
                  <p>
                    Hotel rooms may be divided due to the number and type of
                    beds. Single room (SGL) – a room for one guest, with a
                    single bed. Depending on the..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/hotel-box-3.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Types Of Breakfast</a>
                  <p>
                    In different hotels around the world you can find various
                    kinds of breakfast. It's worth checking the breakfast offer
                    during the booking process. We..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/Checkinproc.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">
                    Check-in Procedures For Hotels, Apartments, Guest Houses
                  </a>
                  <p>
                    All Hotels or Similar Establishments Usually Require Guests
                    To..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/Typesofbeds.jfif")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Types Of Beds</a>
                  <p>There are several basic types of hotel beds: single..</p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/Additionalservices.jfif")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">
                    Additional Services Available And Offered In Hotels
                  </a>
                  <p>
                    Many hotels offer additional services such as: wake-up
                    calls, laundry..
                  </p>
                </div>
              </div>
            </Col>
          </div>
        </div>
      </section> */}
      <section className="top-route-bb">
        <Reviews serviceType={2} />
      </section>

      <section className="discounts_container">
        <Subscribe />
      </section>
    </div>
  );
};

export default HotelSearch;
