import React, { useEffect, useState } from "react";
// import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Table,
  Layout,
  Modal,
  Radio,
  Select,
  Tooltip,
  Switch,
  Spin,
  Collapse
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { Link, useHistory } from "react-router-dom";
const { Content } = Layout;
const { Option } = Select;

const HolidayCancelPolicy = ({ exportExcel }) => {
  let history = useHistory();
  const { Panel } = Collapse;
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();
  const [policyList, setPolicyList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isCloneMode, setIsCloneMode] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  // const [excelList, setExcelList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [cValue, setcValue] = useState(0);
  const [Addupdate, setAddupdate] = useState(false);

  useEffect(() => {
    getPolicyRequest();
    getPacakageList();
  }, []);

  const getPacakageList = () => {
    ApiClient.get("HolidayExtranet/GetHolidayPackages")
      .then((res) => {
        if (res.status == 200) {
          setPackageList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getPolicyRequest = () => {
    ApiClient.get("HolidayExtranet/Holiday/cancellationpolicy")
      .then((res) => {
        if (res.status == 200) {
          setPolicyList(res.data);
          // defineExcelData(res.data);
        } else {
          setPolicyList([]);
        }
      })
      .catch((e) => {
        setPolicyList([]);
      });
  };

  const submitForm = (values) => {
    let data = {
      ...values,
    };
    if (isEdit) {
      updatePolicyRequest(data, currID);
    } else if (isCloneMode) {
      addPolicyRequest(data);
    } else {
      addPolicyRequest(data);
    }
  };

  const addPolicyRequest = (data) => {
    setAddupdate(true);
    ApiClient.post("HolidayExtranet/create/Holiday/cancellationpolicy", data)
      .then((response) => {
        if (response.status == 200) {
          Modal.success({
            title: "Cancellation Policy  Added successfully !",
            content: "Thank You For Using",
          })
          //  message.success("Cancellation Policy  added successfully !");

          getPolicyRequest();
          setModalVisible(false);
          form.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updatePolicyRequest = (data, recId) => {
    setAddupdate(true);
    ApiClient.put(
      "HolidayExtranet/update/Holiday/cancellationPolicy/" + recId,
      {},
      data
    )
      .then((response) => {
        if (response.status == 200) {
          Modal.success({
            title: "Cancellation Policy  Updated successfully !",
            content: "Thank You For Using",
          })
          // message.success("Cancellation Policy  updated successfully !");

          getPolicyRequest();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deletePolicyRequest = (id) => {
    ApiClient.delete("HolidayExtranet/delete/Holiday/cancellationPolicy/" + id)
      .then((response) => {
        if (response.status == 200) {
          Modal.success({
            title: "Cancellation Policy  Deleted successfully !",
            content: "Thank You For Using",
          })
          // message.success("Cancellation Policy  Deleted successfully !");
          getPolicyRequest();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const showModal = () => {
    setAddupdate(false);
    setIsEdit(false);
    form.resetFields();

    setModalVisible({
      visible: true,
    });
  };

  const onUpdate = (currentData) => {
    setAddupdate(false);
    setModalVisible(true);
    setCurrID(currentData.Id);
    form.setFieldsValue({
      ...currentData,
    });
  };

  // const defineExcelData = (data) => {
  //   const filteredData = data.map((item) => {
  //     const obj = {
  //       SrNo: item.SNo,
  //       // CMSType: getCmsType(item.CMSType),
  //       // CMSInfo: item.CMSInfo,
  //       // PackageTitle: item.PackageTitle,
  //     };
  //     return obj;
  //   });
  //   setExcelList(filteredData);
  // };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => {
            setIsEdit(true);
            setIsCloneMode(false);
            onUpdate(currentItem);
          }}
        >
          <EditOutlined
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div>
          <Tooltip placement="top" title="Clone Tour Extranet CMS">
            {" "}
            <CopyOutlined
              onClick={() => {
                setIsCloneMode(true);
                setIsEdit(false);
                onUpdate(currentItem);
              }}
              style={{
                color: "#174998",
                fontSize: "20px",
                textAlign: "center",
              }}
            />
          </Tooltip>
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deletePolicyRequest(currentItem.Id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Package Title",
      dataIndex: "PackageTitle",
    },
    {
      title: "Cancellation Hours",
      dataIndex: "CancellationHours",
    },
    {
      title: "Cancellation Charge",
      dataIndex: "CancellationCharge",
    },
    {
      title: "Type",
      dataIndex: "TypeName",
    },

    {
      title: "Status",

      render: (currentItem) => handelStatus(currentItem),
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const handelStatus = (data) => {
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        checked={data.Status == 1 ? true : false}
        onChange={() => {
          changeStatus(data, data.Status == 1 ? 0 : 1);
        }}
      />
    );
  };
  const preventMinus = (e) => {
    if (e.code === 'NumpadSubtract') {
      e.preventDefault();
    }
  };

  const changeStatus = (info, Status) => {
    delete info.SNo;
    delete info.HolidayPackageId;
    delete info.PackageTitle;
    delete info.CancellationHours;
    delete info.CancellationCharge;
    delete info.Type;
    delete info.TypeName;

    let obj = {
      ...info,
      Status: Status,
    };
    let id = info?.Id;
    updatePolicyRequest(obj, id);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = policyList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setPolicyList(searchResults);
    } else {
      getPolicyRequest();
      setPolicyList(policyList);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  const searchData = () => {
    setShowSearchBox(true);
  };
  const CheckingValidating = (e) => {
     if (e.target.value > 0) {
      setcValue(e.target.value)
    }
  }

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div className="navigationmain-box" >
                  <a className="navigation-color" style={{ marginLeft: "1.5%" }} href="/admin/toursextranet-packages">Packages </a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-price">Price</a><i class="arrow right"></i>
                  {/* <a className="navigation-color" href="/admin/toursextranet-overview">overview</a><i class="arrow right"></i>
                  <a className="navigation-color" href="/admin/toursextranet-cms">cms</a><i class="arrow right"></i> */}
                  <a className="navigation-color" href="/admin/toursextranet-media">media</a><i class="arrow right"></i>
                  <a className="navigation-color" style={{ color: "orange" }} href="/admin/toursextranet-cancelpolicy">Cancel Policy</a>
                </div>
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Cancellation Policy{" "}
                          <HelpInfoHelper
                            screenName={"/admin/toursextranet-cancelpolicy"}
                          />
                        </h5>
                        {policyList.length > 0 && (
                          <p>{policyList.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      {/* <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          exportExcel(excelList, "TourExtranetCMS")
                        }
                      /> */}
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getPolicyRequest()}
                      />
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>
                  {policyList.length > 0 ? (

                    <Collapse
                      defaultActiveKey={["1"]}
                      accordion
                      expandIconPosition={"right"}
                    >
                      {policyList.map((item, index) => {
                        return (<Panel header={`${item.PackageTitle}: ${item.Duration}, ${item.CityName}, ${item.CountryName}`} key={index + "panel"} >
                          {item.CancellationPolicyInfo.length > 0 ? (<div>
                            <Table
                              className="table-scroll-none"
                              bordered
                              dataSource={item.CancellationPolicyInfo}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>) : (<b>No Records Found</b>)}
                        </Panel>)
                      })}
                    </Collapse>

                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEdit ? "Update" : isCloneMode ? "Clone" : "Add"} Cancellation
                Policy
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-bc-level modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>{Addupdate ? (<Spin style={{ marginRight: "5px" }} />) : null}
            <Button htmlType="submit" type="primary" onClick={form.submit} disabled={Addupdate}>
              {isEdit ? "Update" : isCloneMode ? "Clone" : "Add"}
            </Button>
            <Button type="danger" onClick={() => form.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={form} onFinish={submitForm}>
          <Row gutter={(12, 12)} className="mb-2">
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Package"
                name="HolidayPackageId"
                rules={[{ required: true }]}
              >
                <Select showSearch placeholder="Select Package">
                  {packageList.map((item, index) => (
                    <Option
                      key={index + "Package"}
                      value={item.HolidayPackageId}
                    >
                      {item.PackageTitle}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Cancellation Hours"
                name="CancellationHours"
                rules={[{ required: true },]}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item label="Type" name="Type" rules={[{ required: true }]}>
                <Select showSearch placeholder="Select Type">
                  <Option value={1}>Percentage</Option>
                  <Option value={0}>Fixed</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Cancellation Charge"
                name="CancellationCharge"
                rules={[{ required: true }]}
              >
                <Input type="number" min={0}
                  onPaste={preventPasteNegative}
                  onKeyPress={preventMinus}
                />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item
                label="Status"
                name="Status"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(HolidayCancelPolicy);
