import React, { useEffect, useState } from "react";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Popconfirm,
    Row,
    Table,
    Layout,
    Modal,
    Upload,
    Image,
    Spin
} from "antd";
import {
    PlusOutlined,
    EditOutlined,
    CloseOutlined,
    DeleteOutlined,
    EyeOutlined,
} from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";

const { TextArea } = Input;
const { Content } = Layout;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const ImageUpload = ({
    category,
    fileList,
    setFileList,
    formName,
    deleteBanner,
    limit,
    limitSize,
}) => {
    const [isUploading, setIsUploading] = useState(false);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const uploadImageButton = !isUploading ? (
        <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload </div>
        </div>
    ) : (
        <div className="ant-upload-text">Loading... </div>
    );

    function beforeUpload(file) {
        const checkJpgOrPng =
            file.type === "image/png" ||
            file.type === "image/jpeg" ||
            file.type === "image/jpg";
        if (!checkJpgOrPng) {
            message.error("You can only upload jpg, jpeg and png file!");
        }

        const checkFileSize = file.size < 1126400;
        if (!checkFileSize) {
            message.error(" Image must be smaller than 1Mb!");
        }

        return checkJpgOrPng && checkFileSize;
    }

    function getImages(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    function handleChange(info) {
        if (info.file.status === "uploading") {
            setFileList(info.fileList);
            setIsUploading(false);
        }
        if (info.file.status === "removed") {
            setIsUploading(false);
            setFileList(info.fileList);
        }
        if (info.file.status === "error") {
            setIsUploading(false);
            return;
        }
        if (info.file.status === "done") {
            setFileList(info.fileList);
            setIsUploading(false);
        }
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getImages(file.originFileObj);
        }
        setPreviewVisible(true);
        setPreviewImage(file.url || file.preview);

        setPreviewTitle(file.name);
    };

    const handleCancel = () => {
        setPreviewVisible(false);
    };

    return (
        <>
            <Form.Item
                name={formName}
            // rules={[
            //   { required: fileList.length > 0 ? false : true, message: "Required" },
            // ]}
            >
                <Upload
                    name="image"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={fileList}
                    action={uploadActionUrl}
                    onChange={handleChange}
                    onPreview={handlePreview}
                    data={{ category: category }}
                    onRemove={(val) => deleteBanner(val.uid)}
                    beforeUpload={beforeUpload}
                >
                    {limit
                        ? fileList.length >= limitSize
                            ? null
                            : uploadImageButton
                        : uploadImageButton}
                </Upload>
            </Form.Item>
            <Modal
                visible={previewVisible}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
            </Modal>
        </>
    );
};

const HotelRoomCategory = ({ exportExcel }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [RMCategoryForm] = Form.useForm();
    const [RMCategory, setRMCategory] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [currID, setCurrID] = useState(-1);
    const [showSearchBox, setShowSearchBox] = useState(false);
    const [excelList, setExcelList] = useState([]);
    const [image, setImage] = useState([]);
    const [imageRequestModal, setImageRequestModal] = useState(false);
    const [imageRequest, setImageRequest] = useState([]);
    const [Addupdate, setAddupdate] = useState(false);

    useEffect(() => {
        getRMCategory();
    }, []);

    const getRMCategory = () => {
        ApiClient.get("HolidayExtranet/getAllHotelRoomCategory")
            .then((res) => {
                if (res.status == 200) {
                    let data = res.data.map((item, index) => {
                        return {
                            SNo: index + 1,
                            ...item,
                            CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
                            ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
                        };
                    });
                    setRMCategory(data);
                    defineExcelData(data);
                } else {
                    setRMCategory([]);
                }
            })
            .catch((e) => {
                setRMCategory([]);
            });
    };

    const submitForm = (values) => {

        let ImageArray = [];

        if (image.length > 0) {
            for (let i = 0; i < image.length; i++) {
                if (image[i].response.status === 200) {
                    ImageArray.push(image[i].response.data.filepaths[0]);
                }
            }
        }
        let data = {
            Icon: ImageArray,
            RoomCategoryName: values.RoomCategoryName,
            Description: values.Description,
        };

        isEdit ? updaRMCategory(data, currID) : addCategory(data);
    };

    const addCategory = (data) => {
        setAddupdate(true);
        ApiClient.post("HolidayExtranet/createHotelRoomCategory", data)
            .then((response) => {
                if (response.status == 200) {
                    message.success("Category added successfully !");

                    getRMCategory();
                    setModalVisible(false);
                    RMCategoryForm.resetFields();
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const updaRMCategory = (data, recId) => {
        setAddupdate(true);
         ApiClient.put("HolidayExtranet/update/hotelroomcategory/" + recId, {}, data)
            .then((response) => {
                if (response.status == 200) {
                    message.success("Category updated successfully !");

                    getRMCategory();
                    setModalVisible(false);
                } else {
                    message.error(response.message, 3);
                }
                setAddupdate(false);
            })
            .catch((error) => {
                console.error(error);
                setAddupdate(false);
            });
    };

    const deleRMCategory = (id) => {
        setAddupdate(true);
        ApiClient.delete("HolidayExtranet/deleteHotelRoomCategory/" + id)
            .then((response) => {
                if (response.status == 200) {
                    message.success("Category Deleted successfully !");
                    getRMCategory();
                } else {
                    message.error(response.message, 3);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const showModal = () => {
        setAddupdate(false);
        setIsEdit(false);

        RMCategoryForm.resetFields();
        setImage([]);
        setModalVisible({
            visible: true,
        });
    };

    const onUpdate = (currentData) => {
        setAddupdate(false)
        setImage([]);
        RMCategoryForm.resetFields();
        setIsEdit(true);
        setModalVisible(true);
        setCurrID(currentData.RoomCategoryId);
        RMCategoryForm.setFieldsValue({
            RoomCategoryName: currentData.RoomCategoryName,
            Description: currentData.Description,
        });
        let array = [];
        if (currentData.Icon.length > 0) {
            for (let i = 0; i < currentData.Icon.length; i++) {
                array.push({
                    uid: currentData.RoomCategoryId,
                    status: "done",
                    url: `${BASE}${currentData.Icon[i].substring(1)}`,
                    response: {
                        status: 200,
                        data: {
                            filepaths: [currentData.Icon[i]],
                        },
                    },
                });
            }
            setImage(array);
        }
    };

    const defineExcelData = (data) => {
        const filteredData = data.map((item) => {
            const obj = {
                SrNo: item.SNo,

                CategoryName: item.CategoryName,
                Description: item.Description,
            };
            return obj;
        });
        setExcelList(filteredData);
    };

    const tableActions = (currentItem) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                    className="edit-icon"
                    style={{ textAlign: "center" }}
                    onClick={() => onUpdate(currentItem)}
                >
                    <EditOutlined
                        style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
                    />
                </div>
                <div className="edit-icon" style={{ textAlign: "center" }}>
                    <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => deleRMCategory(currentItem.RoomCategoryId)}
                    >
                        <DeleteOutlined
                            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
                        />
                    </Popconfirm>
                </div>
            </div>
        );
    };

    const DeleteImage = (id) => {
     };

    const columns = [
        {
            title: "SNo",
            dataIndex: "SNo",
        },
        {
            title: "Icon",
            dataIndex: "Icon",
            render: (Icon) => {
                return Icon.length > 0 ? ImageRequest(Icon) : null;
            },
        },
        {
            title: "RoomCategory Name",
            dataIndex: "RoomCategoryName",
        },
        {
            title: "Description",
            dataIndex: "Description",
            render: (text) => {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: text }}
                        style={{ whiteSpace: "break-spaces", lineHeight: "14px" }}
                    ></div>
                );
            },
        },
        // {
        //   title: "Status",

        //   render: (currentItem) => handelStatus(currentItem),
        // },

        {
            title: "Actions",
            render: (currentItem) => tableActions(currentItem),
        },
    ];

    const ImageRequest = (CategoryImages) => {
        return (
            <div className="text-center">
                <span
                    style={{ cursor: "pointer", color: "green" }}
                    onClick={(e) => {
                        setImageRequest(CategoryImages);
                        setImageRequestModal(true);
                    }}
                >
                    <EyeOutlined />
                </span>
            </div>
        );
    };

    // const handelStatus = (data) => {
    //   <Switch
    //     checkedChildren="Active"
    //     unCheckedChildren="Inactive"
    //     checked={data.Status == 1 ? true : false}
    //     onChange={() => {
    //       changeStatus(data, data.Status == 1 ? 0 : 1);
    //     }}
    //   />;
    // };

    // const changeStatus = (info, Status) => {
    //   delete info.SNo;
    //   delete info.MediaFor;
    //   delete info.HotelImageTypeId;
    //   delete info.RoomName;
    //   delete info.HotelId;
    //   let obj = {
    //     ...info,
    //     Status: Status,
    //   };
    //   let id = info?.Images[0]?.MediaId;

    //   // UpdaRMCategory(obj, id);
    // };
    // Category updated successfully !
    const searchTableData = (e) => {
        var searchVal = e.target.value;
        if (searchVal.length > 0) {
            const searchResults = RMCategory.filter(function (item) {
                if (item != undefined) {
                    return JSON.stringify(item)
                        .toLowerCase()
                        .includes(searchVal.toLowerCase());
                }
            });
            setRMCategory(searchResults);
        } else {
            getRMCategory();
            setRMCategory(RMCategory);
        }
    };
    const closeSearch = () => {
        setShowSearchBox(false);
    };

    const closeSearchInput = (
        <div>
            <CloseOutlined onClick={closeSearch} />
        </div>
    );

    const searchData = () => {
        setShowSearchBox(true);
    };

    return (
        <div className="promo providerapi">
            <div className="manage-markup-section">
                <Content className="admin-container">
                    <Row>
                        <Col span={24}>
                            <div className="card-bt-gap">
                                <Card bordered={false}>
                                    <div className="card-add-heading">
                                        <div className="rows-count">
                                            <div>
                                                <h5>
                                                    View RoomCategory{" "}
                                                    <HelpInfoHelper
                                                        screenName={"/admin/toursextranet-category"}
                                                    />
                                                </h5>
                                                {RMCategory.length > 0 && (
                                                    <p>{RMCategory.length} rows found !</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="action-images">
                                            {showSearchBox && (
                                                <Input
                                                    placeholder="Search"
                                                    onChange={(e) => searchTableData(e)}
                                                    suffix={closeSearchInput}
                                                    style={{ padding: "0px 12px" }}
                                                />
                                            )}
                                            &nbsp;&nbsp;
                                            <img
                                                src={search}
                                                alt="search"
                                                onClick={(e) => searchData()}
                                            />
                                            <img
                                                src={excel}
                                                alt="excel"
                                                onClick={(e) =>
                                                    exportExcel(excelList, "TourExtranetCategory")
                                                }
                                            />
                                            <img
                                                src={exchange}
                                                alt="exchange"
                                                onClick={() => getRMCategory()}
                                            />
                                            <p className="add-icon" onClick={showModal}>
                                                <PlusOutlined />
                                            </p>
                                        </div>
                                    </div>
                                    {RMCategory.length > 0 ? (
                                        <div>
                                            <Table
                                                className="table-scroll-none"
                                                bordered
                                                dataSource={RMCategory}
                                                columns={columns}
                                                pagination={{
                                                    defaultPageSize: 25,
                                                    showSizeChanger: true,
                                                    pageSizeOptions: ["25", "50", "100", "125"],
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <b>No record Found</b>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </div>

            <Modal
                title={[
                    <div>
                        <h6 style={{ marginBottom: "0px" }}>
                            <strong>{isEdit ? "Update Category" : "Add Category"}</strong>
                        </h6>
                    </div>,
                ]}
                className="promo-modal-header admin-bc-level modal-header-bg12"
                visible={modalVisible}
                onOk={(e) => { setModalVisible(false) }}
                onCancel={(e) => setModalVisible(false)}
                footer={[
                    <div>{Addupdate ? (<Spin style={{ marginRight: "5px" }} />) : null}
                        <Button
                            htmlType="submit"
                            type="primary"
                            onClick={RMCategoryForm.submit}
                            disabled={Addupdate}
                        >
                            {isEdit ? "Update" : "Add"}
                        </Button>
                        <Button type="danger" onClick={() => RMCategoryForm.resetFields()}>
                            Clear
                        </Button>
                    </div>,
                ]}
            >
                <Form layout={"vertical"} form={RMCategoryForm} onFinish={submitForm}>
                    <Row gutter={(12, 12)} className="mb-2">
                        <Col className="gutter-row" md={24} xs={24}>
                            <Form.Item
                                label="RoomCategory Name"
                                name="RoomCategoryName"
                                rules={[{ required: true }]}
                            >
                                <Input rows={4} placeholder="Category Name" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={24} xs={24}>
                            <Form.Item
                                label="Description"
                                name="Description"
                                rules={[{ required: true }]}
                            >
                                <TextArea rows={4} placeholder="RoomCategory Description" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <p>Images</p>

                            <div className="upload-logo">
                                <ImageUpload
                                    category="RoomCategoryImages"
                                    fileList={image}
                                    setFileList={setImage}
                                    formName="Icon"
                                    limit={true}
                                    limitSize={3}
                                    deleteBanner={DeleteImage}
                                />
                            </div>
                        </Col>
                        {/* <Col md={24} xs={24}>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={2}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col> */}
                    </Row>
                </Form>
            </Modal>
            <Modal
                visible={imageRequestModal}
                title="Photos"
                onCancel={(e) => {
                    setImageRequestModal(false);
                }}
                footer={false}
                className="modal-header-bg12"
            >
                <Row gutter={12}>
                    {imageRequest.map((image, index) => (
                        <Col md={6} sm={8} xs={12} key={index}>
                            <Image src={BASE + image.substring(1)} />
                        </Col>
                    ))}
                </Row>
            </Modal>
        </div>
    );
};

export default UpdatedComponent(HotelRoomCategory);
