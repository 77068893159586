import React from "react";
import * as ANTD from "antd";

import FlightTicket from "../../admin/FlightTicket/FlightTicket";

import { Form, Input, Modal, Select, Menu } from "antd";
import {
  PrinterOutlined,
  MessageOutlined,
  MailOutlined,
  DownloadOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Option } from "antd/lib/mentions";

const PrintTicket = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [formLayout, setFormLayout] = React.useState("vertical");

  const tailLayout = {
    wrapperCol: { span: 24 },
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const Print = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Print Confirmation With Price
        </a>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Print Confirmation Without Price
        </a>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const Email = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Email With Price
        </a>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <a rel="noopener noreferrer" href="#">
          Email Without Price
        </a>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const [form] = ANTD.Form.useForm();
  const submitForm = (val) => {


    let url_formed = val.ServiceType + "?ref=" + val.RefId;

    document.location.replace(url_formed);
  };
  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <div className="card-add-heading">
                    <h3>My trips</h3>
                  </div>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row>
              <ANTD.Col span={24}>
                <ANTD.Card>
                  <div className="">
                    <h5>Enter Ticket Details</h5>
                  </div>
                  <ANTD.Form form={form} onFinish={submitForm}>
                    <ANTD.Row gutter={24}>
                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Service Type"
                          name="ServiceType"
                          rules={[{ required: true, message: " " }]}
                        >
                          <Select placeholder="Select Service Type">
                            <Option value="flight/ticket">Flights</Option>

                            <Option value="hotel/ticket">Hotel</Option>
                          </Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Reference ID"
                          name="RefId"
                          rules={[{ required: true, message: " " }]}
                        >
                          <ANTD.Input />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Mobile"
                          name="Mobile"
                          rules={[{ required: true, message: " " }]}
                        >
                          <ANTD.Input />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col className="gutter-class" lg={6} md={12} xs={24}>
                        <ANTD.Form.Item>
                          <ANTD.Button
                            type="primary"
                            className="retrive-btn"
                            htmlType="submit"
                            style={{ width: "100%" }}
                          >
                            Retrieve
                          </ANTD.Button>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Print Ticket</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header cancel-modal"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              {/*  <ANTD.Button key="add" type="primary"  >Print Ticket</ANTD.Button> */}
            </div>,
          ]}
        >
          <ANTD.Row className="print-ticket-btn">
            <ANTD.Dropdown overlay={Print} placement="bottomCenter">
              <ANTD.Button className="all-print-btn" type="primary">
                Print
                <DownOutlined className="printmail" />
              </ANTD.Button>
            </ANTD.Dropdown>

            {/* <ANTD.Button className="all-print-btn" type="primary"><PrinterOutlined />Print</ANTD.Button> */}
            <ANTD.Button className="all-print-btn" type="primary">
              <MessageOutlined />
              SMS
            </ANTD.Button>

            <ANTD.Dropdown overlay={Email} placement="bottomCenter">
              <ANTD.Button className="all-print-btn" type="primary">
                <MailOutlined />
                Email
              </ANTD.Button>
            </ANTD.Dropdown>
            {/* <ANTD.Button className="all-print-btn" type="primary"><MailOutlined />Email</ANTD.Button> */}
            <ANTD.Button className="all-print-btn" type="primary">
              <DownloadOutlined />
              Download
            </ANTD.Button>

            <ANTD.Col md={24} xs={24}></ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col md={24} xs={24}>
              <FlightTicket />


            </ANTD.Col>
          </ANTD.Row>
        </Modal>
      </div>
    </div>
  );
};
export default PrintTicket;
