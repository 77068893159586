import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Popover,
  Radio,
  Tooltip,
  Modal,
  message,
} from "antd";
import "./HotelFairBox.scss";
import Login from "../../../components/Login/Login";
import ReactHtmlParser from "react-html-parser";
import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getPromoDiscount = (promoData, total) => {
  let promoAmount = 0;

  if (promoData && promoData?.status) {
    if (promoData.DiscountType === 1) {
      promoAmount = Number((total / 100) * promoData.Discount);
    } else {
      promoAmount = Number(promoData.Discount);
    }
  }

  return Number(promoAmount);
};
export const getHotelGrandTotal = (hotelDetailsObj, hotelSearchData, ConvFee, promoData, selectedInsuranceData, redeemAmount
) => {
  let totalRoomBaseFare = 0;
  let totalRoomTax = 0;
  let totalOtherCharges = 0;
  let totalExtraGuestCharges = 0;
  let noOfNights = 0;
  let convamount = 0;
  let promoDiscount = 0;
  let noOfRooms = 0;
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let totalCommission = 0;
  let redeemTotal = 0;
  let hotelDiscount = 0;
  let roomDetails = {
    roomCount: 0,
    roomNightPrice: 0,
    totalTax: 0,
    total: 0

  };

  if (Object.keys(hotelSearchData).length > 0) {
    let checkin = new Date(hotelSearchData.checkInDate);
    let checkout = new Date(hotelSearchData.checkOutDate);
    let diffTime = checkout - checkin;
    let diffDays = Math.ceil(diffTime / (1000 * 24 * 60 * 60));

    noOfNights = Number(diffDays);
  } else return;

  if (hotelDetailsObj?.combineRoom?.length > 0) {
    noOfRooms = hotelDetailsObj?.combineRoom[0]?.combineRooms.length;
    hotelDetailsObj?.combineRoom.forEach((roomDetail) => {
      if (roomDetail?.priceDetails) {
        const priceObj = roomDetail?.priceDetails;

        totalRoomBaseFare += Number(priceObj.base);
        totalRoomTax += Number(priceObj.tax);
        totalOtherCharges += Number(priceObj.otherCharges);
        totalExtraGuestCharges += Number(priceObj?.extraGuestCharges ?? 0);
        agentMarkup += Number(priceObj?.agentMarkup ?? 0);
        adminCommission += Number(priceObj?.adminCommission ?? 0);
        markup += Number(priceObj?.markup ?? 0);
        totalCommission += Number(roomDetail?.commission ?? 0);
        hotelDiscount += Number(priceObj?.discount ?? 0);
      }
    });
  }

  let grandBaseFare = Number(totalRoomBaseFare);
  let grandTax = Number(totalRoomTax);
  let grandOtherCharges = Number(totalOtherCharges);
  let grandExtraGuestCharges = Number(totalExtraGuestCharges);
  let grandHotelDiscount = Number(hotelDiscount);

  let totalTax = Number(grandTax) + Number(grandOtherCharges);

  roomDetails = {
    roomCount: noOfRooms,
    roomNightPrice: grandBaseFare,
    totalTax: totalTax,
    tax: grandTax,
    otherCharges: grandOtherCharges,
    extraGuestCharges: grandExtraGuestCharges,
    totalHotelDiscount: grandHotelDiscount,
  };

  let total = hotelDetailsObj?.combineRoom[0]?.price

  


  // console.log(ConvFee, "hemanth")
  if (ConvFee) {
    if (ConvFee.type == 1) {
      convamount = Number(ConvFee.amount);
    } else {
      convamount = Number((total / 100) * Number(ConvFee.amount));
    }
  }

  // console.log(convamount, "hemu")

  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 2
  ) {
    let totalPax = hotelSearchData.roomGuests.reduce(
      (acc, cur) => acc + Number(cur.noOfAdults) + Number(cur.noOfChilds),
      0
    );

    insuranceTotal = totalPax * Number(selectedInsuranceData.amount);
  }
  promoDiscount = getPromoDiscount(promoData, total);

  let totalAmount = Number(total) + Number(convamount) + Number(insuranceTotal);
  totalAmount = Number(totalAmount) - Number(promoDiscount);

  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  totalAmount -= redeemTotal;
  return {
    roomDetails,
    noOfNights,
    promoDiscount,
    totalAmount: Number(totalAmount).toFixed(2),
    convamount,
    totalCommission,
    adminCommission,
    agentMarkup,
    markup,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
  };
};

const HotelFairBox = ({ hotelDetailsObj, hotelSearchData, isPromoVisible, location }) => {
  const [form] = Form.useForm();
  let dateFormat = "YYYY-MM-DD";
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
    },
    AddConvFee,
    validatePromoCode,
    RemovePromo,
    getInsuranceByServiceType,
    validateRedeemCoupon,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);

  const [promoCode, setPromoCode] = useState([]);
  const [redeemData, setRedeemData] = useState({});
  const [info, setinfo] = useState([]);
  const [isinfo, setisinfo] = useState(false);
  const [price, setprice] = useState([]);
  const [isprice, setisprice] = useState(false);
  const { roomDetails, noOfNights, promoDiscount, totalAmount, convamount, insuranceTotal, totalCommission, redeemTotal, additionalFee
  } = getHotelGrandTotal(
    hotelDetailsObj,
    hotelSearchData,
    ConvFee,
    promoData,
    selectedInsuranceData,
    redeemAmount
  );
  const [Loc, setLoc] = useState({});
  useEffect(() => {
    if (location) {
      setLoc(location);
    }
  }, [location]);

  useEffect(() => {
    if (!agent) {
      AddConvFee(2);
      getpromo();
    }
  }, [agent]);

  useEffect(() => {
    getInsuranceByServiceType(2);
  }, []);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {


            let nPromo = data?.filter(
              (promo) =>
                promo.ServiceType === 2 && Number(promo.StarRating) === Number(hotelDetailsObj?.starRating) &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );
            if (nPromo.length === 0) {
              nPromo = data?.filter(
                (promo) =>
                  promo.ServiceType === 2 && Number(promo.starRating) === 0 &&
                  (promo.ApplicationType === 1 || promo.ApplicationType === 3)
              );
            }

            setPromoCode(nPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };

  const handleApply = (code) => {
    if (user != null) {
      form.setFieldsValue({
        promo: code,
      });

      form.submit();
    } else {
      message.error("pls Sign-In to Avail Offers")
    }
  };
  const handleinfo = (val) => {
    setinfo(val);
    setisinfo(true);
  }
  const handleprice = (val) => {
    setprice(val);
    setisprice(true);
  }

  const roomFare = () => {
    return (
      <>

        <div className="pax-count-acc-body">
          <p>Base fare</p>
          <p>
            {
              activeCurrency === "INR" ? (
                <i class="fa fa-inr" aria-hidden="true"></i>
              ) : activeCurrency === "USD" ? (
                <i class="fa fa-usd" aria-hidden="true"></i>
              ) : activeCurrency === "EUR" ? (
                <i class="fa fa-eur" aria-hidden="true"></i>
              ) : activeCurrency === "GBP" ? (
                <i class="fa fa-gbp" aria-hidden="true"></i>
              ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                <i class="fa fa-jpy" aria-hidden="true"></i>
              ) : activeCurrency
            }{" "}
            {currencyValue(totalAmount)}{" "}
          </p>
        </div>
      </>
    );
  };
  useEffect(() => {
    if (user) {
      getRedeemCoupon(user.UserID);
    }
  }, [user]);
  const getRedeemCoupon = (userID) => {
    APIClient.get("admin/getUserCouponsWalletAmt/" + userID)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };

  return (
    <>
      <div className="price-card-container">
        <Card className="pax-count-card hotel-cards-details">
          <h6 className="price-header" style={{ color: "#320063" }}><b>Your price Summary</b></h6>
          <div className="price-section">
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p className="head-er">Price</p>
              </Col>
              <Col className="d-flex">
                <p>
                  {
                    activeCurrency === "INR" ? (
                      <i class="fa fa-inr" aria-hidden="true"></i>
                    ) : activeCurrency === "USD" ? (
                      <i class="fa fa-usd" aria-hidden="true"></i>
                    ) : activeCurrency === "EUR" ? (
                      <i class="fa fa-eur" aria-hidden="true"></i>
                    ) : activeCurrency === "GBP" ? (
                      <i class="fa fa-gbp" aria-hidden="true"></i>
                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                      <i class="fa fa-jpy" aria-hidden="true"></i>
                    ) : activeCurrency
                  }
                  {" "}
                  {currencyValue(hotelDetailsObj?.combineRoom[0]?.price)}
                </p>
                <Popover
                  overlayClassName="pricepopup"
                  placement="left"
                  content={
                    <>
                      <div className="pax-count-acc-body">
                        <div className="pax-type">
                          <p>Base Fare</p>

                        </div>

                        <div className="service-price">
                          <p>
                            {
                              activeCurrency === "INR" ? (
                                <i class="fa fa-inr" aria-hidden="true"></i>
                              ) : activeCurrency === "USD" ? (
                                <i class="fa fa-usd" aria-hidden="true"></i>
                              ) : activeCurrency === "EUR" ? (
                                <i class="fa fa-eur" aria-hidden="true"></i>
                              ) : activeCurrency === "GBP" ? (
                                <i class="fa fa-gbp" aria-hidden="true"></i>
                              ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                                <i class="fa fa-jpy" aria-hidden="true"></i>
                              ) : activeCurrency
                            }
                            {" "}
                            {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.totalBasePrice)}
                          </p>

                        </div>
                      </div>

                    </>
                  }
                  title="Base Fare"
                >
                  <QuestionCircleOutlined />
                </Popover>


              </Col>
            </Row>
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col></Col>
              <Col>
                <div className="sub-pr-ce">
                  <p className="price-tax">+ {" "}
                    {
                      activeCurrency === "INR" ? (
                        <i class="fa fa-inr" aria-hidden="true"></i>
                      ) : activeCurrency === "USD" ? (
                        <i class="fa fa-usd" aria-hidden="true"></i>
                      ) : activeCurrency === "EUR" ? (
                        <i class="fa fa-eur" aria-hidden="true"></i>
                      ) : activeCurrency === "GBP" ? (
                        <i class="fa fa-gbp" aria-hidden="true"></i>
                      ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                        <i class="fa fa-jpy" aria-hidden="true"></i>
                      ) : activeCurrency
                    }
                    {` `}
                    {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.totalTax)} Taxes and Charges
                    <Popover
                      overlayClassName="pricepopup"
                      placement="left"
                      content={
                        <>
                          {hotelDetailsObj?.combineRoom[0]?.priceDetails?.serviceTax > 0 && (
                            <div className="pax-count-acc-body">
                              <div className="pax-type">
                                <p>Service Tax</p>
                              </div>

                              <div className="service-price">
                                <p>
                                  {
                                    activeCurrency === "INR" ? (
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                    ) : activeCurrency === "USD" ? (
                                      <i class="fa fa-usd" aria-hidden="true"></i>
                                    ) : activeCurrency === "EUR" ? (
                                      <i class="fa fa-eur" aria-hidden="true"></i>
                                    ) : activeCurrency === "GBP" ? (
                                      <i class="fa fa-gbp" aria-hidden="true"></i>
                                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                                      <i class="fa fa-jpy" aria-hidden="true"></i>
                                    ) : activeCurrency
                                  }
                                  {" "}
                                  {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.serviceTax)}
                                </p>
                              </div>
                            </div>
                          )}
                          {hotelDetailsObj?.combineRoom[0]?.priceDetails?.tax > 0 && (
                            <div className="pax-count-acc-body">
                              <div className="pax-type">
                                <p>Tax</p>
                              </div>

                              <div className="service-price">
                                <p>
                                  {
                                    activeCurrency === "INR" ? (
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                    ) : activeCurrency === "USD" ? (
                                      <i class="fa fa-usd" aria-hidden="true"></i>
                                    ) : activeCurrency === "EUR" ? (
                                      <i class="fa fa-eur" aria-hidden="true"></i>
                                    ) : activeCurrency === "GBP" ? (
                                      <i class="fa fa-gbp" aria-hidden="true"></i>
                                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                                      <i class="fa fa-jpy" aria-hidden="true"></i>
                                    ) : activeCurrency
                                  }
                                  {" "}
                                  {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.tax)}
                                </p>
                              </div>
                            </div>
                          )}

                          {hotelDetailsObj?.combineRoom[0]?.priceDetails?.otherCharges > 0 && (
                            <div className="pax-count-acc-body">
                              <div className="pax-type">
                                <p>Other Tax</p>
                              </div>

                              <div className="service-price">
                                <p>
                                  {
                                    activeCurrency === "INR" ? (
                                      <i class="fa fa-inr" aria-hidden="true"></i>
                                    ) : activeCurrency === "USD" ? (
                                      <i class="fa fa-usd" aria-hidden="true"></i>
                                    ) : activeCurrency === "EUR" ? (
                                      <i class="fa fa-eur" aria-hidden="true"></i>
                                    ) : activeCurrency === "GBP" ? (
                                      <i class="fa fa-gbp" aria-hidden="true"></i>
                                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                                      <i class="fa fa-jpy" aria-hidden="true"></i>
                                    ) : activeCurrency
                                  }
                                  {" "}
                                  {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.otherCharges)}
                                </p>
                              </div>
                            </div>
                          )}

                        </>
                      }
                      title="Taxes and Fees"
                    >
                      <QuestionCircleOutlined />
                    </Popover>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          {roomDetails?.extraGuestCharges != 0 ? <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Extra Guest Charge</p>
            </Col>
            <Col className="d-flex">
              <p>
                {
                  activeCurrency === "INR" ? (
                    <i class="fa fa-inr" aria-hidden="true"></i>
                  ) : activeCurrency === "USD" ? (
                    <i class="fa fa-usd" aria-hidden="true"></i>
                  ) : activeCurrency === "EUR" ? (
                    <i class="fa fa-eur" aria-hidden="true"></i>
                  ) : activeCurrency === "GBP" ? (
                    <i class="fa fa-gbp" aria-hidden="true"></i>
                  ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                    <i class="fa fa-jpy" aria-hidden="true"></i>
                  ) : activeCurrency
                }
                {" "}

                {currencyValue(roomDetails.extraGuestCharges)}
              </p>
            </Col>
          </Row> : null}


          {promoData.status ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Discount</p>
              </Col>
              <Col className="d-flex">
                <p>
                  -  {
                    activeCurrency === "INR" ? (
                      <i class="fa fa-inr" aria-hidden="true"></i>
                    ) : activeCurrency === "USD" ? (
                      <i class="fa fa-usd" aria-hidden="true"></i>
                    ) : activeCurrency === "EUR" ? (
                      <i class="fa fa-eur" aria-hidden="true"></i>
                    ) : activeCurrency === "GBP" ? (
                      <i class="fa fa-gbp" aria-hidden="true"></i>
                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                      <i class="fa fa-jpy" aria-hidden="true"></i>
                    ) : activeCurrency
                  }
                  {" "}
                  {currencyValue(promoDiscount)}
                </p>
              </Col>
            </Row>
          ) : null}

          {selectedInsuranceData.status === 1 &&
            selectedInsuranceData?.serviceType === 2 ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Insurance Amount</p>
              </Col>
              <Col>
                <p>
                  {
                    activeCurrency === "INR" ? (
                      <i class="fa fa-inr" aria-hidden="true"></i>
                    ) : activeCurrency === "USD" ? (
                      <i class="fa fa-usd" aria-hidden="true"></i>
                    ) : activeCurrency === "EUR" ? (
                      <i class="fa fa-eur" aria-hidden="true"></i>
                    ) : activeCurrency === "GBP" ? (
                      <i class="fa fa-gbp" aria-hidden="true"></i>
                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                      <i class="fa fa-jpy" aria-hidden="true"></i>
                    ) : activeCurrency
                  }
                  {" "}
                  {currencyValue(insuranceTotal)}
                </p>
              </Col>
            </Row>
          ) : null}

          {convamount > 0 ? (
            <Row justify={"space-between"} className="grand_total_card_row">
              <Col>
                <p>Convenience Fee</p>
              </Col>
              <Col className="d-flex">
                <p>
                  {
                    activeCurrency === "INR" ? (
                      <i class="fa fa-inr" aria-hidden="true"></i>
                    ) : activeCurrency === "USD" ? (
                      <i class="fa fa-usd" aria-hidden="true"></i>
                    ) : activeCurrency === "EUR" ? (
                      <i class="fa fa-eur" aria-hidden="true"></i>
                    ) : activeCurrency === "GBP" ? (
                      <i class="fa fa-gbp" aria-hidden="true"></i>
                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                      <i class="fa fa-jpy" aria-hidden="true"></i>
                    ) : activeCurrency
                  }
                  {" "}
                  {currencyValue(convamount)}
                </p>
              </Col>
            </Row>) : null}

          <div className="pax-total-price">
            <div className="pax-type">
              <p>Total:</p>
            </div>
            <div className="total">
              <p>  {
                activeCurrency === "INR" ? (
                  <i class="fa fa-inr" aria-hidden="true"></i>
                ) : activeCurrency === "USD" ? (
                  <i class="fa fa-usd" aria-hidden="true"></i>
                ) : activeCurrency === "EUR" ? (
                  <i class="fa fa-eur" aria-hidden="true"></i>
                ) : activeCurrency === "GBP" ? (
                  <i class="fa fa-gbp" aria-hidden="true"></i>
                ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                  <i class="fa fa-jpy" aria-hidden="true"></i>
                ) : activeCurrency
              }
              </p>
              <p className="amount ml-1"> {currencyValue(totalAmount)}</p>
            </div>
          </div>
          <p className="all-taxes"> <i class="fa fa-money" aria-hidden="true"></i> Including all taxes and fees{" "}
            {hotelDetailsObj?.combineRoom[0]?.rateComments?.pax_comments ? (
              <span onClick={() => handleprice(hotelDetailsObj?.combineRoom[0]?.rateComments)}>
                <button style={{ color: "blue", border: "1px solid white", backgroundColor: "transparent" }} ><i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                </button>
              </span>) : null}
          </p>
          {agent && totalCommission > 0 ? (
            <div className="pax-total-price">
              <div className="pax-type">
                <p className="pax-comm">Commission:</p>
              </div>
              <div className="total">
                <p className="pax-comm">
                  {
                    activeCurrency === "INR" ? (
                      <i class="fa fa-inr" aria-hidden="true"></i>
                    ) : activeCurrency === "USD" ? (
                      <i class="fa fa-usd" aria-hidden="true"></i>
                    ) : activeCurrency === "EUR" ? (
                      <i class="fa fa-eur" aria-hidden="true"></i>
                    ) : activeCurrency === "GBP" ? (
                      <i class="fa fa-gbp" aria-hidden="true"></i>
                    ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                      <i class="fa fa-jpy" aria-hidden="true"></i>
                    ) : activeCurrency
                  }
                </p>
                <p className="amount ml-1 pax-comm">
                  {" "}
                  {currencyValue(totalCommission)}
                </p>
              </div>
            </div>
          ) : null}


        </Card>
      </div>
      {hotelDetailsObj?.combineRoom[0]?.priceDetails?.hotelCharges?.[0]?.mandatory ? <div className="add-fee">
        <Card bordered={false} className="hotel-add-wrapper">
          <div className="hdr-add">
            <h6>Additional Charges </h6>
            <div className="add-fee-amnt">
              <Row justify={"space-between"} className="grand_total_card_row">
                <Col>
                  <p className="head-er">Amount</p>
                </Col>
                <Col className="d-flex">
                  <p>
                    {hotelDetailsObj?.combineRoom[0]?.priceDetails?.hotelCharges[0]?.currency}
                    {" "}
                    {currencyValue(hotelDetailsObj?.combineRoom[0]?.priceDetails?.hotelCharges?.[0]?.amount)}
                  </p>
                </Col>
              </Row>

            </div>
            <p className="all-taxes"> <i class="fa fa-money" aria-hidden="true"></i> {" "}
              <Tooltip placement="bottom" title="This amount is charged by the hotel at the time of check-in.">
                <span>Excluding the Additional Charges! .</span>
              </Tooltip></p>
            <p className="all-tax"><i class="fa fa-exclamation-circle" aria-hidden="true"></i>

              <Tooltip placement="bottom" title="Only Additional Charges Collected by the Hotel, at the time of Check-in">
                <span onClick={() => handleinfo(hotelDetailsObj?.combineRoom[0]?.rateComments)}>{" "}No need to pay here.!{" "}
                  <button style={{ color: "blue", border: "1px solid white", backgroundColor: "transparent" }} >Why?</button></span>
              </Tooltip></p>
          </div>
        </Card>
      </div> : null}
      <Modal className="modal-css-direction-popup" visible={isprice} onCancel={() => setisprice(false)} onOk={() => setisprice(false)}>
        <div className="cont">
          {ReactHtmlParser(price?.pax_comments)}
        </div>



      </Modal>
      <Modal className="modal-css-direction-popup" visible={isinfo} onCancel={() => setisinfo(false)} onOk={() => setisinfo(false)}>
        <div className="cont">
          {ReactHtmlParser(info?.comments)}
        </div>



      </Modal>
      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="hotelpromo_wrapper">
          {promoData.status == false ? (
            <Card bordered={false} className="hotel-card-wrapper">
              <p className="name">Apply Promo</p>
              {user != null ?
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={(d) => {
                    validatePromoCode({
                      ServiceType: 2,
                      Amount: Number(totalAmount),
                      PromoCode: d.promo,
                      UserId: user?.UserID ?? 1,
                      StarRating: parseInt(hotelDetailsObj?.starRating),
                      userMail: user?.Email,
                    });
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col md={16} sm={12} xs={12}>
                      <Form.Item
                        name="promo"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input
                          className="inputbg"
                          placeholder="Enter Your Promo code"
                          autoComplete="off"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={12}>
                      <Form.Item>
                        <Button type="primary" onClick={() => form.submit()}>
                          Apply
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form> :
                <div>
                  <p>Please <span style={{ color: "blue", cursor: "pointer" }} onClick={() => showModal1("USER")}>Sign-In</span> to Avail Offers</p>
                </div>
              }

              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save  {
                                activeCurrency === "INR" ? (
                                  <i class="fa fa-inr" aria-hidden="true"></i>
                                ) : activeCurrency === "USD" ? (
                                  <i class="fa fa-usd" aria-hidden="true"></i>
                                ) : activeCurrency === "EUR" ? (
                                  <i class="fa fa-eur" aria-hidden="true"></i>
                                ) : activeCurrency === "GBP" ? (
                                  <i class="fa fa-gbp" aria-hidden="true"></i>
                                ) : activeCurrency === "JPY" || activeCurrency === "CNY" ? (
                                  <i class="fa fa-jpy" aria-hidden="true"></i>
                                ) : activeCurrency
                              }&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card bordered={false} className="hotel-card-wrapper">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />{" "}
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
      <Modal
        centered
        visible={modalVisible.visible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        className="login-modal"
        footer={false}
      >
        <Login
          location={Loc}
          setModalVisible={(value) => setModalVisible(value)}
          type={modalVisible.type}
        />
      </Modal>

    </>
  );
};

export default HotelFairBox;
