import React, { useContext, useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import HotelDetailsCard from "../../../components/HotelDetailsBox/HotelDetailsCard";
import {
  Row,
  Card,
  Col,
  Form,
  Input,
  DatePicker,
  Select,
  Skeleton,
  message,
  Radio,
  TimePicker,
  Modal,
  Statistic 
} from "antd";

import HotelFairBox from "./HotelFairBox";
import { GlobalStatesContext } from "../../../common/providers";

import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import { getPassengerData } from "../../../helpers/PassegerData";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import HotelBookPayCard from "./HotelBookPayCard";
import CountryList from "../../../common/CountryList";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip,Popover } from 'antd';
import timeoutimg from "../../../assets/images/timeout.jpg";
import ApiClient from "../../../helpers/ApiClient";
import "./HotelCheckout.scss";
var moment = require('moment')

const { Option } = Select;

const { Countdown } = Statistic;

const HotelCheckout = ({location}) => {
  const { setHotelCheckOutData, state: { sessiontimeout,status }, getsessiontimeout } = useHotelContext();
  const {
    user,
    isLogin: { agent },
  } = useAuthContext();
  const {
    state: {
      otherData: { insuranceData, selectedInsuranceData },
    },

    setSelectedInsuranceData,
    RemovePromoConvFee,
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  const [contact_form] = Form.useForm();
  const [guestDetailsForm] = Form.useForm();
  const [arrival_form] = Form.useForm();
  const [departure_form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [ids, setIds] = useState({
    traceId: null,
    repriceId: null,
  });
  const [loading, setLoading] = useState(true);
  const [hotelPriceData, setHotelPriceData] = useState({});
  const [transportType, setTransportType] = useState(0);
  const [arrivalType, setArrivalType] = useState(0);

  const [hotelSearchData, setHotelSearchData] = useState({});

  const [roomGuestInfo, setRoomGuestInfo] = useState([]);
  useEffect(() => { getsessiontimeout() }, [])
  const handlePaxField = (val, roomIndex, paxIndex, key) => {
    let temp = [...roomGuestInfo];

    if (paxIndex === 0) {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = true;
    } else {
      temp[roomIndex].paxInfoList[paxIndex]["leadGuest"] = false;
    }

    temp[roomIndex].paxInfoList[paxIndex][key] = val;

    setRoomGuestInfo(temp);
  };


  const [insuranceRequired, setInsuranceRequired] = useState(-1);

  const loadpassengerData = () => {
    if (user && user?.UserID) {
      getPassengerData(user.UserID).then((data) => {
        if (data.status) {
          contact_form.setFieldsValue({
            phoneNo: data.Mobile,
            email: data.Email,
            addressLine1: data?.Address1 ? data?.Address1 : "",
            city: data?.city ? data?.city : "",
            state: data?.state ? data?.state : "",
          });
        }
      });
    }
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    RemovePromoConvFee();
    fetchHotelPrice();
    loadpassengerData();
  }, []);

  const HotelGuestReqFields = (paxObj, guestRequiredFields) => {
    guestRequiredFields.map((paxReqFieldsObj) => {
      Object.keys(paxReqFieldsObj).map((paxReqKeys) => {
        if (paxReqFieldsObj[paxReqKeys] === true) {
          paxObj[paxReqKeys] = "";
        }
      });
    });
    return paxObj;
  };

  const fetchHotelPrice = () => {
    const hotelParams = queryString.parse(window.location.search);

    setIds({
      traceId: hotelParams.traceId,
      repriceId: null,
    });

    setLoading(true);
    let Req = {
      ...hotelParams,
      roomPlan: JSON.parse(hotelParams.roomPlan),
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      supplier: hotelParams.supplier,
    };


    setHotelPriceData({});
    setHotelSearchData({});
    setRoomGuestInfo([]);
    ApiClient.post("hotels-v2/hotelprice", Req)
      .then((res) => res)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.errors.length === 0) {
            const { hotels, purchaseType, repriceId, request, traceId } =
              res.data;
            if (
              Object.keys(hotels).length > 0 &&
              Object.keys(request).length > 0
            ) {
              setHotelPriceData({
                ...hotels,
                purchaseType,
                traceId,
                repriceId,
              });
              setHotelSearchData({
                ...request,
                supplier: hotelParams.supplier,
              });
              setHotelCheckOutData({});
              const roomInfoArr = [];

              let roomsInfo = request.roomGuests;


              roomsInfo.map((room, roomIndex) => {
                const paxListArr = [];

                [...Array(room.noOfAdults)].map(() => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mr.",
                    guestType: "Adult",
                    guestInRoom: roomIndex + 1,
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });
                [...Array(room.noOfChilds)].map((_, index) => {
                  let paxObj = {
                    firstName: "",
                    lastName: "",
                    title: "Mstr",
                    guestType: "Child",
                    guestInRoom: roomIndex + 1,
                    age: parseInt(room.childAge[index]),
                  };
                  paxObj = HotelGuestReqFields(
                    paxObj,
                    hotels.guestRequiredFields
                  );
                  paxListArr.push(paxObj);
                });

                roomInfoArr.push({ paxInfoList: paxListArr });
              });

              setRoomGuestInfo(...roomGuestInfo, roomInfoArr);
            }
          } else {
            res.data.errors.forEach((err) => {
              if (err.errorCode === "SOLDOUT") {
                message.error(err.errorDetail, 5);
              }
            });
          }
        } else if (res.status === 500 && res.data?.errors?.length > 0) {
          res.data.errors.forEach((err) => {
            if (err.errorCode === "SOLDOUT") {
              message.error(err.errorDetail, 5);
            }
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const updateTranportType = (e) => {
    if (e.target) {

      setTransportType(e.target.value);
    } else {
      setTransportType(e);
    }
  };

  const updateArrivalType = (e) => {
    if (e.target) {

      setArrivalType(e.target.value);
    } else {
      setArrivalType(e);
    }
  };

  const handleInsuranceChange = (val) => {
    if (val === 1) {
      setSelectedInsuranceData(insuranceData);
    } else {
      setSelectedInsuranceData({ amount: 0, insuranceCoverage: 0, status: 0 });
    }
    setInsuranceRequired(val);
  };

  const redirectToPreview = () => {
    departure_form
      .validateFields()
      .then((departData) => {
        arrival_form
          .validateFields()
          .then((arrivalData) => {
            contact_form
              .validateFields()
              .then((val) => {
                guestDetailsForm
                  .validateFields()
                  .then((passegersData) => {
                    let countryName = "";
                    if (val.countryCode) {
                      countryName = CountryList.filter(
                        (item) => item.code === val.countryCode
                      );
                      countryName = countryName[0].name;
                    }

                    let data = {
                      hotelPriceData,
                      hotelSearchData,
                      addressInfo: {
                        addressLine1: "",
                        addressLine2: "",
                        cellCountryCode: val.areaCode,
                        countryCode: val.countryCode,
                        areaCode: val.areaCode,
                        phoneNo: val.phoneNo,
                        email: val.email,
                        city: "",
                        state: "",
                        country: countryName,
                        zipCode: "",
                      },
                      arrivalTransport: {
                        ArrivalTransportType: arrivalType,
                        TransportInfoId: arrivalType == 0 ? arrivalData.FlightNo : arrivalData.TransportTypeNo,
                        Time: moment(arrivalData.DateTime).format('YYYY-MM-DDTHH:mm:ss')


                      },
                      departureTransport: {
                        DepartureTransportType: transportType,
                        TransportInfoId: transportType == 0 ? departData.FlightNo : departData.TransportTypeNo,
                        Time: moment(departData.DateTime).format('YYYY-MM-DDTHH:mm:ss')
                      },
                      guests: roomGuestInfo,
                      insuranceRequired: passegersData?.insuranceRequired === 1 ? 1 : 0,
                      insuranceData: selectedInsuranceData,
                    };
                    setHotelCheckOutData(data);

                    history.push("/hotels/hotel-review");
                  })
                  .catch((e) => {
                    if (e?.errorFields && e?.errorFields.length > 0)
                      guestDetailsForm.scrollToField(e.errorFields[0].name);
                  });
              })
              .catch((e) => {
                if (!e.errorFields) {
                  return;
                }
                contact_form.scrollToField(e.errorFields[0].name);
              });
          }).catch((e) => {
            if (!e.errorFields) {
              return;
            }
            arrival_form.scrollToField(e.errorFields[0].name);
          });
      }).catch((e) => {
        if (!e.errorFields) {
          return;
        }
        departure_form.scrollToField(e.errorFields[0].name);
      });
  };


  const gotoHotelDetail = (hotelCode) => {
    let queryObj = {
      hotelId: hotelCode,
      traceId: ids.traceId,
      supplier: hotelSearchData.supplier
    };
    const query = queryString.stringify(queryObj);
    history.push(`/hotels/hotel-details?${query}`);
  };
  const timeout = () => {
    return (

      <div>

        <div className="timeout-popup-main">
          <div className="timeout-popup-main1">
            <img className="timeout-img" src={timeoutimg} alt="time" />
          </div>
          <div className="timeout-popup-main2">
            <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
            <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
            <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
          </div>
        </div>

      </div>
    );
  }
  const handelCountdown = () => {

    //const query = queryString.stringify(searchhotelobj);
    let query = queryString.stringify(JSON.parse(localStorage.getItem('HotelSearchBar')));

    Modal.warning({
      icon: <></>,
      //title: "",
      content: timeout(),
      onOk() {
        history.push("/hotels/listing?" + query);

      },
    });
  };

  return (
    <div className="hotel-checkout-wrapper">

      <div className="form-body">
        <div className="lne-5"></div>

        <div className="form-body-container">
        {status != 0 &&
        <div className="countdown3 checkout-countdown1">
            <div className="countdown-main-box">
              <div className="countdown1">
                <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
              </div>
              <div className="countdown2">
                <Countdown
                  className="busCountdown"
                  onFinish={handelCountdown}
                  value={sessiontimeout}
                  format={"mm[ min]  ss[ sec]"}
                />
              </div>
            </div>
          </div>}
          <div className="selection-tab">
            <div className="sel">
              <div className="a1"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div className="b"><h6>Your Selection</h6></div>
            </div>
            <div className="l"></div>
            <div className="sel">
              <div className="a" style={{ color: "white", background: "#0059ff" }}>2</div>
              <div className="b"><h6>Your Details</h6></div>
            </div>
            <div className="l"></div>
            <div className="sel-3">
              <div className="a">3</div>
              <div className="b"><h6>Final Step</h6></div>
            </div>
          </div>
          <Row gutter={16}>
            <Col className="filter-fflight-hop1 normal-and-tabdifff">
              {hotelPriceData?.hotels?.hotelCode && (
                <p
                  className="pointer_cursor"
                  onClick={() => gotoHotelDetail(hotelPriceData?.hotels?.hotelCode)}
                >
                  Change Room
                </p>
              )}
              <div className="hotel-info-wrapper">
                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <HotelDetailsCard
                    Ids={ids}
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                  />
                ) : null}
              </div>

              <div className="hotel-price-wrapper">
                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <HotelFairBox
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                    isPromoVisible={true}
                    location={location}
                  />
                ) : null}
              </div>


            </Col>

            <Col className="result-flight-hop1">

              {loading ? (
                <Card bordered={false} className="hotel-card-wrapper">
                  <Skeleton active />
                </Card>
              ) : Object.keys(hotelPriceData).length > 0 ? (
                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelDetailsBox
                    Ids={ids}
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                  />
                </Card>
              ) : null}
              {loading ? (
                <Card bordered={false} className="hotel-card-wrapper">
                  <Skeleton active />
                </Card>
              ) : Object.keys(hotelPriceData).length > 0 ? (
                <>
                  {hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_instructions ? (
                    <>
                      <Card bordered={false} style={{ background: "#dbaed45e" }} className="hotel-card-wrapper">
                        <div className="good-to">
                          <div className="hdr-1"><span><i class="fa fa-thumbs-up" aria-hidden="true"></i></span>Good to Know:</div>
                          <ul>
                            {hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_instructions?.split('<br />').map((instruction, index) => (
                              <>
                                {instruction != "" || instruction != "</ul>" ? <li key={index}>
                                  <i class="fa fa-info-circle" aria-hidden="true"></i> {" "}
                                  <span>


                                    {ReactHtmlParser(instruction)}
                                  </span>


                                </li> : null
                                }
                              </>
                            ))}

                          </ul>
                        </div>
                      </Card>
                    </>
                  ) : null}
                </>
              ) : null}

              {loading ? (
                <Card bordered={false} className="hotel-card-wrapper">
                  <Skeleton active />
                </Card>
              ) : roomGuestInfo?.length > 0 ? (

                <div className="guest-form-card">
                  <Form
                    form={guestDetailsForm}
                    scrollToFirstError={true}
                    layout="vertical"
                  >

                    {roomGuestInfo.map((roomsObj, roomIndex) => (
                      <Card
                        bordered={false}
                        className="guest-dts-form hotel-card-wrpr"
                        key={roomIndex}
                      >
                        <div className="hdr">
                          <h4>Enter Your Details</h4>

                          <p>Almost done! just fill in the <span className="req-red"> Required{" "} </span> info {" "}
                          <Popover placement="rightTop"  content={"Child Age is Mandatory, Age should be lessthan or equal to 12"}>
                         <InfoCircleOutlined />
                          </Popover></p>


                        </div>

                        <p className="room-title">{hotelPriceData?.combineRoom[0]?.combineRooms[0]?.roomName.split(',')[0]} {1 + roomIndex}</p>

                        {roomsObj.paxInfoList.map((pax, paxIndex) => (
                          <div
                            key={roomIndex + "detials" + paxIndex}
                            className="guest-input-wrapper"
                          >
                            <p className="guestsType">
                              {pax.guestType === "Adult" ? "Adult" : "Child"}{" "}
                            </p>
                            <Row className="input-lbl" gutter={[16, 16]}>
                              <Col md={4} sm={12} xs={24}>
                                {pax.guestType === "Adult" ? (
                                  <Form.Item
                                    name={`Title_${roomIndex}_${paxIndex}`}
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter your Title!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "title"
                                        );
                                      }}
                                    >
                                      <Option value="Mr">Mr</Option>
                                      <Option value="Ms">Ms</Option>
                                      <Option value="Mrs">Mrs</Option>
                                    </Select>
                                  </Form.Item>
                                ) : (
                                  <Form.Item
                                    name={`Title_${roomIndex}_${paxIndex}`}
                                    label="Title"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Enter your Title",
                                      },
                                    ]}
                                  >
                                    <Select
                                      size="large"
                                      onChange={(val) => {
                                        handlePaxField(
                                          val,
                                          roomIndex,
                                          paxIndex,
                                          "title"
                                        );
                                      }}
                                    >
                                      <Option value="Mstr">Mstr</Option>
                                    </Select>
                                  </Form.Item>
                                )}
                              </Col>

                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  label="First Name"
                                  name={`firstname_${roomIndex}_${paxIndex}`}
                                  placeholder="First name & Middle name (if any)"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter your Name!",
                                    },
                                    {
                                      pattern: "^[a-zA-Z ]*$",
                                      message:
                                        "Alphabet Characters Only",
                                    },

                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter First Name"
                                    onChange={(e) => {
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "firstName"
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={10} sm={12} xs={24}>
                                <Form.Item
                                  label="Last Name"
                                  name={`lastname_${roomIndex}_${paxIndex}`}

                                  rules={[
                                    {
                                      required: true,
                                      message: "Please Enter your Last Name!",

                                    },

                                    {
                                      pattern: "^[a-zA-Z ]*$",
                                      message:
                                        "Alphabet Characters Only",
                                    },

                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Surname Name"
                                    onChange={(e) => {
                                      handlePaxField(
                                        e.target.value,
                                        roomIndex,
                                        paxIndex,
                                        "lastName"
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              {pax.guestType === "Child" ? (
                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={`childAge_${roomIndex}_${paxIndex}`}
                                    label="Child Age"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter child age!",
                                      },
                                      {
                                        type: "number",
                                        transform: (value) =>
                                          value >= 17 ? value : undefined,
                                        // Age must be between 0 and 12
                                        message: "Age must be between 2 and 17",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Child's Age"
                                      className="inputbg"
                                      size="large"
                                      onChange={(e) => {
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "age"
                                        );
                                      }}

                                    />

                                  </Form.Item>
                                </Col>
                              ) : (
                                <Col md={4} sm={12} xs={24}>
                                  <Form.Item
                                    name={`adultage_${roomIndex}_${paxIndex}`}
                                    label="Age"
                                    rules={[
                                      {
                                        required: false,
                                        message: "Enter your Age!",
                                      },
                                      {
                                        type: "number",
                                        transform: (value) =>
                                          value >= 2 && value <= 12
                                            ? value
                                            : undefined, // Age must be between 0 and 12
                                        message: "Age must be Older than 12",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Your Age"
                                      className="inputbg"
                                      onChange={(e) => {
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "age"
                                        );
                                      }}
                                      size="large"
                                    />

                                  </Form.Item>
                                </Col>
                              )}
                              {(pax.hasOwnProperty("pan") && pax.guestType != "Child") && (
                                <Col md={10} sm={12} xs={24}>
                                  <Form.Item
                                    label="Pan Number"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter your pan number!",
                                      },
                                      {
                                        pattern: "^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$",
                                        message:
                                          "Please Enter A Valid PAN Number",
                                      },
                                    ]}
                                    name={`pan_${roomIndex}_${paxIndex}`}
                                  >
                                    <Input
                                      size="large"
                                      className="inputbg"
                                      placeholder="PAN Number"
                                      onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                                      onChange={(e) =>
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "pan"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}

                              {pax.hasOwnProperty("passportNo") && (
                                <Col md={10} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport Number"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter your passport number!",
                                      },
                                      {
                                        pattern: "^([a-zA-z]){1}([0-9]){7}?$",
                                        message:
                                          "Please Enter A Valid Passport Number",
                                      },
                                    ]}
                                    name={`passportNo_${roomIndex}_${paxIndex}`}
                                  >
                                    <Input
                                      size="large"
                                      className="inputbg"
                                      placeholder="Passport Number"
                                      onChange={(e) =>
                                        handlePaxField(
                                          e.target.value,
                                          roomIndex,
                                          paxIndex,
                                          "passportNo"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("passportDOI") && (
                                <Col md={12} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport DOI"
                                    className="passport-dates"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportDOI_${roomIndex}_${paxIndex}`}
                                  >
                                    <DatePicker
                                      onChange={(date, dateString) =>
                                        handlePaxField(
                                          dateString,
                                          roomIndex,
                                          paxIndex,
                                          "passportDOI"
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                              {pax.hasOwnProperty("passportDOE") && (
                                <Col md={12} sm={12} xs={24}>
                                  <Form.Item
                                    label="Passport DOE"
                                    className="passport-dates"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required",
                                      },
                                    ]}
                                    name={`passportDOE_${roomIndex}_${paxIndex}`}
                                  >
                                    <DatePicker
                                      onChange={(_, dateString) =>
                                        handlePaxField(
                                          dateString,
                                          roomIndex,
                                          paxIndex,
                                          "passportDOE"
                                        )
                                      }
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </div>
                        ))}
                      </Card>
                    ))}{" "}

                  </Form>
                </div>

              ) : null}


              {hotelPriceData?.description ?
                (<div className="additional-info">
                  <Card bordered={false}
                    className="guest-details-form hotel-card-wrapper">
                    <div className="hdr-info-2">
                      <h6><i class="fa fa-check-circle-o" aria-hidden="true"></i> {" "}Additional info</h6>
                    </div>
                    <ul>
                      {hotelPriceData?.description?.split('.').map((instruction, index) => (
                        <>
                          {instruction != "" ? <li key={index}>{" "}
                            <i class="fa fa-thumbs-o-up" aria-hidden="true"></i>

                            <span>
                              {ReactHtmlParser(instruction)}
                            </span>
                          </li> : null}
                        </>
                      ))}

                    </ul>
                  </Card>
                </div>) : null}

              <div className="cont-infor-card">
                <Card
                  bordered={false}
                  className="guest-details-form hotel-card-wrapper"
                >


                  <Form
                    layout="vertical"
                    name="contactForm"
                    form={contact_form}
                    scrollToFirstError={true}
                    initialValues={{ areaCode: agent ? "+91" : "" }}
                  >
                    <div className="guest-input-wrapper-2">
                      <Row className="inpt-mail" gutter={16}>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                              { required: true, message: "Please Enter your Email!" },
                              { type: "email", message: "Invalid Email" },



                            ]}
                          >
                            <Input placeholder="Enter your email" size="large"
                              suffix={
                                <Tooltip title="Confirmation will sent to this Mail">
                                  <InfoCircleOutlined
                                    style={{
                                      color: 'rgba(0,0,0,.45)',
                                    }}
                                  />
                                </Tooltip>
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Phone number"
                            name="phoneNo"
                            className="phno phoneno-directionn-codes"
                            rules={[
                              {
                                required: true,
                                message: "Enter your phone number!",
                              },

                            ]}
                          >
                            <Input
                              suffix={
                                <Tooltip title="Confirmation will sent to this Number">
                                  <InfoCircleOutlined
                                    style={{
                                      color: 'rgba(0,0,0,.45)',
                                    }}
                                  />
                                </Tooltip>
                              }
                              placeholder="Phone Number"
                              size="large"
                              addonBefore={
                                <Form.Item
                                  name="areaCode"
                                  className="phno"
                                  rules={[
                                    {
                                      required: true,
                                      message: "ISD Code",
                                    },
                                  ]}
                                  noStyle

                                >
                                  <Select
                                    showSearch
                                    placeholder="Select Country ISD"
                                    style={{ width: "138%" }}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {CountryList.map((item) => (
                                      <Option
                                        style={{ width: "280%" }}

                                        key={item.dial_code}
                                        value={item.dial_code}
                                      >
                                        {item.dial_code}
                                      </Option>
                                    ))}
                                  </Select>

                                </Form.Item>
                              }
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            name="countryCode"
                            label="Country"
                            rules={[
                              {
                                required: true,
                                message: "Please Enter country code",
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              showSearch
                              placeholder="Select Country"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option key={item.code} value={item.code}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </Card>
              </div>
              {/* {console.log(hotelPriceData, "hotell")} */}
              {(hotelPriceData?.isDepartureDetailsMandatory ||
                hotelPriceData?.isPackageDetailsMandatory) && (
                  <div className="extra-pax-det">

                    <div className="contact-header">
                      <div className="contact-title">
                        <p className="booking-summary-div"> Package Details</p>
                      </div>
                    </div>
                    <Card
                      bordered={false}
                      className="guest-details-form hotel-card-wrapper"
                    >
                      {hotelPriceData?.isDepartureDetailsMandatory && (
                        <div className="contact-title">
                          <p className="booking-summary-div">  Arrival Details</p>


                          <Radio.Group onChange={updateArrivalType} value={arrivalType} style={{ marginBottom: "3%", }} >
                            <Radio value={0} style={{ fontWeight: 'bold' }} >Arriving By Flight</Radio>
                            <Radio value={1} style={{ fontWeight: 'bold' }} > Arriving By Surface</Radio>
                          </Radio.Group>
                          <Form
                            autoComplete="off"
                            layout="vertical"
                            name="arrivalform"
                            form={arrival_form}
                            scrollToFirstError={true}

                          >
                            <div className="guest-input-wrapper">
                              <Row gutter={16}>
                                {arrivalType == 0 ? (
                                  <Col md={8} sm={12} xs={24}>

                                    <Form.Item
                                      label="Flight No"
                                      name="FlightNo"

                                    >
                                      <Input autoComplete="off" />
                                    </Form.Item>
                                  </Col>) :
                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      label="Transport Type/No"
                                      name="TransportTypeNo"

                                    >
                                      <Input autoComplete="off" />
                                    </Form.Item>
                                  </Col>}


                                <Col md={5} sm={12} xs={24}>
                                  <Form.Item
                                    label="Date:"
                                    name="DateTime"
                                    className="DateTime"
                                  >
                                    <DatePicker />

                                  </Form.Item>
                                </Col>

                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label=" Time:"
                                    name="Time"
                                    className="DateTime"
                                  >
                                    <TimePicker />
                                  </Form.Item>
                                </Col>

                              </Row>
                            </div>
                          </Form>
                        </div>
                      )}
                      {hotelPriceData?.isPackageDetailsMandatory && (
                        <div className="contact-title">
                          <p className="booking-summary-div">  Departure Details</p>

                          <Radio.Group onChange={updateTranportType} value={transportType} style={{ marginBottom: "3%", }} >
                            <Radio value={0} style={{ fontWeight: 'bold' }} > Departing By Flight</Radio>
                            <Radio value={1} style={{ fontWeight: 'bold' }} > Departing By Surface</Radio>
                          </Radio.Group>
                          <Form
                            autoComplete="off"
                            layout="vertical"
                            name="departureform"
                            form={departure_form}
                            scrollToFirstError={true}

                          >
                            <div className="guest-input-wrapper">
                              <div className="contact-title">
                              </div>

                              <Row gutter={16}>
                                {transportType == 0 ? (
                                  <Col md={8} sm={12} xs={24}>

                                    <Form.Item
                                      label="Flight No"
                                      name="FlightNo"

                                    >
                                      <Input autoComplete="off" />
                                    </Form.Item>
                                  </Col>) :
                                  <Col md={8} sm={12} xs={24}>
                                    <Form.Item
                                      label="Transport Type/No"
                                      name="TransportTypeNo"

                                    >
                                      <Input autoComplete="off" />
                                    </Form.Item>
                                  </Col>}

                                <Col md={5} sm={12} xs={24}>
                                  <Form.Item
                                    label="Date:"
                                    name="DateTime"
                                    className="DateTime"
                                  >
                                    <DatePicker />

                                  </Form.Item>
                                </Col>

                                <Col md={8} sm={12} xs={24}>
                                  <Form.Item
                                    label=" Time:"
                                    name="Time"
                                    className="DateTime"
                                  >
                                    <TimePicker />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        </div>
                      )}
                    </Card>
                  </div>
                )}
              {hotelPriceData?.combineRoom?.[0]?.rateComments?.comments ?
                (<div className="additional-info">
                  <Card bordered={false}
                    className="guest-details-form hotel-card-wrapper">
                    <div className="hdr-info-2">
                      <h6><i class="fa fa-check-circle-o" aria-hidden="true"></i> {" "}Important Rules</h6>
                    </div>
                    <ul>

                      {hotelPriceData?.combineRoom?.[0]?.rateComments?.comments?.split('\n').map((instruction, index) => (
                        <>
                          {(instruction != "" || instruction != "</ul>") ? <li key={index}>{" "}
                            <i class="fa fa-info-circle" aria-hidden="true"></i>

                            <span>
                              {ReactHtmlParser(instruction)}
                            </span>
                          </li> : null}
                        </>
                      ))}

                    </ul>
                  </Card>
                </div>) : null}


              <div className="arr-time">
                <Card bordered={false} className="arr-ti">
                  <div className="hdr-3">
                    <h6>Your Arrival Time</h6>
                    {hotelPriceData?.combineRoom?.[0]?.rateComments.checkin_min_age ?
                      <p><span style={{ color: "green" }}><i class="fa fa-address-card" aria-hidden="true"></i></span> For Check-In, Minimum age Should Be {hotelPriceData?.combineRoom?.[0]?.rateComments.checkin_min_age} Year's Old</p> : null}
                    {hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_begin_time ?
                      <p><span style={{ color: "green" }}><i class="fa fa-clock-o" aria-hidden="true"></i></span> Your room will be ready for check-in at {hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_begin_time}</p> : (
                        <p><span style={{ color: "green" }}><i class="fa fa-clock-o" aria-hidden="true"></i></span> Your room will be ready for check-in at {hotelPriceData?.policy?.checkInTime}</p>)}
                    <p><span style={{ color: "green" }}><i class="fa fa-users" aria-hidden="true"></i></span> 24-hour front desk – help whenever you need it!</p>

                  </div>
                </Card>
              </div>


            </Col>
          </Row>
          <Row className="normal-and-tabdifff1">
            <Col className="filter-fflight-hop1">
              {hotelPriceData?.hotels?.hotelCode && (
                <p
                  className="pointer_cursor"
                  onClick={() => gotoHotelDetail(hotelPriceData?.hotels?.hotelCode)}
                >
                  Change Room
                </p>
              )}
              <div className="hotel-info-wrapper">
                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <HotelDetailsCard
                    Ids={ids}
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                  />
                ) : null}
              </div>

              <div className="hotel-price-wrapper">
                {loading ? (
                  <Card bordered={false} className="hotel-card-wrapper">
                    <Skeleton active />
                  </Card>
                ) : Object.keys(hotelPriceData).length > 0 ? (
                  <HotelFairBox
                    hotelDetailsObj={hotelPriceData}
                    hotelSearchData={hotelSearchData}
                    isPromoVisible={true}
                    location={location}
                  />
                ) : null}
              </div>


            </Col>
          </Row>
          <Row className="conntinue-hotel-bookinng" >

            <HotelBookPayCard
              isLoading={loading}
              pgIsLoading={false}
              holdLoading={false}
              purchaseType={false}
              bookpaycardinfo={"hotel-checkout"}
              redirectToPreview={redirectToPreview}
              agent={false}
            />
          </Row>

        </div>
      </div>
    </div >
  );
};

export default HotelCheckout;
