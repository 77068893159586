import {
  BarChartOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Button, Col, Layout, Menu, Row } from "antd";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AdminNavigations from "../common/navbar/AdminNavigations";
import { useSytContext } from "../common/providers/SytProvider";
import ProtectedMenu from "../helpers/ProtectedMenu";
import { SubMenus } from "./adminNavigationMenus";
import "./Adminstyles.scss";

export const checkAllRoutes = (keysArray, accessList) => {
  return keysArray.some((item) => accessList.includes(item));
};

// import SideBarComponent from "./SideBarComponent";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const Administration = ({
  location,
  accessList,
  accessToken,
  resetIsLogin,
}) => {
  const url = "";
  const { logo } = useSytContext();

  const BASE = process.env.REACT_APP_BASE_URL;

  let history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const menuToggle = () => {
    setCollapsed((prev) => !prev);
  };

  const AdminLogout = () => {
    resetIsLogin();
    history.push("/");
  };

  return (
    <div id="admin">
      <Layout>
        <Sider
          trigger={null}
          style={{ minHeight: "100vh" }}
          width={"220px"}
          breakpoint="lg"
          collapsible
          collapsed={!collapsed}
          defaultCollapsed={false}
        >
          <div className="logo">
            <Link to="/">
              {logo ? (
                <img src={BASE + logo.substring(1)} alt="trypmaker-Logo" />
              ) : (
                <img
                  src={require("../assets/images/trypmaker.png").default}
                  alt="trypmaker"
                />
              )}
            </Link>
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={url}>
            <ProtectedMenu
              accessList={accessList}
              key="/admin/dashboard"
              icon={<BarChartOutlined />}
            >
              <Link to="/admin/dashboard">Dashboard</Link>
            </ProtectedMenu>
            {SubMenus.map((menuItem) => {
              if (menuItem.submenukey) {
                if (menuItem.children && menuItem.children.length > 0) {
                  let keysArray = [];

                  if (menuItem.isnestedsubkey === 1) {
                    menuItem.children.forEach((item) => {
                      if (item.children && item.children.length > 0) {
                        item.children.forEach((subItem) => {
                          keysArray.push(subItem.key);
                        });
                      }
                    });
                  } else {
                    keysArray = menuItem.children.map((item) => item.key);
                  }

                  if (!checkAllRoutes(keysArray, accessList)) {
                    return null;
                  }
                }
                return (
                  <SubMenu
                    key={menuItem.submenukey}
                    title={menuItem.title}
                    {...menuItem}
                  >
                    {menuItem.children.map((subMenuItem) => {
                      if (subMenuItem.submenukey) {
                        if (
                          subMenuItem.children &&
                          subMenuItem.children.length > 0
                        ) {
                          if (
                            !checkAllRoutes(
                              subMenuItem.children.map((item) => item.key),
                              accessList
                            )
                          ) {
                            return null;
                          }
                        }
                        return (
                          <SubMenu
                            key={subMenuItem.submenukey}
                            title={subMenuItem.title}
                            {...subMenuItem}
                          >
                            {subMenuItem.children.map((item) => {
                              return (
                                <ProtectedMenu
                                  accessList={accessList}
                                  key={item.key}
                                  {...item}
                                >
                                  <Link to={item.key}>{item.title} </Link>
                                </ProtectedMenu>
                              );
                            })}
                          </SubMenu>
                        );
                      } else
                        return (
                          <ProtectedMenu
                            accessList={accessList}
                            key={subMenuItem.key}
                            {...subMenuItem}
                          >
                            <Link to={subMenuItem.key}>
                              {subMenuItem.title}{" "}
                            </Link>
                          </ProtectedMenu>
                        );
                    })}
                  </SubMenu>
                );
              }
            })}
          </Menu>
        </Sider>

        <Layout>
          <Header className="site-layout-background admin_header">
            {collapsed ? (
              <MenuUnfoldOutlined className="trigger" onClick={menuToggle} />
            ) : (
              <MenuFoldOutlined className="trigger" onClick={menuToggle} />
            )}

            <Button type="primary" onClick={AdminLogout}>
              Logout
            </Button>
          </Header>
          <Content>
            <AdminNavigations
              accessList={accessList}
              accessToken={accessToken}
            />
          </Content>
          <Footer className="footer">
            <Row>
              <Col span={24}>
                <p style={{ textAlign: "center", margin: 0 }}>
                  All Right Resereved @ trypmaker
                </p>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default Administration;
