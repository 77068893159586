
import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImagesLightbox.scss";

const ImagesLightbox = ({ hotelImages }) => {
  const [images, setimages] = useState(hotelImages);
  const onImageError = (ui) => {
    setimages(images?.filter(e => e != ui))
  }
 // console.log(images,images.length,"image")
  return (
    <>
      <div className="carousel-wrapper">
        {images?.length === 1 ? (
          <Carousel
            showStatus={false}
            showIndicators={false}
            dynamicHeight={false}
          >
            {images?.map((hotelImage, i) => (
              <div key={i + "hotelimg"}>
                <img src={hotelImage} alt="image" className="carousel-images-1" />
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            showStatus={false}
            showIndicators={false}
            dynamicHeight={false}
            centerMode={true}
            centerSlidePercentage={80}
            selectedItem={1}
          >
            {images?.filter(hotelImage => (hotelImage?.includes("max500") || hotelImage?.includes("media") || hotelImage?.includes("_P") || hotelImage?.includes("_z") || hotelImage?.includes("grnconnect")))
              .map((filteredImage, i) => (
                <div key={"hotel" + i}>
                  <img
                    src={filteredImage}
                    alt="hotel"
                    className="carousel-images-1"
                    onError={() => onImageError(filteredImage)}
                  />
                </div>
              ))}
          </Carousel>
        )}
      </div>
    </>
  );
};
export default ImagesLightbox;
