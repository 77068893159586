import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSytContext } from "../../../common/providers/SytProvider";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
import moment from "moment";
import './tophotels.scss';
import CustomImg from "../../../common/CustomImg";
// import CarouselTop from "../../../components/HomeCarousel/CityCarousel";
import Slider from "react-slick";
import hotelImg from "../../../assets/images/hotels/hotel-4045655_640.jpg";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";

const MobileSlidersettings = {
    arrows: true,
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                arrows: false,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                arrows: false,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
    appendDots: dots => (
        <div className="slick-top">
           
            {dots.slice(0, 4)}
        </div>
    ),
};
const TopHotels = (props) => {

    const BASE = process.env.REACT_APP_BASE_URL;
    const [cityHotelData, setCityHotelData] = useState([]);
    const [cityHotel, setCityHotel] = useState([]);
    const [dataSource, setDataSource] = useState([]);

    const [recentSearchResults, setRecentSearchResults] = useState([]);
    const { activeCurrency, currencyValue } = useCurrencyContext();
    const { promoDataSource } = useSytContext();
    let tomorrowDate = moment().add(1, 'days').format("YYYY-MM-DD");
    let dayafter = moment().add(2, "days").format("YYYY-MM-DD");
    const getCityHotel = () => {
        ApiClient.get("admin/dynamic/hotel")
            .then((res) => {
                if (res.status === 200) {
                    // console.log(res, "resss");
                    let data = res?.data?.map((item) => {
                        return (
                            item?.result
                        );
                    }
                    );

                    setCityHotel(data);
                    // console.log(data, "daaaa");
                }
            })
            .catch((e) => console.log("api error", e));
    };
   



    useEffect(() => {
        // getCityHotelList();
        getCityHotel();
        // if (promoDataSource.length) {
        //     let data = promoDataSource.filter((item) => item.ServiceType === 2);

        //     setDataSource(data);
        // }
    }, [promoDataSource]);

    useEffect(() => {
        getRecentSearches();
    }, []);
    const getRecentSearches = () => {
        let data = sessionStorage?.getItem("hotelRecentSearchesResults");

        if (data?.length) {
            setRecentSearchResults(JSON.parse(data));
        }
    };
    const StarRating = ({ rating }) => {
        const numStars = parseFloat(rating);
        const starsArray = Array.from({ length: numStars }, (_, index) => index);

        return (
            <div className="str-top-ht">
                {starsArray?.map((_, index) => (
                    <span
                        key={index}
                        role="img"
                        aria-label="star"
                        style={{
                            textShadow: "3px 2px 6px grey", // Applying drop shadow
                            marginRight: "1px" // Adding margin between stars (optional)
                        }}
                    >
                        ⭐
                    </span>
                ))}
            </div>
        );
    };


    const getHotelSer = (url) => {
        // console.log(url, "url");
        let formData = {
            checkInDate: tomorrowDate,
            checkOutDate: dayafter,
            hotelCityCode: url?.hotelCityCode,
            hotelId: url?.hotelId,
            roomGuests: JSON.stringify([{
                noOfAdults: 1,
                noOfChilds: 0,
                childAge: [],
            },]),
            nationality: "IN",
            currency: "INR",
            countryCode: "IN",
            traceId: "string",
        };

        const query = queryString.stringify(formData);

        return "/hotels/listing?" + query;
    };

    return (
        <div className="city-top-resp" >
            <h5 className="topci_heading">Top Hotels</h5>
            {/* <CarouselTop deal={cityHotel} /> */}

            {cityHotel ? cityHotel?.map((item, index) => (
                <div className="cont-top-hotel">
                    <h6 className="cit-n">{item?.[index]?.city}</h6>
                    <Slider {...MobileSlidersettings}>
                        {item?.map((city, i) => (
                            <div key={i} className="slick-slide-item">
                                {/* {console.log(item, cityHotel, "iiiii")} */}


                                <div className="main-head-img-1">
                                    <Link
                                        to={getHotelSer(city)}
                                    // className="main-head-img"
                                    >{city?.Images?.length > 1 ?
                                        <CustomImg
                                            className="top-hotel-imag-lik"
                                            src={city?.Images?.[1]}
                                            alt='Hotel'
                                        />
                                        :
                                        <img
                                            className="top-hotel-imag-lik"
                                            src={hotelImg}
                                            alt="Hotel Image" />}
                                        <div className="hotel-info-card">
                                            <div className="property-nm">
                                                <p className="pr-name">{city?.propertyName}</p>
                                                <StarRating rating={city?.starRating} />
                                            </div>
                                            <div className="add-prop">
                                                <p className="add-prop-1">{city?.addresses?.address}</p>
                                            </div>
                                        </div>
                                       
                                    </Link>
                                </div>


                            </div>
                        ))}
                    </Slider>
                </div>
            )) : ""}

        </div >
    );
};
{/* <Link
to={getHotelSer(item)}
className="main-head-img"
>
<img
    className="top-hotel-imag-lik"
    src={
        item.HotelImage1
            ? `${BASE}${item.HotelImage1.substring(1)}`
            : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                .default
    }
    alt="Hotel Image"
/>
</Link> */}
export default TopHotels;
