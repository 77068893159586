import React from "react";
import { Document, Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";

import LogoImg from "../../../assets/images/trypmaker.png";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import bg from "../../../assets/images/bannner-fn-6.jpg";



const csssheet = {
  body: {
    margin: 0,
    fontSize: 11,
  },
  table: {
    borderCollapse: "collapse",
    border: "1px solid #000",
    padding: 2,
  },

  p: {
    margin: 0,
    fontSize: 11,
  },
};
const styles = StyleSheet.create({
  textDanger: {
    color: "#dc3545",
  },

  mb: {
    marginBottom: 10,
  },

  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  whif: {
    flex: 1,
    marginBottom: 10,
  },

  wHalf: {
    flex: 0.5,
    marginBottom: 10,
  },
  tw: {
    flex: 0.2,
  },
  fourthHalf: {
    flex: 0.25,
    paddingRight: 5,
  },

  width32: {
    flex: 0.32,
  },
  trw: {
    flex: 0.3,
  },
  fw: {
    flex: 0.4,
  },
  sw: {
    flex: 0.6,
  },
  sew: {
    flex: 0.7,
  },

  mb8: {
    marginBottom: 8,
  },
  description: {
    paddingLeft: 5,
  },

  hr: {
    borderBottom: "1px solid #e2e2e2",
    flex: 1,
    margin: "10px 0",
  },

  page: {
    flexDirection: "row",
    padding: "15px 10px",
  },
  section: {
    flex: 1,
  },
  innerSection: {
    flex: 1,
    position: "relative",
  },
  pageBg: {
    position: "absolute",
    flex: 1,
    width: "100%",
    height: 100,
  },
  busWrapper: {
    padding: "25px 10px 10px 10px",
  },

  whiteText: { color: "#fff", fontSize: 12, marginBottom: 10, fontWeight: 600 },
  blackText: { color: "#000", fontSize: 12, marginBottom: 10, fontWeight: 600 },
  title: { fontSize: 12, fontWeight: 600, wordBreak: "break-word" },
  fadetitle: { color: "#a8a8a8", fontSize: 9 },
  smallText: { fontSize: 10 },
  xsmallText: {
    fontSize: 9,
    marginBottom: 3,
  },
  greytext: { color: "#666", fontSize: 9 },
  card: {
    padding: "10px 5px",
    marginBottom: 10,
    border: "1px solid #e2e2e2",
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
  },

  insuranceCard: {
    border: "1px solid #02cb66",
    borderRadius: 6,
    textAlign: "center",
    padding: "10px 5px",
  },

  insurancetitle: {
    color: "#18a160",
    fontSize: 10,
  },

  dottedBorder: {
    borderTop: "1px dotted #a8a8a8",
    width: "100%",
  },

  coverage: {
    color: "#294782",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px 0",
  },

  coveragetitle: {
    color: "#294782",
    fontSize: 9,
  },
});

export const getHolidayTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <Text style={{ color: "#f9e218" }}>CREATED</Text>;

    case 2:
      return <Text style={{ color: "#FFA500" }}>BLOCKED</Text>;

    case 3:
      return <Text style={{ color: "#008000" }}>CONFIRMED</Text>;

    case 4:
      return <Text style={{ color: "#FF0000" }}>CANCELLED</Text>;

    case 5:
      return <Text style={{ color: "#008000" }}>PARTIALLY CANCELLED</Text>;

    case 6:
      return <Text style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</Text>;

    default:
      return "";
  }
};

const parser = (str) => {
  if (str) {
    let doc = new DOMParser().parseFromString(str, "text/html");
    return doc.firstChild.innerHTML;
  }
};


const HolidayPackageCheckoutDoc = ({
  buildPackage,
  showPrice = true,
  price,
  pax,
  Logo,
}) => {
  const BASE = process.env.REACT_APP_BASE_URL;
  let uidateFormat = "DD-MMM-YYYY";
  const packageOverview = buildPackage?.PackageOverview;

  if (!packageOverview) {
    return null;
  }
  const contentParts = packageOverview.split(/(?=Day)/);
  // const packageOverview = parseHTMLString(buildPackage?.PackageOverview || "");
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>

        <View style={styles.section}>
          {Logo ? (
            <Image
              src={BASE + Logo.substring(1)}
              style={{ height: 50, width: 100, marginBottom: 10 }}
            ></Image>
          ) : (
            <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
          )}
          <View style={styles.innerSection}>
            <Image src={bg} style={styles.pageBg} />
            <View style={styles.busWrapper}>
              <View style={{ ...styles.card, marginTop: "60px" }} wrap={false}>
                {/* <View style={styles.row}>
                    <View style={styles.fw}> */}
                <View style={styles.row}>
                  <View style={styles.fw}>
                    <Text style={{ ...styles.title, color: "#054789" }}>{buildPackage?.TourName}</Text>

                  </View>

                </View>

              </View>
              <View style={styles.hr}></View>

              <View style={styles.row}>
                <View style={styles.fourthHalf}>
                  <Text style={styles.title}>
                    {buildPackage?.ReferenceNo}
                  </Text>
                  <Text style={styles.fadetitle}>Ticket Ref Number</Text>
                </View>
                <View style={styles.fourthHalf}>
                  <Text style={styles.title}>
                    {getHolidayTicketStatus(buildPackage.BookingStatus)}
                  </Text>
                  <Text style={styles.fadetitle}>Status</Text>
                </View>
                <View style={styles.fourthHalf}>
                  <Text style={styles.title}>
                    {buildPackage.TravellingDate}
                    {/* ? moment(buildPackage.TravellingDate).format(uidateFormat)
                        : ""} */}
                  </Text>
                  <Text style={styles.fadetitle}>Booking Date</Text>
                </View>

              </View>
            </View>

            {/* Contact Details */}

            <View style={styles.card} wrap={false}>
              <View style={styles.mb}>
                <Text style={styles.title}>Contact Details</Text>
                <Text style={styles.fadetitle}>
                  Any Communication by trypmaker, will be sent to these details
                </Text>
              </View>

              <View style={styles.row}>
                <View style={styles.wHalf}>
                  <Text style={styles.fadetitle}>Name</Text>
                  <Text style={styles.smallText}>
                    {buildPackage?.Name}
                  </Text>
                </View>

                <View style={styles.wHalf}>
                  <Text style={styles.fadetitle}>Mobile Number</Text>
                  <Text style={styles.smallText}>
                    {buildPackage?.PhoneNo}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <View style={styles.wHalf}>
                  <Text style={styles.fadetitle}>Email</Text>
                  <Text style={styles.smallText}>
                    {buildPackage.Email}
                  </Text>
                </View>

                <View style={styles.wHalf}>
                  <Text style={styles.fadetitle}>Travelling On</Text>

                  <Text style={styles.smallText}>
                    {buildPackage?.ArrivalDate}

                  </Text>

                </View>
              </View>
            </View>






            <View>
              <View style={styles.card} wrap={false}>
                <View style={styles.mb}>
                  <Text style={styles.title}>Fare Details</Text>
                </View>

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Base Fare</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {buildPackage?.Price}</Text>
                  </View>
                </View>


                {buildPackage?.Conveniencefee ? (
                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Convenience Fee</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>
                        INR {buildPackage?.Conveniencefee}
                      </Text>
                    </View>
                  </View>) : null}


                {buildPackage?.Discount > 0 ? (
                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Discount</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>INR {buildPackage?.Discount}</Text>
                    </View>
                  </View>) : null}

                <View style={{ ...styles.row, ...styles.mb8 }}>
                  <View style={styles.sw}>
                    <Text style={styles.smallText}>Total</Text>
                  </View>

                  <View style={styles.fourthHalf}>
                    <Text style={styles.smallText}>INR {buildPackage?.TotalPrice}</Text>
                  </View>
                </View>

                {buildPackage.BookingStatus != 3 ? (
                  <View style={{ ...styles.row, ...styles.mb8 }}>
                    <View style={styles.sw}>
                      <Text style={styles.smallText}>Refund Amount</Text>
                    </View>

                    <View style={styles.fourthHalf}>
                      <Text style={styles.smallText}>
                        INR {buildPackage?.RefundAmount}
                      </Text>
                    </View>
                  </View>
                ) : null}
              </View>

            </View>





          </View>
          <View style={styles.card} wrap={false}>
            <View style={styles.mb}>
              <Text style={styles.title}>Package Overview</Text>
              <Text style={styles.fadetitle}>
                Any changes, may happen! we will Inform Before Prior Notice.
              </Text>
            </View>


            <View style={styles.row}>
              <View style={styles.whif}>
                {contentParts.map((part, index) => (
                  <Text key={index} style={styles.smallText}>
                    <pre>{ReactHtmlParser(part)}</pre>
                  </Text>
                ))}

              </View>
            </View>
          </View>
        </View>


      </Page >
    </Document >
  );
};

export default HolidayPackageCheckoutDoc;
