import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Collapse,
  message,
  Radio,
  Modal,
  Popconfirm,
  Switch,
  DatePicker,
  Image,
  Popover,
} from "antd";
import moment from "moment";
import excel from "../../assets/vector-icons/excel.png";
import exchange from "../../assets/vector-icons/exchange.png";
import search from "../../assets/vector-icons/search.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import {
  CloseOutlined,
  CaretRightOutlined,
  PlusCircleFilled,
  EyeOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Panel } = Collapse;
let dateFormat = "DD-MM-YYYY";
const BASE = process.env.REACT_APP_BASE_URL;
const ReviewRating = (props) => {
  const [AddForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [activePanel, setActivePanel] = useState(["1"]);
  const [userReviewList, setUserReviewlist] = useState([]);
  const [userReviewTableList, setUserReviewTableList] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [couponDataSource, setCouponDataSource] = useState([]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [id, setId] = useState(-1);
  const [reviewRequestModal, setReviewRequestModal] = useState(false);
  const [reviewRequest, setReviewRequest] = useState("");
  const [imageRequestModal, setImageRequestModal] = useState(false);
  const [imageRequest, setImageRequest] = useState([]);
  const [searchRequest, setSearchRequest] = useState({});

  useEffect(() => {
    getCoupons();
  }, []);

  // api call to get all avaialble promo Codes
  const getCoupons = () => {
    ApiClient.get("admin/GetAllCoupons")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              couponType: getCouponsType(item.couponType),
            };
          });
          setCouponDataSource(data);
          setCouponData(data);
          // defineExcelData(data);
        } else {
          setCouponDataSource([]);
          setCouponData([]);
        }
      })
      .catch((e) => {
        setCouponDataSource([]);
        setCouponData([]);
      });
  };

  // Delete specific promo Codes
  const deleteCoupons = (couponId, id) => {
    ApiClient.delete("admin/DeleteCoupons/" + couponId)
      .then((res) => {
        if (res.status == 200) {
          message.success("Coupon Deleted  successfully", 3);
          getCoupons();
        } else {
          message.error(res.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const deleteUserReview = (id) => {
    if (id != undefined) {
      ApiClient.delete("UserReviews/userReview/" + id).then((res) => {
        if (res.status == 200) {
          message.success("Review Deleted  successfully", 3);
          searchUserReview(searchRequest);
        } else {
          message.error(res.message, 3);
        }
      });
    }
  };
  const getCouponsType = (num) => {
    if (num == 0) {
      return "Fixed";
    } else if (num == 1) {
      return "Percentage";
    } else if (num == "Percentage") {
      return 1;
    } else if (num == "Fixed") {
      return 2;
    } else {
      return "Invalid";
    }
  };
  /* Creating service dynamic column headers */

  const submitFormData = (formvalues) => {
    const data = {
      couponsFor: 3,
      serviceType: formvalues.serviceType,
      businessType: 2,
      couponType: formvalues.couponType ?? 0,
      couponValue: formvalues.couponValue,
      status: 0,
      createdBy: 1,
      modifiedBy: 1,
      DeviceOS: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ApplicationType: 0,
      createdDate: new Date(),
      modifiedDate: new Date(),
      couponName: "",
      couponDiscount: 0,
      couponLimit: 0,
      image: "",
      couponTermsandcondition: "",
      validFrom: "",
      validTill: "",
      reviewType: formvalues.reviewType,
    };
    if (isEditMode) {
      updateCoupons(data, id);
    } else {
      addCoupons(data);
    }
  };
  const addCoupons = (data) => {
    ApiClient.post("admin/Coupons", data).then((res) => {
      if (res.status == 200) {
        message.success("Coupon added successfully", 3);
        setModalVisible(false);
        AddForm.resetFields();
        getCoupons();
      } else if (res.status == 400) {
        message.error(" Duplicate Coupon", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const defineExcelData = () => {
    let copyData = couponData;
    const excelData = copyData.map((item) => {
      const obj = {
        Service_Type: item.serviceName,
        Business_Type: item.businessName,
        Discount_Type: item.couponType,
        Amount: item.couponValue,
      };
      return obj;
    });
    props.exportExcel(excelData, "Rating");
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const changeStatus = (info, status) => {
    const id = info.couponId;
    delete info.SNo;
    delete info.businessName;
    delete info.serviceName;
    let obj = {
      ...info,
      status: status,
      couponType: info.couponType == "Fixed" ? 0 : 1,
    };
    updateCoupons(obj, id);
  };
  const updateCoupons = (data, id) => {
    ApiClient.put("admin/updateCoupons/" + id, {}, data).then((res) => {
      if (res.status == 200) {
        message.success("Coupan Updated  successfully", 3);
        setModalVisible(false);
        //setFormData(initialFormData);
        getCoupons();
      } else if (res.status == 400) {
        message.error(" Duplicate Coupan Code", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const changeUserReviewStatus = (data, Status) => {
    ApiClient.put(
      "UserReviews/updateUserReviewStatus/" + data.ID,
      {},
      {
        Status,
      }
    ).then((res) => {
      if (res.status == 200) {
        message.success("User Reviews Updated  successfully", 3);
        searchUserReview(searchRequest);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const searchViewTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = couponDataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCouponData(searchResults);
    } else {
      setCouponData(couponDataSource);
    }
  };
  const searchUserTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = userReviewTableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setUserReviewTableList(searchResults);
    } else {
      setUserReviewTableList(userReviewTableList);
    }
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const getServiceType = (key) => {
    switch (key) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      case 3:
        return "Bus";
      case 4:
        return "Activities";
      case 5:
        return "Build Package";
      case 6:
        return "All";
      default:
        return;
    }
  };
  const CouponsColumns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },

    {
      title: "Service Type",
      dataIndex: "serviceName",
      sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
    },
    // {
    //   title: "Business Type",
    //   dataIndex: "businessName",
    //   render: (businessName) =>
    //     businessName === "Agent" ? "Partner" : businessName,
    // },

    {
      title: "Coupons Type",
      dataIndex: "couponType",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },
    {
      title: "Review Type",
      dataIndex: "reviewType",
      render: (reviewType) =>
        reviewType === 0
          ? "All"
          : reviewType === 1
          ? "Image"
          : reviewType === 2
          ? "Review"
          : null,
    },
    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const userReviewColumns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },

    {
      title: "Reference No",
      dataIndex: "RefNo",
      sorter: (a, b) => a.RefNo.localeCompare(b.RefNo),
    },
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      render: (ServiceType) => getServiceType(ServiceType),
    },

    {
      title: "Star Rating",
      dataIndex: "StartRating",
    },

    {
      title: "Review",
      dataIndex: "Comments",
      render: (text, rec) => {
        return text ? ReviewRequest(rec) : "";
      },
    },
    {
      title: "Photos",
      render: (_, rec) => {
        return rec.ReviewImages.length > 0 ? ImageRequest(rec) : null;
      },
    },
    {
      title: "Email",
      dataIndex: "EmailId",
    },
    {
      title: "Phone Number",
      dataIndex: "PhoneNumber",
    },
    {
      title: "Approved",
      key: "category",
      render: (currentItem) => handelUserReviewStatus(currentItem),
    },
    {
      title: "Phone Number",
      dataIndex: "PhoneNumber",
    },

    {
      title: "nxt Trvl Des",
      dataIndex: "nextTravelDestination",
    },
    {
      title: "nxt Trvl Date",
      dataIndex: "nextTravelDate",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : ""),
    },
    {
      title: "Actions",
      render: (currentItem) => tableUserReviewActions(currentItem),
    },
  ];
  const ReviewRequest = (rec) => {
    return (
      <div className="text-center">
        <span
          style={{ cursor: "pointer", color: "red" }}
          onClick={(e) => {
            setReviewRequest(rec.Comments);
            setReviewRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };
  const ImageRequest = (rec) => {
    return (
      <div className="text-center">
        <span
          style={{ cursor: "pointer", color: "green" }}
          onClick={(e) => {
            setImageRequest(rec.ReviewImages);
            setImageRequestModal(true);
          }}
        >
          <EyeOutlined />
        </span>
      </div>
    );
  };

  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updateCouponModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deleteCoupons(rec?.couponId, rec?.Id)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };
  const tableUserReviewActions = (rec) => {
    return (
      <div className="text-center">
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deleteUserReview(rec?.ID)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };
  const handelUserReviewStatus = (data) => (
    <Switch
      checkedChildren="Yes"
      unCheckedChildren="No"
      checked={data.Status == 0 ? false : true}
      onChange={() => changeUserReviewStatus(data, data.Status == 0 ? 1 : 0)}
    />
  );
  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };
  const updateCouponModel = (rec) => {
    setId(rec.couponId);

    // setFormData({
    //   ...formData,
    //   Image: rec.image ? rec.image.slice(28) : "",
    // });

    AddForm.setFieldsValue({
      // businessType: rec.businessType,
      couponType: rec.couponType == "Fixed" ? 0 : 1,
      couponValue: rec.couponValue,
      serviceType: rec?.serviceType,
      reviewType: rec?.reviewType,
    });

    setIsEditMode(true);
    showModal();
  };

  const searchUserReview = (values) => {
    setUserReviewlist([]);
    setSearchRequest(values);
    ApiClient.get(`UserReviews/userReviews/report`, values)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setUserReviewlist(data);
          setUserReviewTableList(data);
        } else {
          setUserReviewlist([]);
          setUserReviewTableList([]);
        }
      })
      .catch((error) => {
        setUserReviewlist([]);
        setUserReviewTableList([]);
      });
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    return lastIndex++;
  };

  const addNewCoupons = () => {
    setIsEditMode(false);
    AddForm.resetFields();

    showModal();
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const disabledPastDate = (currentDate) => {
    return currentDate <= moment();
  };

  const AddReviewRatingHeader = () => (
    <>
      Add Review & Rating/Photos{" "}
      <HelpInfoHelper screenName={"/admin/reviews-rating"} />
    </>
  );

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                <CaretRightOutlined rotate={isActive ? 90 : -90} />
              </Tooltip>
            )}
            expandIconPosition="right"
            className="panel_wrapper"
            defaultActiveKey={["1"]}
            activeKey={activePanel}
            onChange={(arrKeys) => {
              if (arrKeys.length > 1) {
                setActivePanel(arrKeys[arrKeys.length - 1]);
              } else {
                setActivePanel(arrKeys);
              }
            }}
          >
            <Panel header={<AddReviewRatingHeader />} key="1">
              <Card bordered={false}>
                <div className="card-add-heading">
                  <div className="rows-count">
                    <div>
                      <h5>View Records {couponDataSource.length}</h5>
                    </div>
                  </div>
                  <div className="action-images">
                    {showSearchBox && (
                      <Input
                        placeholder="Search"
                        onChange={(e) => searchViewTableData(e)}
                        suffix={closeSearchInput}
                        style={{ padding: "0px 12px" }}
                      />
                    )}
                    &nbsp;&nbsp;
                    <img
                      src={search}
                      alt="search"
                      onClick={(e) => searchData()}
                    />
                    <img src={excel} alt="excel" onClick={defineExcelData} />
                    <img
                      src={exchange}
                      alt="exchange"
                      onClick={() => getCoupons()}
                    />
                    <p
                      className="add-deposit-icon"
                      onClick={() => {
                        addNewCoupons();
                      }}
                    >
                      <PlusCircleFilled />
                    </p>
                  </div>
                </div>
                {couponData.length > 0 ? (
                  <Table
                    className="table-scroll-none"
                    bordered
                    dataSource={couponData}
                    columns={CouponsColumns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                ) : (
                  <b>No record Found</b>
                )}
              </Card>
            </Panel>
            <Panel header="View User Review & Rating/Photos" key="2">
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <Form
                    layout="vertical"
                    onFinish={searchUserReview}
                    initialValues={{
                      services: "",
                      emailId: "",
                      fromDate: "",
                      toDate: "",
                      phoneNumber: "",
                      referenceNo: "",
                    }}
                  >
                    <Row gutter={(12, 12)} align="middle">
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item
                          label="Service Type"
                          name="services"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select placeholder="Please Select">
                            <Option value={1}>Flights</Option>
                            <Option value={2}>Hotels</Option>
                            <Option value={3}>Buses</Option>
                            <Option value={4}>Activities</Option>
                            <Option value={5}>Build Package</Option>
                            <Option value={6}>All</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Reference No." name="referenceNo">
                          <Input placeholder="Enter Reference No." />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item
                          label="Email"
                          name="emailId"
                          rules={[
                            {
                              type: "email",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Email" />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="From Date" name="fromDate">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Select From Date"
                            format={dateFormat}
                            disabledDate={disabledPastDate}
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="To Date" name="toDate">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Select To Date"
                            format={dateFormat}
                            disabledDate={disabledPastDate}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={6} sm={12} xs={24}>
                        <Form.Item
                          name="phoneNumber"
                          label="Mobile Number"
                          rules={[
                            {
                              minLength: 10,
                              maxLength: 10,
                              pattern: "^[0-9]{10}$",
                              message: "Must be 10 digits",
                            },
                          ]}
                        >
                          <Input style={{ width: "100%" }} />
                        </Form.Item>
                      </Col>

                      <Col md={6} xs={24}>
                        <Button key="add" type="primary" htmlType="submit">
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <div className="card-bt-gap">
                    {userReviewTableList.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count d-block">
                            <h5>View User Reviews</h5>
                            <p>{userReviewTableList.length} rows found !</p>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                autocomplete="newpassword"
                                placeholder="Search"
                                onChange={(e) => searchUserTableData(e)}
                                suffix={closeSearchInput}
                                style={{
                                  padding: "0px 12px",
                                  maxWidth: "250px",
                                }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={() => searchData()}
                            />
                            {/* <img
                              src={excel}
                              alt="excel"
                              onClick={() => handleExcel()}
                            /> */}
                          </div>
                        </div>

                        <Table
                          scroll={{ x: true }}
                          bordered
                          rowKey={uniqueKey}
                          dataSource={userReviewTableList}
                          columns={userReviewColumns}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                        />
                      </>
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </div>
                </Card>
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
      <Modal
        title={isEditMode ? "Update Coupons" : "Add Coupons"}
        className="promo-modal-header modal-header-bg12"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <>
            <Button type="primary" onClick={() => AddForm.submit()}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              danger
              className="cancel-btn"
              onClick={(e) => {
                AddForm.resetFields();
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </>,
        ]}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          form={AddForm}
          onFinish={submitFormData}
          initialValues={{ couponType: 0 }}
        >
          <Row gutter={16}>
            <Col md={8} xs={24}>
              <Form.Item
                label="Service"
                name="serviceType"
                rules={[{ required: true, message: "Service is required" }]}
              >
                <Select placeholder="Select Service Type">
                  <Select.Option value={1}>Flights</Select.Option>
                  <Select.Option value={2}>Hotels</Select.Option>
                  <Select.Option value={3}>Bus</Select.Option>
                  <Select.Option value={4}>Activities</Select.Option>
                  <Select.Option value={5}>BuildPackage</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {/* <Col md={8} xs={24}>
              <Form.Item
                label="Business Type"
                name="businessType"
                rules={[
                  { required: true, message: "Business Type is required" },
                ]}
              >
                <Select placeholder="Select Business Type">
                  <Select.Option value={5}>Partner</Select.Option>
                  <Select.Option value={2}>User</Select.Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col md={8} xs={24}>
              <Form.Item
                label="Review Type"
                name="reviewType"
                rules={[{ required: true, message: "Review Type is required" }]}
              >
                <Select placeholder="Select Review Type">
                  <Select.Option value={0}>All</Select.Option>
                  <Select.Option value={1}>Photos</Select.Option>
                  <Select.Option value={2}>Reviews</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Coupon Type"
                name="couponType"
                rules={[{ required: true, message: "Coupon Type is required" }]}
              >
                <Radio.Group defaultValue={1}>
                  <Radio value={1}>Percentage</Radio>
                  <Radio value={0}>Fixed</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Coupon Value"
                name="couponValue"
                rules={[
                  { required: true, message: " Coupon Value is required" },
                ]}
              >
                <Input placeholder="Add Coupon Value" type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        visible={reviewRequestModal}
        title="Review"
        onCancel={(e) => {
          setReviewRequestModal(false);
        }}
        footer={false}
      >
        <div>
          <p>{reviewRequest} </p>
        </div>
      </Modal>
      <Modal
        visible={imageRequestModal}
        title="Photos"
        onCancel={(e) => {
          setImageRequestModal(false);
        }}
        footer={false}
      >
        <Row gutter={12}>
          {imageRequest.map((image, index) => (
            <Col md={6} sm={8} xs={12}>
              <Image src={BASE + image.ImagePath.substring(1)} />
            </Col>
          ))}
        </Row>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(ReviewRating);
