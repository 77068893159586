import {
  CaretDownOutlined,
  RightOutlined,
  ShoppingCartOutlined,
  SyncOutlined,
  UserOutlined,
} from "@ant-design/icons";
import googlelogo from '../../assets/images/google-logo.png'
import { Dropdown, Menu, Modal } from "antd";
import Drawersearch from "./Drawersearch";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { Link, NavLink, useHistory, withRouter } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import Login from "../../components/Login/Login";
import APIClient from "../../helpers/ApiClient";
import "../navbar/Navbar.scss";
import FlagsList from "./FlagsList";
import { Input, Space } from 'antd';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Google from "../../common/googleReviews";

const { SubMenu } = Menu;
const Nav = ({ location, props }) => {
  // console.log(location, props, "loc");
  const [navclass, setNavclass] = useState("nav-transparent-class");
  const [isHomePage, setIsHomePage] = useState(true)
  const [y, setY] = useState(window.scrollY);
  const [index, setIndex] = useState(0);
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });
  const {
    isLogin: { flag, agent, isGuest },
    user,
    setUser,
    resetIsLogin,
  } = useAuthContext();

  let services = user?.Services ? user?.Services : [];

  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (isHomePage && y > 30) {
        setNavclass("header-container")
      }
      else if (isHomePage && y < 50) {
        setNavclass("nav-transparent-class")
      }
      else if (!isHomePage) {
        setNavclass("header-container-relative")
      }
      setY(window.scrollY);
    }, [y]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    const activepath = location?.pathname ?? ""
    if ((activepath !== '/hotels' && activepath !== '/Holiday' && activepath !== '/') || agent) {
      setNavclass("header-container-relative")
      setIsHomePage(false)
    } else {
      setIsHomePage(true)
      setNavclass("nav-transparent-class")
    }

  }, [location?.pathname])


  const {
    logo,
  } = useSytContext();
  const BASE = process.env.REACT_APP_BASE_URL;


  let history = useHistory();
  const [fetchingWallet, setFetchingWallet] = useState(false);
  const [navToggle, setNavToggle] = useState(false);

  const logout = () => {
    resetIsLogin();
    history.push("/");
  };

  const toggleCount = () => {
    setNavToggle((prev) => !prev);
  };


  const mainicon = (
    <Menu>
      <Menu.Item key="/flight">
        <Link to="/">Flight</Link>
      </Menu.Item>
    </Menu>
  );

  const getwalletBalance = () => {
    if ((agent /*|| flag*/)) {
      setFetchingWallet(true);
      APIClient.get(`admin/GetAgentWalletDetails/${user.UserID}`)
        .then((resp) => {
          if (resp.status == 200) {
            setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
          }
          setFetchingWallet(false);
        })
        .catch((error) => {
          setFetchingWallet(false);
        });
    }
    if (user && flag) {
      setFetchingWallet(true);
      APIClient.get(`admin/GetUserWalletDetails/${user.UserID}`)
        .then((resp) => {
          if (resp.status == 200) {
            setUser((prev) => ({ ...prev, Walletdetails: resp.data }));
          }
          setFetchingWallet(false);
        })
        .catch((error) => {
          setFetchingWallet(false);
        });
    }
  };
  /*============= check user logged or not =========== */
  const [modalVisible, setModalVisible] = useState({
    visible: false,
    type: "USER",
  });



  const handelIndex = (index, link) => {
    setIndex(index)
  }
  const showModal1 = (type) => {
    /*============= check user logged or not =========== */
    setModalVisible({ visible: true, type: type });

  };
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.login === true) {
        showModal1();
      }
    }
  }, [location]);


  const Balance = (
    <Menu>
      <div className="nav-wallet-wrapper">
        {agent ? (
          <span className="d-flex align-items-center justify-content-between">
            Cash : INR{" "}
            {user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : ""}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        ) : (
          <span className="d-flex align-items-center justify-content-between">
            Balance : INR
            {user
              ? user?.Walletdetails
                ? user?.Walletdetails.Amount
                : "0"
              : ""}
            {"  "}
            <SyncOutlined
              spin={fetchingWallet}
              style={{ marginLeft: "10px" }}
              onClick={() => {
                if (!fetchingWallet) getwalletBalance();
              }}
            />
          </span>
        )}
      </div>

      {user && user?.Role?.RoleLevel == 3 ? (
        <>
          {user?.Walletdetails?.CreditAmount >= 0 ? (
            <div className="nav-wallet-wrapper">
              <b>Credit Amount : {user?.Walletdetails?.CreditAmount}</b>
            </div>
          ) : null}
          {user ? (
            user?.Walletdetails?.ExpiryDate ? (
              <div className="nav-wallet-wrapper">
                <p className="mb-0">
                  Credit Expires :{" "}
                  {user?.Walletdetails?.ExpiryDate
                    ? moment(user?.Walletdetails?.ExpiryDate).format(
                      "DD-MM-YYYY"
                    )
                    : ""}
                </p>
              </div>
            ) : null
          ) : null}

          <Menu.Item key="15">
            <NavLink
              exact
              activeClassName="selectedNav"
              className="cre-amount-14"
              to="/deposits"
            >
              TOPUP
            </NavLink>
          </Menu.Item>
        </>
      ) : null}
    </Menu>
  );
  const loginMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/profile">
          My Profile
        </NavLink>
      </Menu.Item>

      <SubMenu key="2" title="Reports" className="border-bottom-1">
        <Menu.Item key="2-1">
          <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
            Flight Reports
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2-3">
          <NavLink exact activeClassName="" to="/booking-reports?type=Holiday">
            Holiday Reports
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2-2">
          <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
            Hotel Reports
          </NavLink>
        </Menu.Item>

      </SubMenu>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/coupon-wallet">
          Coupon Wallet
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/traveller-details">
          Passenger Details
        </NavLink>
      </Menu.Item>
      {user ? user.isloginType === 1 ?
        <Menu.Item key="8" className="border-bottom-0">
          <NavLink exact activeClassName="selectedNav" to="/change-password">
            Change Password
          </NavLink>
        </Menu.Item> : null : ""}
      <Menu.Item key="12">
        <NavLink exact activeClassName="selectedNav" to="/UserStatements">
          Statements
        </NavLink>
      </Menu.Item>
      <Menu.Item key="13">
        <NavLink exact activeClassName="selectedNav" to="/wallet">
          Wallet
        </NavLink>
      </Menu.Item>
    </Menu>
  );

  const AgentMenu = (
    <Menu className="menu-bdr-nav">
      <Menu.Item key="1">
        <NavLink exact activeClassName="selectedNav" to="/myprofile">
          My Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink exact activeClassName="selectedNav" to="/commission-details">
          My Commissions
        </NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink exact activeClassName="selectedNav" to="/bank-details">
          Bank Details
        </NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink exact activeClassName="selectedNav" to="/agent-markup">
          Markups
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/deposits">
          Deposits
        </NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink exact activeClassName="selectedNav" to="/agent-logo">
          Logo
        </NavLink>
      </Menu.Item>
      <Menu.Item key="6">
        <NavLink exact activeClassName="selectedNav" to="/store-vistors">
          Store Visitors
        </NavLink>
      </Menu.Item>
      <SubMenu key="7" title="Reports" className="border-bottom-1">
        <Menu.Item key="7-1">
          {(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ?
            <NavLink exact activeClassName="" to="/booking-reports?type=Flight">
              Flight Reports
            </NavLink> : null}
        </Menu.Item>
        <Menu.Item key="7-3">
          {(user?.Role?.Name === "Agent" && services.includes("3")) || isGuest ?
            <NavLink exact activeClassName="" to="/booking-reports?type=Holiday">
              Holiday Reports
            </NavLink> : null}
        </Menu.Item>
        <Menu.Item key="7-2">
          {(user?.Role?.Name === "Agent" && services.includes("2")) || isGuest ?
            <NavLink exact activeClassName="" to="/booking-reports?type=Hotel">
              Hotel Reports
            </NavLink> : null}
        </Menu.Item>

      </SubMenu>

      <Menu.Item key="13">
        <NavLink exact activeClassName="selectedNav" to="/Discounts">
          Margins
        </NavLink>
      </Menu.Item>
      <Menu.Item key="12">
        <NavLink exact activeClassName="selectedNav" to="/statements">
          Statements
        </NavLink>
      </Menu.Item>
      <Menu.Item key="10" className="border-bottom-0">
        <NavLink exact activeClassName="selectedNav" to="/change-password">
          Change Password
        </NavLink>
      </Menu.Item>

    </Menu>
  );
  const MainMenu = (
    <Menu className="menu-bdr-nav  loginnav-sub-menu-links"    >
      <Menu.Item key="21" className="hovereffectdrpdwn">
        <div onClick={() => showModal1("USER")}>User Login</div>
      </Menu.Item>
      <Menu.Item key="22" className="hovereffectdrpdwn">
        <div onClick={() => showModal1("AGENT")}>Partner Login</div>
      </Menu.Item>

    </Menu>
  );

  const handleDirection = (direction) => {
    localStorage.setItem("direction", direction);
    document.getElementsByTagName("HTML")[0].setAttribute("dir", direction);
  };

  const NavbarLinks = ({ user, isGuest }) => {
    let services = user?.Services ? user?.Services : [];


    return (
      <>
        {(isGuest || user?.Role?.Name === "Agent") && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav1" to="/">
              <div>
                <i
                  aria-hidden="true"
                ></i></div>
              <div>{(user?.Role?.Name === "Agent" && services.includes("1")) || isGuest ? <span><span className="fa fa-plane" style={{ fontSize: "17px" }}> </span><span style={{ fontStyle: 'Noto Sans', fontSize: "17px" }}> Flights</span></span> : <span><span className="fa fa-home" style={{ fontSize: "17px" }}> </span><span style={{ fontStyle: 'Noto Sans', fontWeight: "bold", fontSize: "17px" }}> Home</span></span>}</div>
            </NavLink>
          </li>
        )}
        {(isGuest || services.includes("2")) && (
          <li onClick={toggleCount}>
            <NavLink exact activeClassName="selectedNav1" to="/hotels" >
              <div style={{ width: "100%" }}><span><span className="fa fa-hotel" style={{ fontSize: "17px" }}> </span><span style={{ fontStyle: 'Noto Sans', fontSize: "17px" }}> Hotels</span></span>{" "}</div>
            </NavLink>
          </li>
        )}

        {(isGuest || services.includes("3")) && (

          <li onClick={toggleCount} >
            <NavLink exact activeClassName="selectedNav1" className='hover-for-all' to="/holidays" style={{ justifyContent: "space-evenly", display: "flex", flexWrap: "wrap", width: "100%" }} >
              {/* <div style={{ width: "20%" }}> <i className="fa fa-bed nav-itmes-target" aria-hidden="true"> </i>  </div> */}
              <div style={{ width: "100%" }}><span><span className="fa fa-shopping-bag" style={{ fontSize: "17px" }}> </span><span style={{ fontStyle: 'Noto Sans', fontSize: "17px" }}> Holidays</span></span>{" "}</div>
            </NavLink>
          </li>
        )}
      </>
    );
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.trustindex.io/loader.js?f9e2cb227d8102278196d0e2475';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="main-header-upper navbar-print-hide" >
        <div className="header-cont-detal" >
          <div className="header-cont-detal1">
            <div className="top-nav-sec">
              {/* <Google /> */}
              {/* <div id="trustindex-widget-embed" data-widget-id="f9e2cb227d8102278196d0e2475"></div> */}
              {/* <span className="mob-none ">
                <img src={googlelogo} style={{ width: "5%" }} />
                {/* <span style={{marginLeft:'1%', marginRight:'1%'}}>4.6/5 <span style={{color:"#83868d"}}>(rating)</span></span> */}
              {/* <span style={{ marginLeft: '1%', marginRight: '1%' }}>
                  <span>   4.1/5   </span>(
                  <span class="fa fa-star checked11 allstarsame"></span>
                  <span class="fa fa-star checked11 allstarsame"></span>
                  <span class="fa fa-star checked11 allstarsame"></span>
                  <span class="fa fa-star checked11 allstarsame"></span>
                  <span class="fa fa-star allstarsame"></span>)
                </span>
              </span> */}

              {/* <script defer async src='https://cdn.trustindex.io/loader.js?5ceee3e2722a02470316f54bad5'></script> */}
              {/* <script defer async src='https://cdn.trustindex.io/loader.js?c04a5fe2759642939466f0b0cf4'></script> */}
              <span style={{ fontSize: '15px', fontWeight: '600', marginRight: '2%' }} >Call us 7 days    <span class="fa fa-whatsapp" style={{ fontSize: '15px', fontWeight: '600', marginLeft: '1%', color: "white", backgroundColor: 'green', borderRadius: '50%', padding: '0.2%' }} ></span>   +91 9704206860 </span>
              <ul className="all-top-menu">
                <li className="mob-none cart-nav-top">
                  {user &&
                    Object.keys(user).length > 0 &&
                    (flag == true || agent == true) ? (
                    <span>
                      Welcome {user.FirstName}{" "}
                      {agent ? `(${user.AgentID})` : null}
                    </span>
                  ) : null}
                </li>


                {agent == true ? (
                  <li >
                    <Dropdown overlay={Balance} trigger={["click"]}>
                      <span >
                        Balance <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  </li>
                ) : null}

                {user && !agent ? (
                  <li>
                    <Dropdown overlay={Balance} trigger={["click"]}>
                      <span >
                        Wallet <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  </li>
                ) : null}
                {flag === true || agent === true ? (
                  <li className="mob-none cart-nav-top">
                    <NavLink
                      to="/"
                      exact
                      activeClassName="selectedNav"
                      onClick={logout}
                    >
                      Logout
                    </NavLink>
                  </li>
                ) : null}
              </ul>

              <span className="flag-main-span">
                <span className="currency-text last-mob-bdr mob-none">
                  <FlagsList />
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="header-wid-logo-one-main" >
          <div className="header-wid-logo-one" >

            <div className="header-wid-logo-one1" onClick={toggleCount}>
              <NavLink exact activeClassName="" to="/">
                {logo ? (
                  <img style={{ width: '60%' }} src={BASE + logo.substring(1)} alt="trypmaker_Logo" />
                ) : (
                  <img
                    style={{ width: '60%' }}
                    src={
                      require("../../assets/images/trip.jpg").default
                    }
                    alt="trypmaker"
                  />
                )}
              </NavLink>
            </div>

            <div className="header-wid-logo-one3" >
              {user ? (
                <ul >

                  <li >
                    {flag ? (
                      <Dropdown overlay={loginMenu} trigger={["click"]}  >
                        <span ><a>
                          <UserOutlined /> My_Account{" "}
                          <CaretDownOutlined className="mr-0" /></a>
                        </span>
                      </Dropdown>

                    ) : agent ? (
                      <Dropdown overlay={AgentMenu} trigger={["click"]} >
                        <span ><a>
                          <UserOutlined /> My_Account
                          <CaretDownOutlined className="mr-0" /></a>
                        </span>
                      </Dropdown>
                    ) : null}
                  </li>

                </ul>
              ) : <div className="header-wid-logo-one3" style={{ width: '100%' }}>
                <span>
                  {agent !== true ? (
                    <span className=" cart-nav-top trip-mar-ri " onClick={toggleCount}  >
                      <NavLink exact to="/mytrips" onClick={toggleCount}>
                        <span  > My Trips</span>
                      </NavLink>
                    </span>
                  ) : null}
                </span>
                <span className="border-right-0  ">
                  <Dropdown overlay={MainMenu} trigger={["click"]} className='dropdwn'  >
                    <span className="signin-button-navbar" ><span className="mob-none" >
                      <a>
                        Sign in
                      </a>
                    </span>
                    </span>
                  </Dropdown>
                </span>
              </div>}
            </div>

          </div>
        </div>

        <div className="header-wid-logo-one-main1-pho" >
          <div className="header-wid-logo-one-pho" >
            <div className="header-wid-logo-one1-pho" onClick={toggleCount}>
              <NavLink exact activeClassName="" to="/">
                {logo ? (
                  <img src={BASE + logo.substring(1)} alt="trypmaker_Logo" />
                ) : (
                  <img
                    src={
                      require("../../assets/images/trip.jpg").default
                    }
                    alt="trypmaker"
                  />
                )}
              </NavLink>
            </div>

            <div className="header-wid-logo-one3-pho"   >

              <span>
                {flag === true || agent === true ? (
                  <span className="  cart-nav-top">
                    <NavLink
                      to="/"
                      exact
                      activeClassName="selectedNav"
                      onClick={logout}
                    >
                      Logout
                    </NavLink>
                  </span >
                ) : null}
              </span>


              <span>
                {agent !== true ? (
                  <span className=" cart-nav-top trip-mar-ri " onClick={toggleCount}  >
                    <NavLink exact to="/mytrips" onClick={toggleCount}>
                      <span > Trips</span>
                    </NavLink>
                  </span>
                ) : null}
              </span>

              {flag === true || agent === true ? (
                null
              ) : <Dropdown overlay={MainMenu} trigger={["click"]} className='dropdwn'   >
                <span className="signin-button-navbar " style={{ backgroundColor: 'white' }} ><a>
                  <UserOutlined style={{ color: 'black' }} /></a>
                </span>
              </Dropdown>}
            </div>
            <li >
              {flag ? (
                <Dropdown overlay={loginMenu} trigger={["click"]}  >
                  <span ><a>
                    <UserOutlined /> {" "}
                    <CaretDownOutlined className="mr-0" /></a>
                  </span>
                </Dropdown>

              ) : agent ? (
                <Dropdown overlay={AgentMenu} trigger={["click"]} >
                  <span ><a>
                    <UserOutlined />
                    <CaretDownOutlined className="mr-0" /></a>
                  </span>
                </Dropdown>
              ) : null}
            </li>
            <li className=" cart-nav-top" style={{ textAlign: 'left', width: '92%' }}>
              {user &&
                Object.keys(user).length > 0 &&
                (flag == true || agent == true) ? (
                <span>
                  Welcome {user.FirstName}{" "}
                  {agent ? `(${user.AgentID})` : null}
                </span>
              ) : null}
            </li>
            <div className="header-wid-logo-one2-pho" >
              {/* <Drawersearch
                index={index} /> */}
            </div>
          </div >
        </div >
      </div >

      <div class="scrollable-tabs-container navbar-print-hide">

        <div class="scrollable-tabs-container1">
          <div class="left-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"> <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
          </div>
          <ul>
            {agent ? (
              <NavbarLinks user={user} isGuest={false} />
            ) : (
              <NavbarLinks user={null} isGuest={true} />
            )}
            {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
              <li>
                <NavLink exact activeClassName="active" to="/flightGroupEnquiry"  >
                  <span>Flight Enquiry</span>
                </NavLink>
              </li> : null}
            {agent ? (
              null
            ) : (<li>
              <NavLink exact activeClassName="active" to="/offers" >
                <span>Offers</span>
              </NavLink>
            </li>)}
            {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
              <li>
                <NavLink exact activeClassName="active" to="/web-check-in" >
                  <span>Web Check In</span>
                </NavLink>
              </li> : null}
            <li>
              <NavLink exact activeClassName="active" to="/mytrips" >
                <span>Retrieve Booking</span>
              </NavLink>
            </li>

          </ul >
          <div class="right-arrow">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"> <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
          </div>
        </div >


      </div >







      <div className="header-bg navbar-print-hide">
        <div className="header-container">
          <div className="wrapper">
            <div className="top-nav-sec">
              <ul className="all-top-menu">
                <li className="mob-none cart-nav-top">
                  {user &&
                    Object.keys(user).length > 0 &&
                    (flag === true || agent === true) ? (
                    <span>
                      Welcome {user.FirstName}{" "}
                      {agent ? `(${user.AgentID})` : null}
                    </span>
                  ) : null}
                </li>


                {agent === true ? (
                  <li className="mob-none cart-nav-top">
                    <Dropdown overlay={Balance} trigger={["click"]}>
                      <span className="user-btn-wrapper"
                        style={{ backgroundColor: 'transparent', border: '1px solid #320063', paddingLeft: '10%', paddingRight: "10%", borderRadius: '5px', marginTop: '-1%' }}
                      >
                        Balance <CaretDownOutlined />
                      </span>
                    </Dropdown>
                  </li>
                ) : null}

                {user && !agent ? (
                  <li className="mob-none cart-nav-top">
                    <Dropdown overlay={Balance} trigger={["click"]}>
                      <span className="user-btn-wrapper">
                        Wallet <CaretDownOutlined className="mr-0" />
                      </span>
                    </Dropdown>
                  </li>
                ) : null}
                {flag === true || agent === true ? (
                  <li className="mob-none cart-nav-top">
                    <NavLink
                      to="/"
                      style={{ backgroundColor: 'transparent', border: '1px solid #320063', paddingLeft: '10%', paddingRight: "10%", borderRadius: '5px' }}
                      exact
                      activeClassName="selectedNav"
                      onClick={logout}
                    >
                      Logout
                    </NavLink>
                  </li>
                ) : null}
              </ul>
            </div>

            <header className="header">
              <div className="app-logo">

                <div className="user-icon">
                  <input className="menu-btn" type="checkbox" id="menu-btn" />
                  <span className="d-block d-md-none mobile-user">
                    <div className="mobile-user-icon">
                      {flag === true ? (
                        <Dropdown overlay={loginMenu} trigger={["click"]}>
                          <span className="user-btn-wrapper">
                            <UserOutlined />
                          </span>
                        </Dropdown>
                      ) : agent === true ? (
                        <Dropdown overlay={AgentMenu} trigger={["click"]}>
                          <span className="user-btn-wrapper">
                            <UserOutlined />
                          </span>
                        </Dropdown>
                      ) : (
                        <span className="user-btn-wrapper" onClick={showModal1}>
                          <UserOutlined />
                        </span>
                      )}
                    </div>
                  </span>

                </div>
              </div>
              <div className="app-links">
                <ul
                  className="menu"

                  style={{ maxHeight: navToggle ? "610px" : "0px", }}
                >

                  {agent ? (
                    <NavbarLinks user={user} isGuest={false} />
                  ) : (
                    <NavbarLinks user={null} isGuest={true} />
                  )}

                  <li onClick={toggleCount}>
                    <NavLink exact activeClassName="selectedNav" to="/flightGroupEnquiry"  >
                      <div>Flight Enquiry</div>
                    </NavLink>
                  </li>
                  {agent ? (
                    null

                  ) : (<li onClick={toggleCount}>
                    <NavLink exact activeClassName="selectedNav" to="/offers" >
                      <div>Offers</div>
                    </NavLink>
                  </li>)}

                  <li onClick={toggleCount}>
                    <NavLink exact activeClassName="selectedNav" to="/web-check-in" >
                      <div>Web Check In</div>
                    </NavLink>
                  </li>
                  <li onClick={toggleCount}>
                    <NavLink exact activeClassName="selectedNav" to="/mytrips" >
                      <div>Retrieve Booking</div>
                    </NavLink>
                  </li>




                  <li onClick={toggleCount} className="delas-taget-flight">
                    <NavLink exact activeClassName="selectedNav" to="/deals">
                      {" "}
                      <i
                        className="fa fa-plane nav-itmes-target"
                        aria-hidden="true"
                      ></i>
                      Flight Deals
                    </NavLink>
                  </li>
                  {agent ? (
                    null

                  ) : (<li
                    id="delas-taget-flight"
                    onClick={toggleCount}
                    className={`${agent ? null : "border-right-0"}`}
                  >
                    <NavLink exact activeClassName="selectedNav" to="/offers">
                      <i
                        className="fa fa-tags nav-itmes-target"
                        aria-hidden="true"
                      ></i>
                      Offers
                    </NavLink>
                  </li>)}




                  <li
                    onClick={() => showModal1("USER")}
                    className="border-right-0"
                    id="only-mob-user"

                  >
                    <NavLink exact activeClassName="selectedNav" onClick={toggleCount} to="">
                      User Login
                    </NavLink>
                  </li>


                </ul >

                {
                  user ? (
                    <ul className="menu" style={{ border: '2px solid #340167', borderRadius: '10px' }} >
                      <li className="border-right-0 mob-none" style={{ padding: "6%", marginLeft: "2%", marginRight: "3%", paddingRight: "2%", width: "100%", border: "none" }}>
                        {flag ? (
                          <Dropdown overlay={loginMenu} trigger={["click"]}  >
                            <span className="user-btn-wrapper" ><a>
                              <UserOutlined />  My_Account{" "}
                              <CaretDownOutlined className="mr-0" /></a>
                            </span>
                          </Dropdown>
                        ) : agent ? (
                          <Dropdown overlay={AgentMenu} trigger={["click"]} >
                            <span className="user-btn-wrapper"><a>
                              <UserOutlined /> My_Account
                              <CaretDownOutlined className="mr-0" /></a>
                            </span>
                          </Dropdown>
                        ) : null}
                      </li>

                    </ul >
                  ) : null
                }


              </div >
            </header >
          </div >
        </div >

        {/* {console.log(location, "loc-2")} */}
        <Modal
          centered
          visible={modalVisible.visible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          className="login-modal"
          footer={false}
        >
          <Login
            location={location}
            setModalVisible={(value) => setModalVisible(value)}
            type={modalVisible.type}
          />
        </Modal>
      </div >
    </>
  );
};

export default withRouter(Nav);
