import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import { CloseOutlined } from "@ant-design/icons";
import UpdatedComponent from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = ANTD.Layout;

function HolidayEnquires(props) {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [holidayEnquires, setHolidayEnquires] = useState([]);

  const GetHolidayEnquires = () => {
    ApiClient.get("HolidayExtranet/get/offlinequery")
      .then((res) => {

        if (res.statusCode == 200) {
          let data = res.data.map((item, index) => {

            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              JourneyDate: moment(item.JourneyDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setHolidayEnquires(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
      });
  };

  useEffect(() => {
    GetHolidayEnquires();
  }, []);

  const tableColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
    },

    // {
    //   title: "Flight Enquiry ID",
    //   dataIndex: "FlightEnquiryID",
    //   sorter: (a, b) => a.FlightEnquiryID - b.FlightEnquiryID,
    // },
    {
      title: "Name",
      dataIndex: "FullName",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Contact Number",
      dataIndex: "Mobile",
    },
    {
      title: "Package",
      dataIndex: "PackageName",
    },
    {
      title: "Trip Duration",
      dataIndex: "Duration",
    },


    {
      title: "Journey Date",
      dataIndex: "JourneyDate",
    },
    {
      title: "Source City",
      dataIndex: "City",
      sorter: (a, b) => a.SourceCity.localeCompare(b.SourceCity),
    },
    // {
    //   title: "Deperture Time",
    //   dataIndex: "DepartureTime",

    // },

    // {
    //   title: "Return Time",
    //   dataIndex: "ReturnTime",
    //   render: (text) => getTime(text.ReturnTime),
    // },


    // {
    //   title: "Created Date",
    //   dataIndex: "CreatedDate",
    // },
    // {
    //   title: "Modified Date",
    //   dataIndex: "ModifiedDate",
    // },
  ];

  /* === === === === Search Button Starts === === === === */

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = holidayEnquires.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setHolidayEnquires(searchResults);
    } else {
      GetHolidayEnquires();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  /* === === === === Search Button ends === === === === */

  /* === === === === Excel Button starts === === === === */

  const handleExcel = () => {
    const excelData = holidayEnquires;

    props.exportExcel(excelData, "HolidayEnquiryDetails");
  };

  /* === === === === Excel Button Ends === === === === */

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Holiday Enquiries{" "}
                          <HelpInfoHelper
                            screenName={"/admin/reports/holidayOffline"}
                          />
                        </h5>
                        <p>{holidayEnquires.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => GetHolidayEnquires()}
                      />
                    </div>
                  </div>
                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={holidayEnquires}
                      columns={tableColumns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
    </div>
  );
}

export default UpdatedComponent(HolidayEnquires);
