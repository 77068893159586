import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import ApiClient from "../../../helpers/ApiClient";
import queryString from "query-string";
const ActiveTabs = () => {

    let tomorrowDate = moment().add(5, 'days').format("YYYY-MM-DD");
    let dayafter = moment().add(7, "days").format("YYYY-MM-DD");
    const getHotelSer = (url) => {
        // console.log(url, "url");
        let formData = {
            checkInDate: tomorrowDate,
            checkOutDate: dayafter,
            hotelCityCode: url,
            roomGuests: JSON.stringify([{
                noOfAdults: 1,
                noOfChilds: 0,
                childAge: [],
            },]),
            nationality: "IN",
            currency: "INR",
            countryCode: "IN",
            traceId: "string",
        };

        const query = queryString.stringify(formData);

        return "/hotels/listing?" + query;
    };

    return (

        <div className="active-ht-tag">
            <p className="act-hdr-tag">Trending Destination</p>
            <ul className="active-tg">
                <li className="active-t"><Link className='anc-tab' to={getHotelSer('cityName=Hyderabad,India&&cityId=22586')}>Hyderabad</Link></li>
                <li className="active-t"><Link className='anc-tab' to={getHotelSer('cityName=Goa,India&&cityId=22582')}>Goa</Link></li>
                <li className="active-t"><Link className='anc-tab' to={getHotelSer('cityName=Dubai,United Arab Emirates,&&cityId=42323')}>Dubai</Link></li>
                <li className="active-t"><Link className='anc-tab' to={getHotelSer('cityName=Bangkok,Thailand&&cityId=41319')}>Thailand</Link></li>
                <li className="active-t"><Link className='anc-tab' to={getHotelSer('cityName=Andaman and Nicobar Islands,India&&cityId=52495')}>Andaman's</Link></li>

            </ul>
        </div>

    );
};
export default ActiveTabs;