import React, { useContext, useState, useEffect, Suspense, useCallback } from "react";
import {
  Row,
  Col,
  Collapse,
  Grid,
  Skeleton,
  Modal,
  Button,
  message,
  Form,
  Statistic,
} from "antd";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { GlobalStatesContext } from "../../common/providers";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import { ClockCircleOutlined,
} from "@ant-design/icons";
import FilterSkeletonLayout from "../FilterSkeleton/FilterSkeleton";
import FlightResultsCardSkeleton from "../FlightResultsCardSkeleton/FlightResultsCardSkeleton";

import { getSearchRequestFromQuery, getMultidestinationQuery } from "../flight-search/SearchRequestHelper";
import "./SearchResults.scss";
import { useFlightContext } from "../../common/providers/Flights/FlightContext";
import ModifyFlightSearch from "../flight-search/ModifyFlightSearch";
//#region  Dynamic Import
const FlightsList = React.lazy(() => import('./FlightsList'))
const FlightFilters = React.lazy(() => import('../Flight-Filters/FlightFilters'))
const FlightSearch = React.lazy(() => import('../flight-search/FlightSearch'))
const Sort = React.lazy(() => import('./SortBy/Sort'))
const NoResultFound = React.lazy(() => import('../ErrorPages/NoResultFound'))

//#endregion end of Dynamic Import


const { useBreakpoint } = Grid;
const { Panel } = Collapse;
const { Countdown } = Statistic;
const SearchResults = () => {
  let history = useHistory();
  const { user } = useAuthContext();
  const {
    setFlightSearchObj,
    resetFlightSelectedData,
    updateFlightAirSearchRespObj,
    updateSelectedFlight,
    updateFlightFares,
    getsessiontimeout,
    state: { flightAirSearchResp, flightSearchObj, selectedFlight,sessiontimeout,status },
  } = useFlightContext();

  const {
    state: { airlineMatrixReset, flightFilters },
    ResetAirlineMatrix,
  } = useContext(GlobalStatesContext);

  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const [selectedTripType, setSelectedTripType] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisiblenew, setModalVisiblenew] = useState(false);
  const [showNetFare, setShowNetFare] = useState(false);
  const [promodataSource, setPromoDataSource] = useState([]);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [istopset, setistopset] = useState(-520);
  const [y, setY] = useState(window.scrollY);
  const [MultiCityIndex, setMultiCityIndex] = useState(0);
  function onInactive(ms, cb) {
    var wait = setTimeout(cb, ms);

    document.onmousemove =
      document.mousedown =
      document.mouseup =
      document.onkeydown =
      document.onkeyup =
      document.focus =
      document.scroll =
      function () {
        clearTimeout(wait);

        if (
          !localStorage.getItem("popupShown") &&
          JSON.parse(localStorage.getItem("popupShown")) !== true
        ) {
          wait = setTimeout(cb, ms);
        }
      };
  }
  const dateFormat = "DD-MM-YYYY";
  const dateFormatrev = "YYYY-MM-DD";
  const getPromoCodes = () => {
    setPromoDataSource([]);

    ApiClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {

          let datas = res.data.filter((item) =>
            moment(item.ValidTill, dateFormatrev).isSameOrAfter(moment())
          );
          let data = datas.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              ValidFrom: moment(item.ValidFrom).format(dateFormat),
              ValidTill: moment(item.ValidTill).format(dateFormat),
            };
          });
          setPromoDataSource(data);
        }
      })
      .catch((e) => {
        console.log("api error", e);
       });
  };

  const showModalflight = () => {
    setModalVisible({
      visible: true,
    });
  };

  const showModalsort = () => {
    setModalVisiblenew({
      visible: true,
    });
  };

  const SetUpFlightFromResponse = (resp, requestData) => {
    setFlightSearchObj({
      ...requestData,
      traceId: resp.data.traceId,
      resultsType: resp.data.resultsType,
    });
    updateFlightAirSearchRespObj({
      ...resp.data,
      tripType: 1,
      flightDetails: resp?.data.flightDetails.map((flight) => {
        return { ...flight, isVisible: true };
      }),
      ibFlightDetails: resp.data.ibFlightDetails.map((flight) => {
        return { ...flight, isVisible: true };
      }),
      multicityFlightDetails: resp.data?.multicityFlightDetails.map((flights) => {
        return flights.map((flight) => {
          return { ...flight, isVisible: true };
        })
      })
    });
  };


  const getFlightResults = () => {
    setIsLoading(true);
    resetFlightSelectedData();
    let requestData = getSearchRequestFromQuery();

    if (
      requestData.originDestinations[0].destination ===
      requestData.originDestinations[0].origin
    ) {
      message.error("Origin and Destination cannot be same", 3);
      setIsLoading(false);
      return false;
    }

    let reqData = {
      ...requestData,
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1
    };

    ApiClient.post("flights/airSearch", reqData)
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.statusCode === 200) {
          SetUpFlightFromResponse(resp, reqData);
        }
        setIsLoading(false);
      })
      .catch((err) => setIsLoading(false));
  };

  const onFormSubmit = () => {
    getFlightResults();
  };
  useEffect(()=>{getsessiontimeout()},[])
  useEffect(() => {
    getPromoCodes();

    if (
      !localStorage.getItem("popupShown") &&
      JSON.parse(localStorage.getItem("popupShown")) !== true
    ) {
      onInactive(15000, function () {
        setPopUpVisibility(true);
      });
    }
  }, []);
  // console.log(JSON.parse(localStorage.getItem('FlightSearchBar')),"query")
  const handelCountdown = () => {
   
    Modal.warning({
      icon: <ClockCircleOutlined />,
      //title: "",
      content: (<div><h5>Your Session is Expired</h5><p>Click on "OK" to continue with New Search</p></div>),
      onOk() {
        //let queryParams = queryString.stringify(flightsearchsessionobj);
        let queryParams =(JSON.parse(localStorage.getItem('FlightSearchBar')));
        // console.log("queryparam",queryParams)
    getsessiontimeout();
    getFlightResults ();
        history.push("/flight/results?" + queryParams);
      },
    });
  };
  const handleNavigation = useCallback(
    e => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
       
        setistopset(0)//(istopset+Number(y)-Number(window.scrollY))
        
      } else if (y < window.scrollY) {
        setistopset(-520)
      }
      setY(window.scrollY);
    }, [y]
  );
  
  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);
  
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);



  useEffect(() => {
    getFlightResults();
  }, [window.location.search]);

  const LoadingSkelTon = () => {
    return (
      <div>
        <div className="flight-results-skeleton-container">
          <Row gutter={{ xs: 8, md: 16 }}>
            <Col md={6} xs={0}>
              <FilterSkeletonLayout />
            </Col>
            <Col md={18} xs={24}>
              <div className="flights-skeleton-card">
                <div className="nearbydates-skeleton">
                  <Skeleton active={true} paragraph={{ rows: 0 }} />
                </div>
                <div className="flights-sort-skeleton">
                  <Row className="flight-sort-skeleton-row">
                    <Col md={3} xs={0} className="sort-name">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Col>
                    <Col md={4} xs={0}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={4} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={5} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={5} xs={6}>
                      <Skeleton active={true} paragraph={{ rows: 1 }} />
                    </Col>
                    <Col md={3} xs={6} className="airline-name">
                      <Skeleton active={true} paragraph={{ rows: 0 }} />
                    </Col>
                  </Row>
                </div>
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
                <FlightResultsCardSkeleton />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  const getMultiCityIndex = (index) => {
    setMultiCityIndex(index)
  }
  const getPrevNextsearch = (type) => {

    const searchRequest = queryString.parse(window.location.search);
    let start_params = `adultCount=${searchRequest.adultCount}&airTravelType=${searchRequest.airTravelType}&cabinClass=${searchRequest.cabinClass}&childCount=${searchRequest.childCount}&infantCount=${searchRequest.infantCount}&stopOver=${searchRequest.stopOver}&priceForTrip=${searchRequest.priceForTrip}&includeCarrier=${searchRequest.includeCarrier}&`;
    let one_way_params =
      start_params +
      `departureDateTime=${moment(searchRequest.departureDateTime).add(type, "day").format("YYYY-MM-DD").toString()}&flightDateFlex=${searchRequest.flightDateFlex}&origin=${searchRequest.origin}&destination=${searchRequest.destination}`;

    if (searchRequest.airTravelType != "oneWay") {
      one_way_params = one_way_params +
        `&returnDateTime=${searchRequest.originDestinations[1].departureDateTime}`

    }
    history.replace("/flight/results?" + one_way_params);
  }
  return (
    <div>
      <div className="modify-search-container">
        <div className="modify-search-container-fluid">
          <Collapse
            activeKey={md ? ["1"] : key}
            showArrow={false}
            onChange={(val) => {
              setKey(val);
            }}
          >
            <Panel
              showArrow={false}
              header={<span className="hotels-hide-search">Modify Search</span>}
              key="1"
            >
              <Suspense fallback={<div>Loading...</div>}>
                <ModifyFlightSearch modify={true} onFormSubmit={onFormSubmit} />
              </Suspense>
            </Panel>
          </Collapse>
        </div>
      </div>
      <div className="result_div">
        {isLoading ? (
          <LoadingSkelTon />
        ) : (
          <div style={{ backgroundColor: "#E8F2FA" }}>
            {Object.keys(flightAirSearchResp).length === 0 ||

              flightAirSearchResp.flightDetails.length === 0 && flightAirSearchResp.multicityFlightDetails.length == 0 ? (
              <div className="flight-results-skeleton-container">
                <Suspense fallback={<div>Loading...</div>}>
                  <NoResultFound />
                </Suspense>
              </div>
            ) : (
              <>
              {status != 0 &&
              <div className="countdown31">
                    <div className="countdown-main-box">
                      <div className="countdown1">
                        <span class="fa fa-clock-o" style={{fontSize:"18px" }}></span><span> Your session will expire in</span>
                      </div>
                      <div className="countdown2">
                        <Countdown
                          className="busCountdown"
                          onFinish={handelCountdown}
                          value={sessiontimeout}
                          format={"mm[ min]  ss[ sec]"}
                        />
                      </div>
                    </div>
                  </div>}
                <div className="result_div-container">
                  <Row gutter={{ xs: 8, md: 16 }}>
                    <Col md={6} xs={0} style={{ marginTop: '4%' }} className="filter-fflight-hop">
                      <div style={{ position: "sticky", zIndex: "0", top: istopset }}>
                        <div className="filter-section">
                          {flightAirSearchResp.flightDetails && (
                            <Suspense fallback={<div>Loading...</div>}>
                              <FlightFilters
                                updateFlightAirSearchRespObj={
                                  updateFlightAirSearchRespObj
                                }
                                ResetAirlineMatrix={ResetAirlineMatrix}
                                selectedTripType={selectedTripType}
                                flightAirSearchResp={flightAirSearchResp}
                                MultiCityIndex={MultiCityIndex}
                              />
                            </Suspense>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col className="result-flight-hop">
                      <div className="pnday"><span className="prev-day" onClick={() => getPrevNextsearch(-1)} style={{ fontSize: '12px' }} ><a>previous day </a></span>| <span className="next-day" onClick={() => getPrevNextsearch(1)} style={{ fontSize: '12px' }} ><a>next day</a></span></div>
                      <div className="flight-sort-container">
                        <Suspense fallback={<div>Loading...</div>}>
                          <Sort
                            updateFlightAirSearchRespObj={
                              updateFlightAirSearchRespObj
                            }
                            flightAirSearchResp={flightAirSearchResp}
                            airlineMatrixReset={airlineMatrixReset}
                            ResetAirlineMatrix={ResetAirlineMatrix}
                            selectedTripType={selectedTripType}
                            showNetFare={showNetFare}
                            setShowNetFare={setShowNetFare}
                            MultiCityIndex={MultiCityIndex}
                          />
                        </Suspense>
                      </div>
                      <div className="flight-search-results-sec">
                        <div>
                          <div style={{ display: "inline-block", width: '100%' }}>
                            <Suspense fallback={<div>Loading...</div>}>
                              <FlightsList
                                setSelectedTripType={setSelectedTripType}
                                flightAirSearchResp={flightAirSearchResp}
                                updateSelectedFlight={updateSelectedFlight}
                                flightSearchObj={flightSearchObj}
                                updateFlightFares={updateFlightFares}
                                selectedFlight={selectedFlight}
                                showNetFare={showNetFare}
                                promoData={promodataSource}
                                setMultiCityIndex={getMultiCityIndex}
                                MultiCityIndex={MultiCityIndex}
                              />
                            </Suspense>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalflight}>
            {" "}
            <i className="fa fa-filter" aria-hidden="true"></i>&nbsp;Filters
          </h5>
        </Col>
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5 className="hotel-sort-by-1" onClick={showModalsort}>
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>

      </Row>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Flight Filters</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button type="primary">Close</Button>
          </div>,
        ]}
      >
        <Row>
          <Col md={24} xs={24}>
            <div>
              {flightAirSearchResp.flightDetails && (
                <Suspense fallback={<div>Loading...</div>}>
                  <FlightFilters
                    updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                    ResetAirlineMatrix={ResetAirlineMatrix}
                    selectedTripType={selectedTripType}
                    flightAirSearchResp={flightAirSearchResp}
                  />
                </Suspense>
              )}
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Sort by</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header sort-modal-mobiletr"
        visible={modalVisiblenew}
        onOk={(e) => setModalVisiblenew(false)}
        onCancel={(e) => setModalVisiblenew(false)}
        footer={[
          <div>
            <Button type="primary">Apply</Button>
          </div>,
        ]}
      >
        <Form>
          <Row>
            <Col md={24} xs={24}>
              <div>
                <Suspense fallback={<div>Loading...</div>}>
                  <Sort
                    updateFlightAirSearchRespObj={updateFlightAirSearchRespObj}
                    flightAirSearchResp={flightAirSearchResp}
                    airlineMatrixReset={airlineMatrixReset}
                    ResetAirlineMatrix={ResetAirlineMatrix}
                    selectedTripType={selectedTripType}
                    showNetFare={showNetFare}
                    setShowNetFare={setShowNetFare}
                  />
                </Suspense>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>


    </div>
  );
};

export default SearchResults;
