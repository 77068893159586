import React, { useEffect, useState } from "react";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
  Layout,
  Modal,
  Collapse,
  Radio,
  Popover,
  Switch,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
  // InfoCircleTwoTone,
} from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";

const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
const HelpInfo = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [HelpInfoForm] = Form.useForm();
  const [helpInfo, setHelpInfo] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [excelList, setExcelList] = useState([]);
  const [screensList, setScreensList] = useState([]);

  useEffect(() => {
    getHelpInformation();
    getScreens();
  }, []);

  const getScreens = () => {
    ApiClient.get("admin/screen")
      .then((res) => {
        if (res.status == 200) {
          setScreensList(res.data);
        } else {
          setScreensList([]);
        }
      })
      .catch((error) => {
        setScreensList([]);
      });
  };

  const getHelpInformation = () => {
    ApiClient.get("admin/getHelpInformation")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data;
          setHelpInfo(data);
          defineExcelData(data);
        } else {
          setHelpInfo([]);
        }
      })
      .catch((e) => {
        setHelpInfo([]);
      });
  };

  const submitForm = (values) => {
    var filterList = screensList.filter(
      (screen) => screen.ScreenID === values.screensId
    );

    let data = {
      screenID: values.screensId,
      screenName: filterList[0].DisplayName,
      screenUrl: filterList[0].Name,
      helpDescription: values.helpDescription,
      status: values.status,
    };

    isEdit ? updateHelpInformation(data) : addHelpInformation(data);
  };

  const addHelpInformation = (data) => {
    ApiClient.post("admin/addHelpInformation", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Help Information added successfully !");

          getHelpInformation();
          setModalVisible(false);
          HelpInfoForm.resetFields();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateHelpInformation = (data) => {
    ApiClient.put("admin/updateHelpInformation/" + currID, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Help Information updated successfully !");
          getScreens();
          getHelpInformation();
          setModalVisible(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteHelpInfo = (id) => {
    ApiClient.delete("admin/deleteHelpInformation/" + id)
      .then((response) => {
        if (response.status == 200) {
          message.success("Help Information Deleted successfully !");
          getHelpInformation();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const showModal = () => {
    if (isEdit) {
      setIsEdit(false);

      HelpInfoForm.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const updateHelpInfo = (currentData) => {
    setIsEdit(true);
    setModalVisible(true);
    setCurrID(currentData.id);
    HelpInfoForm.setFieldsValue({
      screensId: currentData.screenID,
      helpDescription: currentData.helpDescription,
      status: currentData.status,
    });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        ScreenName: item.screenName,
        ScreenUrl: item.screenUrl,
        HelpDescription: item.helpDescription,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const tableActions = (currentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}
          onClick={() => updateHelpInfo(currentItem)}
        >
          <EditOutlined
            style={{ color: "#174998", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteHelpInfo(currentItem.id)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Screen Name",
      dataIndex: "screenName",
      sorter: (a, b) => a.screenName.localeCompare(b.screenName),
    },
    {
      title: "Screen URL",
      dataIndex: "screenUrl",
      sorter: (a, b) => a.screenUrl.localeCompare(b.screenUrl),
    },
    {
      title: "Description",
      dataIndex: "helpDescription",
    },
    {
      title: "Status",

      render: (currentItem) => handelStatus(currentItem),
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info, status) => {
    let obj = {
      Id: info.id,
      status: status,
    };
    ApiClient.put("admin/updateHelpInformationStatus/", {}, obj)
      .then((response) => {
        if (response.status == 200) {
          message.success("Help Information Status updated successfully !");
          getScreens();
          getHelpInformation();
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = helpInfo.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setHelpInfo(searchResults);
    } else {
      getHelpInformation();
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Help Information{" "}
                          <HelpInfoHelper screenName={"/admin/HelpInfo"} />
                        </h5>
                        {helpInfo.length > 0 && (
                          <p>{helpInfo.length} rows found !</p>
                        )}
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      {/* <img
                          src={excel}
                          alt="excel"
                          // onClick={handleExcel}
                          onClick={(e) =>
                            props.exportExcel(excelList, "CMS Terms & Offers")
                          }
                        /> */}
                      {/*  <img src={exchange} alt="exchange" /> */}
                      <p className="add-icon" onClick={showModal}>
                        <PlusOutlined />
                      </p>
                    </div>
                  </div>
                  {helpInfo.length > 0 ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={helpInfo}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isEdit ? "Update Help Information" : "Add Help Information"}
              </strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header admin-bc-level"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              htmlType="submit"
              type="primary"
              onClick={HelpInfoForm.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button type="danger" onClick={() => HelpInfoForm.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form
          {...formItemLayout}
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          form={HelpInfoForm}
          onFinish={submitForm}
        >
          <Row gutter={(12, 12)} className="mb-2">
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Screens"
                name="screensId"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select Screens"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {screensList.map((data, index) => (
                    <Option key={index} value={data.ScreenID}>
                      {data.DisplayName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" md={24} xs={24}>
              <Form.Item
                label="Help Information"
                name="helpDescription"
                rules={[{ required: true }]}
              >
                <TextArea rows={4} placeholder="Help Information Description" />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Status"
                name="status"
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={2}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdatedComponent(HelpInfo);
