import React, { useEffect, useState } from "react";
import { Button, Form, message, Radio, Space } from "antd";

const loadScript = (src) => {
    return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}


const RazorpayCheckout = ({ pgData }) => {


    useEffect(() => {
        displayRazorpay()
    }, [pgData])

    const displayRazorpay = async () => {

        const scriptRes = await loadScript("https://checkout.razorpay.com/v1/checkout.js")
        if (!scriptRes) {
            alert("Failed To Load SDK")
            return
        }

        const orderResp = pgData

        const options = {
            key: orderResp.key_id || `${process.env.REACT_APP_RAZORPAY_KEY}`, // Enter the Key ID generated from the Dashboard
            amount: orderResp.amount.toString(), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: orderResp.currency,
            name: "ACROPOLIS SYSTEMS PRIVATE LIMITED",
            description: "hopmker Travel Portal",
            image: `${process.env.REACT_APP_LOGO}`,
            order_id: orderResp.signature,
            callback_url: `${process.env.REACT_APP_BASE_URL}admin/razorpay_callback_url`,
            prefill: {
                name: orderResp.name,
                email: orderResp.email,
                contact: orderResp.phone,
            },
            redirect: true //true: Customer is redirected to the specified callback URL in case of payment failure. false (default): Customer is shown the Checkout popup to retry the payment.

        };
        const paymentObj = new window.Razorpay(options);
        paymentObj.open()
    }


    return (
        <div>
        </div>
    )

}

export default RazorpayCheckout