import React, { useContext, useEffect, useState, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, message, Row,Modal, Statistic } from "antd";
import ReactHtmlParser from "react-html-parser";
import GlobalStatesContext from "../../../common/providers/GlobalStatesContext";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { useHotelContext } from "../../../common/providers/Hotels/HotelProvider";
import HotelDetailsBox from "../../../components/HotelDetailsBox/HotelDetailsBox";
import VerifyOtpModal from "../../../common/OtpModal/VerifyOtpModal";
import ApiClient from "../../../helpers/ApiClient";
import HotelBookPayCard from "../HotelCheckout/HotelBookPayCard";
import queryString from "query-string";
import HotelFairBox, {
  getHotelGrandTotal,
} from "../HotelCheckout/HotelFairBox";
import "../HotelPreview/HotelPreview.scss";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import moment from "moment";
import PaymentComponent from "../../../helpers/CCavanuePayCheckout";
import timeoutimg from "../../../assets/images/timeout.jpg"

const PGTYPE = "PGTYPE";
const PGDISPLAY = "PGDISPLAY";
const PGDATA = "PGDATA";

const initialState = { pgDisplay: false, pgData: {}, pgType: -1 };
const { Countdown } = Statistic;
function reducer(state, action) {
  switch (action.type) {
    case PGTYPE:
      return { ...state, pgType: action.payload };
    case PGDISPLAY:
      return { ...state, pgDisplay: action.payload };
    case PGDATA:
      return { ...state, pgData: action.payload };
    default:
      return state;
  }
}
const HotelPreview = () => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: { hotelCheckOutData, sessiontimeout,status }, getsessiontimeout ,
  } = useHotelContext();

  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [pgDetails, dispatchPgDetails] = useReducer(reducer, initialState);
  const [pgIsLoading, setPgIsLoading] = useState({
    direct: false,
    hold: false,
  });
  const [openRazorpayCheckout, setOpenrazorpayCheckout] = useState(false)

  const [openCashfreeNewVersionCheckout, setOpenCashfreeNewVersionCheckout] = useState(false)

  const [openCCavanueCheckout, setOpenCCavanueCheckout] = useState(false)
  const [pgData, setPgData] = useState({});
  const {
    state: {
      otherData: { promoData, ConvFee, selectedInsuranceData, redeemAmount },
    },
  } = useContext(GlobalStatesContext);
  let history = useHistory();
  useEffect(() => {
    if (Object.keys(hotelCheckOutData).length <= 0) {
      history.goBack();
    }
  }, [hotelCheckOutData]);

  useEffect(() => { getsessiontimeout() }, [])
  // const userReg = (name, email, mobile) => {
  //   let regObj = {
  //     Name: name,
  //     Email: email,
  //     DialingCode: "+91",
  //     Mobile: mobile,
  //     Password: "",
  //     Role: 4,
  //     DeviceToken: "string",
  //     DeviceType: "Web",
  //     FirBaseToken: "string",
  //   };

  //   ApiClient.post("admin/user/register", regObj)
  //     .then(() => { })
  //     .catch();
  // };

  const navigateToTicket = ({ pgType = null, blockType = 1 }) => {
    let guestsList = hotelCheckOutData.guests.map((pax) => {
      return pax.paxInfoList.map((item) => {
        return item;
      });
    });

    let lastCancellationDate = "";
    let lastVoucherDate = "";
    let cancellationDates = [];
    let voucherDate = [];

    if (hotelCheckOutData?.hotelPriceData?.rooms?.length > 0) {
      cancellationDates = hotelCheckOutData?.hotelPriceData?.rooms?.map(
        (roomDates) => {
          return roomDates.ratePlans[0]?.lastCancellationDate;
        }
      );

      voucherDate = hotelCheckOutData?.hotelPriceData?.rooms?.map(
        (roomDates) => {
          return roomDates.ratePlans[0]?.lastVoucherDate;
        }
      );
    }

    if (cancellationDates?.length > 1) {
      lastCancellationDate = cancellationDates.reduce((prev, cur, i) => {
        if (prev && cur) {
          return moment(prev).isSameOrBefore(cur) ? prev : cur;
        } else if (prev) {
          return prev;
        } else {
          return cur;
        }
      });
    } else if (cancellationDates?.length === 1) {
      lastCancellationDate = cancellationDates[0] ? cancellationDates[0] : "";
    }

    if (voucherDate?.length > 1) {
      lastVoucherDate = voucherDate.reduce((prev, cur) => {
        if (prev && cur) {
          return moment(prev).isSameOrBefore(cur) ? prev : cur;
        } else if (prev) {
          return prev;
        } else return cur;
      });
    } else if (voucherDate?.length === 1) {
      lastVoucherDate = voucherDate[0] ? voucherDate[0] : "";
    }

    let allAmount = getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount
    );

    let guestsDetails = [];

    if (guestsList.length > 0) {
      guestsList.forEach((item) => {
        if (item && item?.length > 0) {
          item.forEach((guestInfo) => {
            guestsDetails.push(guestInfo);
          });
        }
      });
    }

    let commissionAmount = 0;
    let agentMarkupAmount = 0;
    let adminCommissionAmount = 0;
    if (user?.Role?.RoleId === 5) {
      commissionAmount = Number(allAmount.totalCommission);
      agentMarkupAmount = Number(allAmount.agentMarkup);
      adminCommissionAmount = Number(allAmount.adminCommission);
    }

    let data = {
      userId: user?.UserID ?? 1,
      roleType: user?.Role?.RoleId ?? 4,
      membership: user?.Membership ?? 1,
      traceId: hotelCheckOutData.hotelPriceData.traceId,
      categoryId: hotelCheckOutData.hotelPriceData.combineRoom[0].categoryId,
      guests: guestsDetails,
      addressInfo: hotelCheckOutData.addressInfo,
      flightInfo: {
        arrivingByFlight: "",
        flightName: "",
        flightNumber: "",
        hour: "",
        minutes: "",
        remarks: "",
        airlineName: "",
        airlinePnr: "",
      },
      docIdentification: {
        type: "",
        value: "",
      },
      creditCardInfo: {
        securityId: "",
        cardNumber: "",
        expirationMonth: "",
        expirationYear: "",
        firstName: "",
        lastName: "",
        billingAmount: "",
        billingCurrency: "",
        cardHolderAddress: {
          addressLine1: "",
          addressLine2: "strng",
          city: "",
          zipcode: "",
          countryCode: "",
        },
      },
      paymentModeType: "",
      promoCode: promoData.Code ?? "",
      convienenceId: ConvFee.id ?? 0,
      insuranceRequired: hotelCheckOutData?.insuranceRequired,
      ArrivalTransport: hotelCheckOutData.arrivalTransport,
      DepartureTransport: hotelCheckOutData.departureTransport,
      totalPrice: Number(allAmount.totalAmount),
      AdminMarkup: allAmount.markup,
      AgentMarkup: agentMarkupAmount,
      LastCancellationDate: lastCancellationDate ? lastCancellationDate : "",
      LastVoucherDate: lastVoucherDate ? lastVoucherDate : "",
      pgType: pgType ? pgType : pgDetails.pgType,
      bookingType: blockType,
      isCouponReedem: redeemAmount.status ?? false,
      supplier: hotelCheckOutData?.hotelSearchData?.supplier ?? "",
    };

    let paymentPaxInfo = {
      CustomerName: guestsList[0][0].firstName,
      CustomerEmail: hotelCheckOutData.addressInfo.email,
      CustomerPhone: hotelCheckOutData.addressInfo.phoneNo,
    };
    // if (!user) {
    //   userReg(
    //     guestsList[0][0].firstName,
    //     hotelCheckOutData.addressInfo.email,
    //     hotelCheckOutData.addressInfo.phoneNo
    //   );
    // }

    setLoadingSpin(true);
    ApiClient.post("hotels-v2/hotelblock", data)
      .then((result) => {
        return result;
      })
      .then((res) => {
        setPgIsLoading({
          direct: false,
          hold: false,
        });
        setLoadingSpin(false);
        if (res.status === 200) {


          if (pgType == 4) {
            ccavanueCheckoutData(res.data.payment_link)
          }
          else {
            window.location.href = res.data.payment_link.url;
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else if (res?.data[0]?.errorDetail)
            message.error(res?.data[0]?.errorDetail, 3);
          else message.error("Failed", 3);
        }
      })
      .catch((err) => {
        setPgIsLoading({
          direct: false,
          hold: false,
        });
        setLoadingSpin(false);
      });
  };

  const CashFreeNewVersionCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCashfreeNewVersionCheckout(true)
  }

  const ccavanueCheckoutData = (resp) => {
    setPgData(resp)
    setOpenCCavanueCheckout(true)
  }
  const handleVerifyOtp = (otpValue) => {
    ApiClient.post("admin/verifyserviceOtp", {
      Mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      DialingCode: hotelCheckOutData?.addressInfo?.areaCode,
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          navigateToTicket({ pgType: pgDetails.pgType });
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = () => {
    ApiClient.post("admin/sendserviceOtp", {
      Mobile: hotelCheckOutData?.addressInfo?.phoneNo,
      DialingCode: hotelCheckOutData?.addressInfo?.areaCode,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const ValidateBookingLimit = () => {
    let { totalAmount } = getHotelGrandTotal(
      hotelCheckOutData.hotelPriceData,
      hotelCheckOutData.hotelSearchData,
      ConvFee,
      promoData,
      selectedInsuranceData,
      redeemAmount
    );
    ApiClient.post("admin/validatebookinglimit", {
      serviceType: 2,
      roleType: user?.Role?.RoleId ?? 4,
      bookingAmount: totalAmount ? totalAmount : 0,
    })
      .then((res) => {
        if (res?.status === 200 && res?.isValid) {
          handleSendOTP();
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const RazorPayCheckoutData = (resp) => {
    setPgData(resp)
    setOpenrazorpayCheckout(true)
  }
  const timeout = () => {
    return (

      <div>

        <div className="timeout-popup-main">
          <div className="timeout-popup-main1">
            <img className="timeout-img" src={timeoutimg} alt="time" />
          </div>
          <div className="timeout-popup-main2">
            <h4 style={{ color: "red", }}><strong>SESSION TIMEOUT</strong></h4>
            <p className="popup-session-timeout-p-tag">Your Session is Expired</p>
            <p className="popup-session-timeout-p-tag">Click on "OK" to continue with New Search</p>
          </div>
        </div>

      </div>
    );
  }
  const handelCountdown = () => {

    //const query = queryString.stringify(searchhotelobj);
    let query = queryString.stringify(JSON.parse(localStorage.getItem('HotelSearchBar')));

    Modal.warning({
      icon: <></>,
      //title: "",
      content: timeout(),
      onOk() {
        history.push("/hotels/listing?" + query);

      },
    });
  };


  const blockApiReq = (pgType) => {
    dispatchPgDetails({ type: PGTYPE, payload: pgType });
    if (pgType) {
      navigateToTicket({ pgType: pgType });

    }
  };

  const processPayGateway = (blockType) => {
    setPgIsLoading({
      direct: blockType === 1 ? true : false,
      hold: blockType === 2 ? true : false,
    });
    if (blockType === 2) {
      navigateToTicket({ blockType: blockType, pgType: 2 });
    }
  };

  return (
    <div className="hotel-preview-container">
      <div className="lne-5"></div>
      <div className="form-body ">
        <div className="form-body-container">
        {status != 0 &&
        <div className="countdown3 countdownfor-hotelrev">
              <div className="countdown-main-box">
                <div className="countdown1">
                  <span class="fa fa-clock-o" style={{ fontSize: "18px" }}></span><span> Your session will expire in</span>
                </div>
                <div className="countdown2">
                  <Countdown
                    className="busCountdown"
                    onFinish={handelCountdown}
                    value={sessiontimeout}
                    format={"mm[ min]  ss[ sec]"}
                  />
                </div>
              </div>
            </div>}
          <div className="selection-tab">
            <div className="sel">
              <div className="a1"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div className="b"><h6>Your Selection</h6></div>
            </div>
            <div className="l"></div>
            <div className="sel">
              <div className="a1"><i class="fa fa-check-circle" aria-hidden="true"></i></div>
              <div className="b"><h6>Your Details</h6></div>
            </div>
            <div className="l"></div>
            <div className="sel-3">
              <div className="a" style={{ color: "white", background: "#0059ff" }}>3</div>
              <div className="b"><h6>Final Step</h6></div>
            </div>
          </div>
          {Object.keys(hotelCheckOutData).length > 0 ? (
            <Row gutter={[16, 16]}>
              <Col md={8} sm={24} xs={24}>

                <div className="hotel-price-wrapper">
                  <HotelFairBox
                    hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                    hotelSearchData={hotelCheckOutData.hotelSearchData}
                    isPromoVisible={false}
                  />


                </div>
              </Col>
              <Col md={16} sm={24} xs={24}>

                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelDetailsBox
                    hotelDetailsObj={hotelCheckOutData.hotelPriceData}
                    hotelSearchData={hotelCheckOutData.hotelSearchData}
                  />
                </Card>


                <Card bordered={false} className="hotel-card-wrapper">
                  <HotelPassengers passengersInfo={hotelCheckOutData.guests} />
                </Card>
                {hotelCheckOutData?.hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_special_instructions ? (
                  <div className="additional-info">
                    <Card bordered={false}
                      className="guest-details-form hotel-card-wrapper">
                      <div className="hdr-info-2">
                        <h6><i class="fa fa-check-circle-o" aria-hidden="true"></i> {" "}Check-in Instructions</h6>
                      </div>
                      <ul>
                        {hotelCheckOutData?.hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_special_instructions?.split('.').map((instruction, index) => (
                          <>
                            {instruction != "" ? <li key={index}>{" "}
                              <i class="fa fa-hand-o-right" aria-hidden="true"></i>

                              <span>
                                {ReactHtmlParser(instruction)}
                              </span>
                            </li> : null}
                          </>
                        ))}

                      </ul>
                    </Card>
                  </div>) : <>
                  {hotelCheckOutData?.hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_instructions ? (
                    <div className="additional-info">
                      <Card bordered={false}
                        className="guest-details-form hotel-card-wrapper">
                        <div className="hdr-info-2">
                          <h6><i class="fa fa-check-circle-o" aria-hidden="true"></i> {" "}Check-in Instructions</h6>
                        </div>
                        <ul>
                          {hotelCheckOutData?.hotelPriceData?.combineRoom?.[0]?.rateComments?.checkin_instructions?.split('.').map((instruction, index) => (
                            <>
                              {instruction != "" ? <li key={index}>{" "}
                                <i class="fa fa-hand-o-right" aria-hidden="true"></i>

                                <span>
                                  {ReactHtmlParser(instruction)}
                                </span>
                              </li> : null}
                            </>
                          ))}

                        </ul>
                      </Card>
                    </div>) : null}

                </>}
                <HotelBookPayCard
                  className="book-pay-btn"
                  isLoading={false}
                  pgIsLoading={pgIsLoading.direct}
                  holdLoading={pgIsLoading.hold}
                  purchaseType={
                    hotelCheckOutData?.hotelPriceData?.purchaseType
                  }
                  bookpaycardinfo={"hotel-review"}

                  agent={agent}
                  blockApiReq={blockApiReq}
                  processPayGateway={processPayGateway}
                  loadingSpin={loadingSpin}
                />
              </Col>

            </Row>
          ) : (
            "No Hotel Selected "
          )}
        </div>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={hotelCheckOutData?.addressInfo?.phoneNo}
        />
      )}




      {
        openCCavanueCheckout && (
          <PaymentComponent
            pgData={pgData}

          />
        )
      }

    </div>
  );
};

export default HotelPreview;
