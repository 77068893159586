import moment from "moment";

export const createReportFromApiReponse = (bookings) => {
  let response = {
    flights: [],
    hotels: [],
    holidays: [],
  };
  // console.log(bookings,"book");
  bookings.forEach((booking) => {

    booking.month = moment(booking.journeyDate).month();
    booking.year = moment(booking.journeyDate).year();
    booking.formatedDate = moment(booking.journeyDate, "YYYY-MM-DD").format(
      "DD-MM-YYYY"
    );


    if (booking.type == "Hotel" && booking.BookingStatus == "CONFIRMED") {
      response.hotels.push(booking);
    } else if (
      booking.type == "Flight" &&
      booking.BookingStatus == "CONFIRMED"
    ) {
      response.flights.push(booking);
    } else if (
      booking.type == "Holidays" &&
      booking.BookingStatus == "CONFIRMED"
    ) {
      response.holidays.push(booking);
    }
  });

  return response;
};
