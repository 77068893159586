import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Result, Row, message, Spin } from "antd";
import APIClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";
import queryString from "query-string";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useHistory } from "react-router-dom";

import "./Login.scss";
import logo from "../../assets/images/trypmaker.png";
import google from "../../assets/images/google.png";
import facebook from "../../assets/images/faceboo.png";
import back from "../../assets/images/travel-hd-2fsg31b3wqteinpa.jpg";
import GoogleButton from 'react-google-button';
import {
  TeamOutlined,
  BookOutlined,
  BlockOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
  LeftOutlined

} from "@ant-design/icons";
import { auth, provider, fbAuthProvider } from "./firebase";
import { signInWithPopup } from "firebase/auth";

const CryptoJS = require("crypto-js");
const CryptoKey = process.env.REACT_APP_LOGIN_SECRETKEY;

const Login = ({
  userInfo = false,
  location,
  setModalVisible,
  type = "USER",
}) => {
  const {
    extraUserInfo,
    setExtraUserInfo,
    setUser,
    setAccessToken,
    setAdminAsUser,
    setAccessList,
    updateAgentLoginFlag,
    updateLoginFlag,
    updateDashboardFlag,

    resetIsLogin,
  } = useAuthContext();
  console.log(location, "login-loc")
  const [form] = Form.useForm();
  const [forgetForm] = Form.useForm();
  const [otpForm] = Form.useForm();
  const history = useHistory();
  const [userId, setUserId] = useState(-1);
  const [showSuccessMessage, setShowSuccesMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isloading, setisloading] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [usernamer, setUsernamer] = useState('');
  let extraVal = {
    DeviceToken: "string",
    DeviceType: "Web",
    FirBaseToken: "string",
  };

  const handleClick = () => {
    signInWithPopup(auth, provider)
      .then((data) => {
        console.log(data, "data");
        request(data);
      })
      .catch((error) => {
        console.log("Error signing in with popup:", error);
      });
  };
  const handleClick1 = () => {
    signInWithPopup(auth, fbAuthProvider)
      .then((data) => {
        console.log(data, "data");
        request(data);
      })
      .catch((error) => {
        console.log("Error signing in with popup:", error);
      });
  };

  const request = (data) => {
    if (data && data.user && data._tokenResponse) {
      let regObj = {
        Name: data.user.displayName ? data.user.displayName : '',
        Email: data.user.email ? data.user.email : '',
        Mobile: data.user.phoneNumber ? data.user.phoneNumber : '',
        Password: "",
        isloginType: data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,
        Role: 2,
        DeviceToken: "string",
        DeviceType: "Web",
        FirBaseToken: "string",
      };

      // console.log(regObj, " regobj");

      if (data?._tokenResponse?.isNewUser) {
        APIClient.post("admin/user/register", regObj)
          .then((response) => {
            if (response?.status === 200) {
              message.success(`login Successfully`);
              verifyOtp1(response.data.userDetails.UserId, data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,);
            } else if (response?.status === 409) {
              message.error(response.message);
            }
          })
          .catch((error) => {
            console.log("Error registering user:", error);
          });
      } else {
        attemptLogin(data.user.email, "", true, data.providerId === "google.com" ? 2 : data.providerId === "facebook.com" ? 3 : 1,);
      }
    } else {
      console.log("User data is undefined or missing _tokenResponse");
    }
  };

  const verifyOtp1 = (userId, logintype) => {
    let verifyObj = {
      UserID: userId,
      Otp: "",
      OtpType: 2,
      isloginType: logintype,
    };

    APIClient.post("admin/verifyotp", verifyObj)
      .then((resp) => {
        handleVerifyOtpResponse(resp, logintype);
      })
      .catch((error) => {
        console.log("Error verifying OTP:", error);
      });
  };

  const handleVerifyOtpResponse = (resp, logintype) => {
    if (resp.status === 202) {
      setUser(resp.data.userDetails);
      setAccessToken(resp.accessToken);
      updateLoginFlag(true);
      attemptLogin(resp.data.userDetails.Email, "", true, logintype);
    } else if (resp.status === 201) {
      setModalVisible(false);
      message.success(resp.message, 3);
      history.push("/");
    } else if (resp.status === 400) {
      message.error("Invalid Otp", 3);
    } else if (resp.status === 500) {
      message.error("Unable to Login Please Contact Administration", 3);
    } else if (resp.status === 503) {
      message.error(resp.message, 3);
    }
  };

  const attemptLogin = (username, password, redirect, logintype) => {
    setisloading(false);
    let obj = {
      UserName: username,
      Password: password,
      Role: 2,
      isloginType: logintype,
    };

    APIClient.post("admin/login", obj)
      .then((resp) => {
        handleLoginResponse(resp, redirect);
      })
      .catch((e) => {
        setisloading(false);
      });
  };

  const handleLoginResponse = (resp, redirect) => {
    setisloading(false);
    if (resp.status === 200) {
      setUser(resp.data.userDetails);
      setAccessToken(resp.accessToken);

      if (resp.data.userDetails.Role.RoleLevel === 1) {
        let screens = [];
        resp.data.userDetails.Screens.forEach((element) => {
          screens.push(element.Name);
        });
        setAccessList(screens);
        setModalVisible(false);
        updateDashboardFlag(true);
        if (redirect) {
          history.push("/");
        }
      } else if (resp.data.userDetails.Role.RoleLevel === 3) {
        setModalVisible(false);
        updateAgentLoginFlag(true);
        if (redirect) {
          handleRedirect();
        } else {
          handleAdminAsUser(resp.data.userDetails.UserId);
        }
      } else if (resp.data.userDetails.Role.RoleLevel === 2) {
        setModalVisible(false);
        updateLoginFlag(true);
        if (redirect) {
          handleRedirect();
        } else {
          handleAdminAsUser(resp.data.userDetails.UserId);
        }
      } else {
        resetIsLogin();
      }
    } else if (resp.status === 500) {
      message.error("Unable to Login Please Contact Administration", 3);
    } else if (resp.status === 503) {
      message.error(resp.message, 3);
    } else if (resp.status === 400 || resp.status === 404) {
      message.error(resp.message, 3);
      let errorMsg = resp?.message ? resp?.message : "Wrong Email or Password";
      form.setFields([
        {
          name: "username",
          errors: [" "],
          value: "",
        },
        {
          name: "password",
          errors: [errorMsg],
          value: "",
        },
      ]);
    }
  };





  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (userInfo && extraUserInfo) {
      let data = EncHelper.decryptData(extraUserInfo);
      if (data) {
        onFinish(queryString.parse(data), false);
      }
    }
  }, [userInfo, extraUserInfo]);

  const handleRedirect = () => {
    let path = "/";
    if (location) {
      // if (location?.state?.login) path = location.state?.from?.pathname ?? path;
      if (location?.pathname) path = location?.pathname ?? path;

    }

    history.push(path, {});
  };
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");


  const handleAdminAsUser = (id) => {
    setAdminAsUser({
      status: true,
      id,
    });
    setExtraUserInfo(null);
  };

  const onFinish = (values, redirect = true) => {
    setUsernamer(values.username)
    //  console.log(usernamer,"user")
    if (showPasswordField) {
      setisloading(true)
      let obj = {
        UserName: usernamer,
        Password: CryptoJS.AES.encrypt(values.password, CryptoKey).toString(),
        ...extraVal,
        Role: type === "USER" ? 2 : type === "AGENT" ? 5 : 8,
        isloginType: 1,
      };
      console.log(obj, "obj")
      APIClient.post("admin/login", obj)
        .then((resp) => {
          if (resp.status === 200) {
            setUser(resp.data.userDetails);
            setAccessToken(resp.accessToken);

            if (
              resp.data.userDetails.Role.RoleLevel == 1 &&
              (type === "USER" || type === "ALL")
            ) {
              let screens = [];
              resp.data.userDetails.Screens.forEach((element) => {
                screens.push(element.Name);
              });
              setAccessList(screens);
              setModalVisible(false);
              updateDashboardFlag(true);
              history.push("/");
            } else if (
              resp.data.userDetails.Role.RoleLevel == 3 &&
              (type === "AGENT" || type === "ALL")
            ) {
              setModalVisible(false);
              updateAgentLoginFlag(true);
              if (redirect) {
                handleRedirect();
              } else {
                handleAdminAsUser(values.id);
              }
            } else if (
              resp.data.userDetails.Role.RoleLevel == 2 &&
              (type === "USER" || type === "ALL")
            ) {
              setModalVisible(false);
              updateLoginFlag(true);
              if (redirect) {
                handleRedirect();
              } else {
                handleAdminAsUser(values.id);
              }
            } else {
              resetIsLogin();
            }
          } else if (resp.status == 500) {
            message.error("Unable to Login Please Contact Administration", 3);
          } else if (resp.status == 503) {
            message.error(resp.message, 3);
          } else if (resp.status == 400 || resp.status == 404) {
            message.error(resp.message, 3)
            if (type !== "ALL") {
              let errorMsg = resp?.message
                ? resp?.message
                : "Wrong Email or Password";
              form.setFields([
                {
                  name: "username",
                  errors: [" "],
                  value: "",
                },
                {
                  name: "password",
                  errors: [errorMsg],
                  value: "",
                },
              ]);
            }
          }
          setisloading(false)
        })
        .catch((e) => {
          setisloading(false)
        });
    } else {
      setShowPasswordField(true);
    }
  };

  const [otpModal, setOtpModal] = useState(false);
  const [forgetModal, setForgetModal] = useState(false);
  const handleOk = (e) => {
    setOtpModal(false);
  };

  const handleCancel = (e) => {
    setOtpModal(false);
  };

  const submitforgetForm = (val) => {
    let mobileNumber;
    let email;
    let mobpattern = /^[0-9]{10}$/;
    if (mobpattern.test(Number(val.username))) {
      mobileNumber = val.username;
      email = null;
    }
    let emailpattern = /\S+@\S+\.\S+/;

    if (emailpattern.test(val.username)) {
      mobileNumber = null;
      email = val.username;
    }
    if (!mobileNumber && !email) {
      forgetForm.setFields([
        {
          name: "username",
          errors: ["Wrong Mobile Number or Email"],
        },
      ]);
      return;
    }
    let data = {
      type: "2",
      Mobile: mobileNumber,
      Email: email,
      ...extraVal,

    };
    // console.log(data,"hhhhhh")
    APIClient.post("admin/sendotp", data)
      .then((res) => {
        if (res.status === 200) {
          setUserId(res.data.userDetails.UserId);
          setShowSuccesMessage(false);
          otpForm.resetFields();
          setForgetModal(false);
          setOtpModal(true);
        } else if (res.status === 401) {
          forgetForm.setFields([
            {
              name: "username",
              errors: [res.message],
            },
          ]);
        } else if (res.status === 404) {
          if (!!res.message) message.error(res.message);
        }
      })
      .catch((e) => { });
  };

  const verifyOtp = (val) => {
    let data = {
      UserId: userId,
      ...val,
    };
    APIClient.post("admin/updatePassword", data)
      .then((res) => {
        if (res.status === 200) {
          setShowSuccesMessage(true);
        } else if (res.status === 400) {
          otpForm.setFields([
            {
              name: "Otp",
              errors: ["Invalid OTP"],
            },
          ]);
        }
      })
      .catch((e) => { });
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const handleBack = () => {
    setShowPasswordField(false);
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 22,
        color: "white",
        marginLeft: "2px"
      }}
      spin
    />
  );

  return (
    <>
      {/* <div className="background-container"> */}
      {/* <img src={back} alt="Background" className="backg-lg-image" /> */}

      {/* <img src={back} alt="" /> */}
      <Row className="img-pop-login  ">
        <Col md={12} xs={24} className="login-user-popup21">

          {type === "USER" ? (
            <div className="section-left">

              <img className="logo-login" src={logo} alt='trypmaker.com' />

              <div className="d-flex align-items-baseline">
                {/* <p className="icons">
                  <TeamOutlined />
                </p> */}
                <p style={{ fontSize: '24px', fontWeight: '600', textShadow: '2px 3px 6px black' }}>Become A Part Of Our Community.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <BookOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>
                  Utilize The Number Of Promotional Codes To Save On Your
                  Bookings.
                </p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <BlockOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>
                  Get Closer To A Higher Membership Tier With Each Purchase And
                  Unlock More Benefits.
                </p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <PrinterOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>View,Print,SMS/Email & Cancel Bookings.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <InfoCircleOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>
                  Dedicated Customer Support On Ticketing, Cancellation,
                  Reschedules And Refunds.
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {type === "AGENT" ? (
            <div className="section-left">
              < img className="logo-login" src={logo} alt='trypmaker.com' />
              <div className="d-flex align-items-baseline">
                {/* <p className="icons">
                  <TeamOutlined />
                </p> */}
                <p style={{ fontSize: '24px', fontWeight: '600', textShadow: '2px 3px 6px black' }}>
                  Start Your Profitable Business Today With Best B2B Travel
                  Login.
                </p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <BookOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>
                  Become An Agent & Get Access To Our Wide Array Of Travel
                  Services.
                </p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <BlockOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>Greater Agent Returns On Every Service.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <PrinterOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>Seamless Wallet Topups And Incentives.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p style={{ color: '#2600a857' }} className="icons">
                  <InfoCircleOutlined />
                </p>
                <p style={{ textShadow: "1px 2px 4px #393939" }}>
                  Dedicated Customer Support On Ticketing, Cancellation,
                  Reschedules And Refunds.
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {type === "CORPORATE" ? (
            <div className="section-left">
              <div className="d-flex align-items-baseline">
                <p className="icons">
                  <TeamOutlined />
                </p>
                <p>Employees Who Travel For Business.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p className="icons">
                  <BookOutlined />
                </p>
                <p>
                  Self-Booking Online Platform Alligned With Travel Policies.
                </p>
              </div>
              <div className="d-flex align-items-baseline">
                <p className="icons">
                  <BlockOutlined />
                </p>
                <p>Special Corporate And Sme Fares in flights.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p className="icons">
                  <PrinterOutlined />
                </p>
                <p>
                  Discounted Rates For Flights, Hotels, Holidays And Other Approved
                  Services.
                </p>
              </div>
              <div className="d-flex align-items-baseline">
                <p className="icons">
                  <InfoCircleOutlined />
                </p>
                <p>Hassle-Free Payment And GST Invoices Guaranteed.</p>
              </div>
              <div className="d-flex align-items-baseline">
                <p className="icons">
                  <CheckCircleOutlined />
                </p>
                <p>Seamless Approval & Reimbursements.</p>
              </div>
            </div>
          ) : (
            ""
          )}
        </Col>
        <Col md={12} xs={24}>

          {type === "USER" ?
            <p className="txt-hdr-log">Become A Part Of Our Community.</p>
            :
            <p className="txt-hdr-log" >
              Start Your Profitable Business Today With Best B2B Travel
              Login.
            </p>}
          <div className="login-user-box">
            <Form form={form} name="basic" onFinish={onFinish} size="large">
              <div className="login-text mb-3">
                <h3>
                  {type === "USER"
                    ? "User"
                    : type === "AGENT"
                      ? "Partner"
                      : "Corporate"}{" "}
                  Login
                </h3>
              </div>

              {type === "USER" || type === "CORPORATE" ? (
                !showPasswordField && (
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                      {
                        type: "email",
                        message: "Email is not a valid email",
                      },
                    ]}
                  >
                    <Input style={{
                      borderRadius: '6px',
                      boxShadow: '1px 2px 9px #0000009e'
                    }}
                      placeholder="Email" />
                  </Form.Item>
                )
              ) : (
                !showPasswordField && (
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Required",
                      },
                    ]}
                  >
                    <Input style={{
                      borderRadius: '6px',
                      boxShadow: '1px 2px 9px #0000009e'
                    }} placeholder="Email or Agent Id" />
                  </Form.Item>
                )
              )}
              {showPasswordField && (
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please enter your password!" },
                  ]}
                >
                  <Input.Password placeholder="Password " style={{
                    borderRadius: '6px',
                    boxShadow: '1px 2px 9px #0000009e'
                  }} />
                </Form.Item>
              )}
              {showPasswordField ? (
                <button type="link" onClick={handleBack} style={{ marginLeft: 115, border: "none", background: "transparent", fontSize: 16, color: "black" }}>
                  go back
                </button>
              ) : null}
              <Button
                type="primary"
                htmlType="submit"
                className="login-btn"
                size={"large"}
                style={{
                  borderRadius: '6px',
                  boxShadow: '1px 2px 9px #1d19199e'
                }}

              >
                {isloading ? (<Spin indicator={antIcon} />) : null}
                {showPasswordField ? 'Login' : 'Continue'}
              </Button>
            </Form>

            <div className="forgot-text1">
              <p
                className="txt-hy mb-0 my-2"
                style={{
                  color: '#ffff',
                  cursor: 'pointer',
                  transition: 'font-size 0.2s',
                }}
                onClick={() => {
                  setModalVisible(false);
                  forgetForm.resetFields();
                  setForgetModal(true);
                }}
              >
                Forgot Your Password
              </p>

            </div>
            <div className="forgot-text1">
              <p
                // style={{ color: '#ffff' }}
                className="txt-hy mb-0 my-2"
                style={{
                  color: '#ffff',
                  cursor: 'pointer',
                  transition: 'font-size 0.2s',
                }}
                onClick={() => {
                  if (type == "AGENT") {
                    history.push("/agent-registration");
                  } else if (type == "USER") {
                    history.push("/user-registration");
                  } else {
                    history.push("/corporate-registration");
                  }

                  setModalVisible(false);
                }}
              >
                New{" "}
                {type === "USER"
                  ? "User"
                  : type === "AGENT"
                    ? "Partner"
                    : "Employee"}
                {' '}?{' '} Please Sign Up Here
              </p>
            </div>
            {type === "USER" ?
              <div className="scial">
                <div className="line-with-or-container">
                  <div className="line" />
                  <div className="or">OR</div>
                  <div className="line" />
                </div>
                <div className="forgot-text1" style={{
                  display: 'flex', justifyContent: "space-evenly",
                  marginTop: 16
                }}>
                  <GoogleButton type="light" onClick={handleClick} style={{ marginBottom: 10, borderRadius: 5 }} />
                  {/* <img src={google} style={{width: 36 , height: 34,marginBottom: 21}} onClick={handleClick}/> */}

                  {/* <FacebookLoginButton type="light" onClick={handleClick1} /> */}
                  {/* <img src={facebook} style={{width: 36 , height: 34,marginBottom: 21}}  onClick={handleClick1}/> */}
                </div>
              </div>

              : null}

          </div>

        </Col>
      </Row>
      {/* </div> */}

      <Modal
        visible={forgetModal}
        onOk={() => setForgetModal(false)}
        onCancel={() => setForgetModal(false)}
        footer={false}
        className="otp-modal remember-modal"
      >
        <div className="forgot-password">
          <div className="dont-remember-text">
            <h4>Don't remember password?</h4>
          </div>
          <Form
            form={forgetForm}
            onFinish={submitforgetForm}
            style={{ padding: 0 }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="E-mail or Mobile" size="large" />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Send OTP
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal
        visible={otpModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="otp-modal"
      >
        <div>
          {showSuccessMessage ? (
            <div className="verified-text">
              <Result
                status="success"
                title="Password Changed successfully"
                extra={[
                  <Button
                    type="primary"
                    onClick={() => {
                      setOtpModal(false);
                      setModalVisible(true);
                    }}
                  >
                    Back To login
                  </Button>,
                ]}
              />
            </div>
          ) : (
            <div className="otp-body">
              <p>Enter Details</p>
              <Form form={otpForm} layout="vertical" onFinish={verifyOtp}>
                <Form.Item
                  name="Otp"
                  label="Enter OTP"
                  rules={[{ required: true, message: "OTP Required" }]}
                >
                  <Input placeholder="Enter  OTP" size={"large"} />
                </Form.Item>
                <Form.Item
                  name="Password"
                  label="Enter New Password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required"
                    },
                    {
                      validator: (_, value) => {
                        if (value.length < 7 || value.length > 24) {
                          return Promise.reject(new Error('Password must be greater than 8 characters and include special characters & numbers'));
                        }
                        if (!/\d/.test(value)) {
                          return Promise.reject(new Error('Password must contain at least one number'));
                        }
                        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                          return Promise.reject(new Error('Password must contain at least one special character'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter Password" />
                </Form.Item>

                <div className="otp-continue-btn">
                  <Button type={"primary"} size={"large"} htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Login;
