import React, { useState, useEffect } from "react";
import { Col, Row, Layout } from "antd";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Button, Modal, Rate, Skeleton, Badge, Carousel } from "antd"
import HolidaySearchForm from "./HolidaySearchForm";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import "./HolidaysSearch.scss";
import ApiClient from "../../../helpers/ApiClient";
import { useSytContext } from "../../../common/providers/SytProvider";
import Banner from "../../../components/banner/Banner";
// import { useAuthContext } from "../../../common/providers/AuthProvider";
import PopularHolidays from "./PopularHolidays";
import OffersSlider from "../../../common/LandingPageOffers/LandingPageOffers";
import CombineServLink from "../../../common/CombineServicesLink/CombineServLink";
import { StarOutlined } from "@ant-design/icons";
import TopHotels from "../../Hotels/TopHotels/TopHotels";
import CityTopHotels from "../../Hotels/TopHotels/CityTopHotels"
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";
import NoImage from "../../../assets/images/image-not-found-scaled-1150x647.png"
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
const { Content } = Layout;
const BASE = process.env.REACT_APP_BASE_URL;

const MobileSlidersettings = {
  arrows: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  infinite: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

const HolidaySearch = () => {
  const { holidayBanners, flightBanners, promoDataSource } = useSytContext();
  const [cityHolidayData, setCityHolidayData] = useState({
    sliderData: [],
    cityData: [],
  });
  const {
    isLogin: { flag, agent },
    user,
    isGuest,
  } = useAuthContext();
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [toursData,setToursData] = useState()

  const [dataSource, setDataSource] = useState([]);
  

  const [deal, setDeal] = useState([])
  const getDeal = () => {
    ApiClient.get("admin/deals")
      .then((res) => {
        if (res.status === 200) {
          setDeal(res.data);
        }
      })
      .catch((e) => {
        setDeal([]);
      });
  };

  useEffect(() => {
    getDeal()
    if (promoDataSource.length) {
      let data = promoDataSource.filter((item) => item.ServiceType === 6);
      setDataSource(data);
    }
  }, [promoDataSource]);

   const [recentSearchResults, setRecentSearchResults] = useState([]);

  useEffect(() => {
    let data = sessionStorage.getItem("holidaysRecentSearchesResults");

    if (data.length) {
      setRecentSearchResults(JSON.parse(data));
    }
  }, []);

  const getCityHolidayList = () => {
    ApiClient.get("ToursExtranet/getTourDeals")
      .then((res) => {
        console.log(res,"res")
        if (res.status === 200) {
          let data = res.data.filter(
            (item) => item.Status === 0 && item.Servicetype === 1
          );
          let result = data.reduce(function (obj, key) {
            obj[key.CountryName] = obj[key.CountryName] || [];
            obj[key.CountryName].push(key);
            return obj;
          }, {});
          setCityHolidayData({ sliderData: data, cityData: result });
        } else {
          setCityHolidayData({ sliderData: [], cityData: [] });
        }
      })
      .catch((e) => {
        setCityHolidayData({ sliderData: [], cityData: [] });
      });
  };

    const  data = () =>{
      ApiClient.get("HolidayExtranet/getHolidayPackages")
      .then((res)=>{
        if (res.status==200){
          setToursData(res)
        }
      })
      .catch((e) => {
        console.log(e)
      })
    }
 
  return (
    <>
      <div className="bus-block">
        <div className="bus-banner">
          {/* <h2 className="upperheading-of-searchlinkbarholiday"   >The world’s best escapes, handpicked by experts</h2> */}
          <Banner banner={holidayBanners} />
        </div>

        <div className="hotel_search">
          <CombineServLink activetab={2} />

        </div>
      </div>



      <section className="main-box-dis-work-section">
        <h3 style={{ marginLeft: '2%' }}><strog>Signup & save upto 30%</strog></h3>
        <div className="main-box-dis-work" >
          <div className="main-box-dis-work1" >
            <i class='fa fa-tag' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-beer' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-globe' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
          <div className="main-box-dis-work1" >
            <i class='fa fa-phone' style={{ fontSize: '34px' }}></i>
            <p>Unlock exclusive offers you won't find elseWhere</p>
          </div>
        </div>
        <div className="liks-about-sigup">
        <Link to="/about-us" className="homepage-aboutus-cot">Learn more about us</Link>
        {flag === true || agent === true ? (null):(
        <Link to="/user-registration" > <button className="homepage-sigup-btn" >Sign up and save</button></Link>)}
        </div>
      </section>
{/* 
      <section className="home-best-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                  <p>
                    Trying our level best to fetch lower price than others, try
                    us once!!
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../../../src/assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                  <p>
                    We’re always here for you – reach us 24 hours a day, 7 days
                    a week.{" "}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}

<section className="mainn-section-tophotels" style={{ width: '80%', margin: 'auto' }}>
        <CityTopHotels/>
      </section>

      <section>
        {((user?.Role?.Name === "Agent" && user?.Services.includes("1")) || agent === false) ?
         <HomeCarousel deal={deal} />
          : null}
      </section>


      {/* <div className="bus-search-header" style={{ textAlign: 'center', marginTop: "2%" }} >
        <h2>Holiday Packages Around the World</h2>
      </div> */}
       <div className="card holidays_search_container_1" style={{ border: 'none' }} >
        <PopularHolidays />
      </div> 





      {/* {cityHolidayData.sliderData.length > 0 ? (
        <div className="promo providerapi activities-slider">
          <Layout className="holidays-search-apps activities-home-page">
            <Content className="admin-container cms-pages-width text-center">
              <h4 className="popular-clr-name">Top Holidays</h4>
              <Slider {...MobileSlidersettings}>
                {cityHolidayData.sliderData.map((item) => (
                  <div key={item.CityHotelID}>
                    {/* <Link to={getActivitiesUrl(item.SearchURL)}> */}
                    {/* <img
                      className="cart-shopping-img-1"
                      src={
                        item.HotelImage1
                          ? `${BASE}${item.HotelImage1.substring(1)}`
                          : require("../../../assets/images/hotels/hotel-fil-2.jpg")
                            .default
                      }
                      alt={item.CityName}
                    /> */}
                    {/* </Link> *
                    <p className="text-center font-weight-bold">
                      {item.HotelName}
                    </p>
                  </div>
                ))}
              </Slider>
            </Content>
          </Layout>
        </div>
      ) : null} */}

<section className="mainn-section-tophotels" style={{width:'80%', margin:'auto'}}>
        <TopHotels />
      </section>
      

        
      <section className="queries_about_hotels">
        <div className="my_holiday">
          <div className="query-hotels-heading">
            <h2 style={{color:" #363a45"}}>Why you should <i>book</i>  with us </h2>
           
          </div>
          <div className="query-hotels-body">
            <Row gutter={{ xs: 8, sm: 16, md:16}}>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-star-o" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                  <h2 style={{fontWeight:"bolder",fontSize:18}}>Exclusive tours, curated by experts</h2>
                  <p style={{fontSize:14}}>
                  With years of experience in the travel industry, we provide expert guidance and curated experiences tailored to your preferences.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-usd" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>Great value small-group tours</h2>
                  <p style={{fontSize:14}}>
                  Our website ensures you get the best deals with our price match guarantee, offering value for every penny spent.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-gift" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>Exclusive Packages</h2>
                  <p style={{fontSize:14}}>
                  Discover exclusive travel packages and unique experiences available only through our platform, designed to create unforgettable memories
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-comments-o" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>Secure Booking</h2>
                  <p style={{fontSize:14}}>
                  Rest assured with our secure and seamless booking process, ensuring your personal information and payments are always protected.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                <i class="fa fa-comments-o" aria-hidden="true" style={{fontSize:22,color:" #363a45"}}></i>
                </div>
                <div className="box-body">
                <h2 style={{fontWeight:"bolder",fontSize:18}}>24/7 dedicated tours concierge</h2>
                  <p style={{fontSize:14}}>
                  Prioritizing customer satisfaction, we offer 24/7 support and strive to exceed your expectations at every step of your journey.
                  </p>
                </div>
              </div>
            </Col>
            
            
            {/* <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/Checkinproc.jpg")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">
                    Check-in Procedures For Hotels, Apartments, Guest Houses
                  </a>
                  <p>
                    All Hotels or Similar Establishments Usually Require Guests
                    To..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/Typesofbeds.jfif")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">Types Of Beds</a>
                  <p>There are several basic types of hotel beds: single..</p>
                </div>
              </div>
            </Col>
            <Col md={4} className="d-flex">
              <div className="query-box col">
                <div className="box-head">
                  <img
                    src={
                      require("../../../assets/images/hotels/Additionalservices.jfif")
                        .default
                    }
                    alt="hotel icon"
                  />
                </div>
                <div className="box-body">
                  <a href="/">
                    Additional Services Available And Offered In Hotels
                  </a>
                  <p>
                    Many hotels offer additional services such as: wake-up
                    calls, laundry..
                  </p>
                </div>
              </div>
            </Col> */}
            </Row>
          </div>
        </div>
      </section> 
      {/* <section className="top-route-bb">
        {dataSource.length && (
          <OffersSlider dataSource={dataSource} serviceType="Holiday" />
        )}
      </section> */}

    </>
  );
};

export default HolidaySearch;
